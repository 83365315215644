import React, { useState } from 'react';
import s from './SalesSettings.module.scss';
import { MainSettings } from './mainSettings/MainSettings';
import { ConfirmPage } from './confirmPage/ConfirmPage';
import { CheckEmail } from './checkEmail/CheckEmail';

export type ActiveWindowType = 'settings' | 'confirm' | 'checkEmail';

export const SalesSettings = () => {
  const [activeWindow, setActiveWindow] = useState<ActiveWindowType>('settings');

  return (
    <div className={s.SalesSettingsBlock}>
      <h2>Настройки</h2>
      {activeWindow === 'settings' && <MainSettings setActiveWindow={setActiveWindow} />}
      {activeWindow === 'confirm' && <ConfirmPage setActiveWindow={setActiveWindow} />}
      {activeWindow === 'checkEmail' && <CheckEmail />}
    </div>
  );
};
