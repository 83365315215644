import React, { FC } from 'react';
import s from './ManagerInfo.module.scss';
import { GetManagerResponseType } from '../../../API/adminTransfersApi';
import { useNavigate } from 'react-router-dom';
import { adminTransfersThunks } from '../../adminTransfersSlice';
import { useAppDispatch } from '../../../../../common/hooks/storeHooks';
import { setAppSuccessMessage } from '../../../../../bll/reducers/appReducer';
import { useWindowDimensions } from '../../../../../common/utils/useWindowDimensions';

type Props = {
  managerData: GetManagerResponseType;
};

export const ManagerInfo: FC<Props> = ({ managerData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const addTGChatLinkWidth = 500;
  const isAddTGChatLink = width <= addTGChatLinkWidth;

  const cashHandler = async () => {
    await dispatch(adminTransfersThunks.sendTransfer(managerData.withdrawId))
      .unwrap()
      .then(() => {
        dispatch(setAppSuccessMessage({ successMessage: 'Успешно! Перевод выполнен.' }));
      });
  };

  const goToTGChat = () => window.open(`https://t.me/${managerData.manager.tgUsername}`, '_blank');

  return (
    <div className={s.managerInfo}>
      <div className={s.telegramBlock}>
        <div className={s.telegram}>
          <div className={s.telegramHeader}>Телеграм</div>
          <div className={s.telegramInfo} onClick={isAddTGChatLink ? goToTGChat : undefined}>
            {managerData.manager.tgUsername}
          </div>
        </div>
        <div className={s.managerInfoButton}>
          <a target={'_blank'} rel={'noreferrer'} href={`https://t.me/${managerData.manager.tgUsername}`}>
            <button>Перейти в чат</button>
          </a>
        </div>
      </div>
      <div className={s.dataBlock}>
        <div className={s.balanceBlock}>
          <div className={s.balanceTitle}>Сумма перевода</div>
          <div className={s.balanceInfo}>{managerData.manager.balance}$</div>
        </div>
        <div className={s.managerData}>
          <div className={s.teamBlock}>
            <div className={s.teamTitle}>Команда</div>
            <div className={s.teamInfo}>{managerData.teamMembers.managers}</div>
          </div>
          <div className={s.freeBlock}>
            <div className={s.freeTitle}>Free</div>
            <div className={s.freeInfo}>{managerData.managerClients.freeClients}</div>
          </div>
          <div className={s.paidBlock}>
            <div className={s.paidTitle}>Платные</div>
            <div className={s.paidInfo}>{managerData.managerClients.paidClients}</div>
          </div>
        </div>
      </div>
      <div className={s.buttons}>
        <button onClick={() => navigate('/admin/transfers')}>Назад</button>
        <button onClick={cashHandler} disabled={managerData.withdrawId === undefined}>
          Оплата прошла
        </button>
      </div>
    </div>
  );
};
