import React, { FC, PropsWithChildren } from 'react';
import s from './PendingTransfersTable.module.scss';

export const PendingTransfersTableLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={s.tableWrapper}>
      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.idCol}>ID менеджера</th>
            <th className={s.emailCol}>Email</th>
            <th className={s.phoneCol}>Телефон</th>
            <th className={s.theamCol}>Команда</th>
            <th className={s.paidCol}>Платных</th>
            <th className={s.sumCol}>Сумма</th>
            <th className={s.telegramCountCol}>Телеграм</th>
            <th className={s.moreCol}></th>
            <th className={s.payCol}></th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
