import React, { FC } from 'react';
import { CompletedTransfersTableLayout } from './CompletedTransfersTableLayout';
import { CompletedTransfersTableRow } from './CompletedTransfersTableRow';
import { serverDateFormat } from '../../../../sales/common/utils/serverDateFormat';
import { GetTransfersResponseType } from '../../../API/adminTransfersApi';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { isTransfersDataFetchingSelector } from '../../adminTransfersSelectors';
import { CircularProgress } from '@mui/material';
import { SalesPagination } from '../../../../sales/common/components/pagination/Pagination';

type Props = {
  transfersData: GetTransfersResponseType;
  onChangePage: (page: string) => void;
};

export const CompletedTransfersTable: FC<Props> = ({ transfersData, onChangePage }) => {
  const isTransfersDataFetching = useAppSelector(isTransfersDataFetchingSelector);

  const mappedUsersTableRows = transfersData.items.map(({ dateTransaction, sum, id, managerId, tgUsername }) => {
    const transactionDate = serverDateFormat(dateTransaction ?? '');
    return (
      <CompletedTransfersTableRow
        key={id}
        managerId={managerId}
        transactionDate={transactionDate}
        sum={sum}
        tgUsername={tgUsername || 'Телеграм не указан'}
      />
    );
  });

  if (isTransfersDataFetching) return <CircularProgress />;

  return (
    <div>
      <CompletedTransfersTableLayout>{mappedUsersTableRows}</CompletedTransfersTableLayout>
      <SalesPagination
        page={transfersData.page.toString()}
        pagesCount={transfersData.pagesCount}
        onChange={onChangePage}
      />
    </div>
  );
};
