import { instance } from './instance';

export const drawResultsApi = {
  getWinners(raffleId: string | undefined) {
    return instance.get<GetWinnersResponseData>(`/statistics/winner/${raffleId}`);
  },
};

export interface GetWinnersResponseData {
  winners: Array<WinnerResponseType>;
}

export type WinnerResponseType = {
  prize?: string;
  telegramName?: string;
  ticket?: string;
  timeEndRaffle?: number;
  place?: number;
  countOfTickets?: number;
};
