import { AgesDisplayValues, GenderDisplayValues } from '../../../../common/shemas';

export enum ButtonUsage {
  YES = 'Да',
  NO = 'Нет',
}

export enum AdminMailingModalVariant {
  INFO = 'info',
  PASSWORD = 'password',
}

export enum Country {
  BY = 'Беларусь',
  KZ = 'Казахстан',
  RU = 'Россия',
  UZ = 'Узбекистан',
  UA = 'Украина',
  ALL = '',
}

export interface AdminMailingFormFields {
  photo: string;
  file?: any;
  countries: Array<string>;
  ages: Array<AgesDisplayValues>;
  gender: Array<GenderDisplayValues>;
  description: string;
  buttonUsage: ButtonUsage;
  buttonText: string;
  buttonLink: string;
}

export interface MailingModalData {
  isOpen: boolean;
  variant: AdminMailingModalVariant;
}

export interface AdminMailingState {
  form: AdminMailingFormFields;
  modal: MailingModalData;
  raffleId: number | null;
}

export type MailingRaffleDataType = {
  code: string;
  raffleId: number;
};

export type MailingRaffleThunkParams = Omit<MailingRaffleDataType, 'raffleId'>;

export type MailingRaffleReturnType = {
  raffleId: number;
};
