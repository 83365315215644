import { UsersGender } from './UsersGender';
import { UsersStatisticsRange } from './UsersStatisticsRange';
import s from './../WidgetsPanel.module.scss';
import { StatisticTypes, StatisticWidgetsData } from '../../types';
import { Card, CardContent } from '@mui/material';

type Props<T extends keyof StatisticWidgetsData> = {
  statistic: T;
  data: StatisticWidgetsData[T];
};
export const StatisticWidget = <T extends keyof StatisticWidgetsData>({ statistic, data }: Props<T>) => {
  if (statistic === StatisticTypes.gender) {
    return (
      <Card className={`${s.statisticWidgetContainer} ${s.gender}`}>
        <CardContent>
          <UsersGender gender={data} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={s.statisticWidgetContainer}>
      <CardContent>
        <UsersStatisticsRange data={data} statistic={statistic} />
      </CardContent>
    </Card>
  );
};
