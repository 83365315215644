import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';

import s from './TypographyUiKit.module.scss';

export type VariantTypographyType =
  | 'blockOrSectionName'
  | 'titlePrimary'
  | 'titleSecondary'
  | 'headerPrimary'
  | 'headerSecondary'
  | 'subheaderPrimary'
  | 'subheaderSecondary'
  | 'subheaderSecondaryBold'
  | 'typefaceMain'
  | 'buttonS'
  | 'buttonM'
  | 'buttonL'
  | 'titleAnyCads'
  | 'bigLink'
  | 'statisticsMore1M'
  | 'typefaceMainBold'
  | 'smallText'
  | 'smallLink'
  | 'titleSideMenu'
  | 'footer';

type TextProps<T extends ElementType> = {
  as?: T;
  variant?: VariantTypographyType;
  children?: ReactNode;
  className?: string;
  colorText?: string;
};

export function TypographyUiKit<T extends ElementType = 'p'>(
  props: TextProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof TextProps<T>>
) {
  const { variant = 'typefaceMain', colorText, className, as: Component = 'p', ...rest } = props;

  const classNames = ` ${s[variant]} ${className}`;

  return <Component className={classNames} style={{ color: colorText }} {...rest} />;
}
