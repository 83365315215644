import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { muiTheme } from '../../../features/sales/constants/customTheme';
import { ThemeProvider } from '@mui/material';

type PropsType = {
  title: string;
  callback?: () => void;
  disable?: boolean;
  textColor?: 'white' | 'black';
  color?: 'primary' | 'secondary' | 'warning' | 'info' | 'success';
  width?: string;
  margin?: string;
  type?: 'button';
  font?: 'main';
};
export const CustomButton: FC<PropsType> = ({
  title,
  font,
  callback,
  width,
  disable,
  textColor,
  margin,
  color,
  type,
}) => {
  const btnStyle = {
    width: `${width}px`,
    height: '42px',
    color: textColor === 'white' ? '#FFFFFF' : '#000000',
    borderRadius: '8px',
    textTransform: 'none',
    fontFamily: font ? 'Gilroy , sans-serif' : 'Mulish , sans-serif',
    fontWeight: font ? '700' : '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    margin: margin ? margin : '0',
    boxShadow: '0px 2px 10px rgba(109, 109, 109, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.5)',
  };
  return (
    <ThemeProvider theme={muiTheme}>
      <Button
        disabled={disable}
        variant={'contained'}
        prefix={'main'}
        onClick={callback}
        sx={btnStyle}
        type={type ? 'button' : 'submit'}
        color={color}
      >
        {title}
      </Button>
    </ThemeProvider>
  );
};
