import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../../common/path/puth';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { getClientsSelector } from '../../../managerPublicSelectors';
import { Clients } from '../../../common/components/clients/Clients';

export const ManagerClients = () => {
  const navigate = useNavigate();
  const clients = useAppSelector(getClientsSelector);
  const toAllClients = () => {
    navigate(`/sales/${PATH.salesAllClients}`);
  };

  return <Clients clients={clients} btnAction={toAllClients} variant={'sales'} />;
};
