import { RootState } from '../../../bll/store/store';

const transfersDataSelector = (state: RootState) => state.adminTransfers.transfersData;
const isTransfersDataFetchingSelector = (state: RootState) => state.adminTransfers.isTransfersDataFetching;
const isSendTransferFetchingSelector = (state: RootState) => state.adminTransfers.isSendTransferFetching;
const adminHistoryDataSelector = (state: RootState) => state.adminTransfers.historyData;
const isAdminTransferHistoryFetchingSelector = (state: RootState) => state.adminTransfers.isHistoryFetching;
const managerDataSelector = (state: RootState) => state.adminTransfers.managerData;
const isManagerDataFetchingSelector = (state: RootState) => state.adminTransfers.isManagerDataFetching;

export {
  transfersDataSelector,
  isTransfersDataFetchingSelector,
  isSendTransferFetchingSelector,
  adminHistoryDataSelector,
  isAdminTransferHistoryFetchingSelector,
  managerDataSelector,
  isManagerDataFetchingSelector,
};
