import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { apiAdmin, CustomersItemType, UsersResponseType } from '../../api/apiAdmin';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { setAppSuccessMessage } from '../../bll/reducers/appReducer';

const slice = createSlice({
  name: 'admin/users',
  initialState: {
    pagesCount: 0,
    page: 0,
    users: [] as CustomersItemType[],
    selectedUser: {} as CustomersItemType,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.usersData.items;
        state.page = action.payload.usersData.page;
        state.pagesCount = action.payload.usersData.pagesCount;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.selectedUser = action.payload.userData;
      });
  },
});

const getUsers = createAsyncThunk<{ usersData: UsersResponseType }, GetUsersURLParams | undefined>(
  'admin/getUsers',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const params = {
        searchNameTerm: arg?.email,
        page: arg?.page,
        pageSize: arg?.size,
        sortBy: arg?.sortBy,
        sortDirection: arg?.sortDirection,
      };
      const res = await apiAdmin.getCustomers(params);
      return { usersData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const getUserById = createAsyncThunk<{ userData: CustomersItemType }, string>(
  'admin/getUserById',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAdmin.getCustomerById(arg);
      return { userData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const editUser = createAsyncThunk<void, EditedUserModel>(
  'admin/editUser',
  async ({ email_address, usr_phone_number, usr_tg, rate_plan_id }, { dispatch, rejectWithValue }) => {
    const { putUserPhone, putUserTgName, putRatePlan } = apiAdmin;
    try {
      const promisesArray = [];
      usr_phone_number && promisesArray.push(putUserPhone({ email_address, usr_phone_number }));
      usr_tg && promisesArray.push(putUserTgName({ email_address, usr_tg }));
      rate_plan_id && promisesArray.push(putRatePlan({ email_address, rate_plan_id }));
      await Promise.all(promisesArray);
      dispatch(setAppSuccessMessage({ successMessage: 'Данные пользователя успешно изменены' }));
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);
export const adminUsersPending = isPending(getUsers, editUser, getUserById);
export const adminUsersFulfilled = isFulfilled(getUsers, editUser, getUserById);
export const adminUsersRejected = isRejected(getUsers, editUser, getUserById);

export const adminUsersThunks = { getUsers, editUser, getUserById };
export const adminUsersReducer = slice.reducer;

export type EditedUserModel = {
  email_address: string;
  usr_phone_number?: string | null;
  usr_tg?: string | null;
  rate_plan_id?: number;
};

export type GetUsersURLParams = {
  email?: string;
  page?: string;
  size?: string;
  sortBy?: string;
  sortDirection?: string;
};
