import React, { FC, PropsWithChildren } from 'react';
import s from './Container.module.scss';

type PropsType = {
  authBackground?: boolean;
};
export const Container: FC<PropsType & PropsWithChildren> = ({ children, authBackground }) => {
  const wrapperClassName = `${s.pageWrapper} ${authBackground ? s.authBackground : ''}`;

  return (
    <div className={wrapperClassName}>
      <div className={s.mainContainer}>{children}</div>
    </div>
  );
};
