import { useEffect, useState } from 'react';
import { ArcElement, Chart as ChartJs, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';

import s from './StatisticWidget.module.scss';
import { StatisticWidgetsData, UsersGenderType } from '../../types';
import { NAME_COLORS } from '../../constants';

ChartJs.register(ArcElement, Title, ChartDataLabels);

interface DatasetType {
  label: string;
  data: number[];
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  hoverOffset: number;
}

type Props<T extends keyof StatisticWidgetsData> = {
  gender: StatisticWidgetsData[T];
};

export const UsersGender = <T extends keyof StatisticWidgetsData>({ gender }: Props<T>) => {
  const usersGender = gender as UsersGenderType;
  const [datasets, setDataSets] = useState<DatasetType[]>([
    {
      label: 'Telegiv',
      data: [+usersGender.male, +usersGender.female],
      backgroundColor: [NAME_COLORS.COLORATE, NAME_COLORS.LIGHT_ORCHID],
      hoverBackgroundColor: [NAME_COLORS.COLORATE, NAME_COLORS.LIGHT_ORCHID],
      hoverOffset: 5,
    },
  ]);

  useEffect(() => {
    setDataSets([
      {
        label: 'Telegiv',
        data: [+usersGender.male, +usersGender.female],
        backgroundColor: [NAME_COLORS.COLORATE, NAME_COLORS.LIGHT_ORCHID],
        hoverBackgroundColor: [NAME_COLORS.COLORATE, NAME_COLORS.LIGHT_ORCHID],
        hoverOffset: 5,
      },
    ]);
  }, [gender]);

  return (
    <div className={s.boxStats}>
      <h4>Пол</h4>
      <Pie
        className={s.canvas}
        options={{
          rotation: 90,
          borderColor: 'transparent',
          offset: 15,
          plugins: {
            datalabels: {
              formatter: value => value + '%',
              color: NAME_COLORS.WHITE,
              font: {
                style: 'italic',
              },
            },
          },
        }}
        data={{
          datasets: datasets,
        }}
      />
    </div>
  );
};
