import { Dayjs } from 'dayjs';
import { MarkerDate } from '../types';

/* Функция заполняет датами другие три поля ввода на основании данных из текущего поля ввода.
Для определения в каком сейчас поле мы находимся - в функцию передается marker.
Так же в функцию передается разница между началом периода и концом периода в днях, по умолчанию 3 дня.
Функция возвращает объект со всеми вычисленными датами после ввода пользователем даты в одном из полей.
 */

export type FillDate = {
  startDayFirst: null | Dayjs;
  endDayFirst: null | Dayjs;
  startDaySecond: null | Dayjs;
  endDaySecond: null | Dayjs;
  diff: number;
  marker: MarkerDate;
};

export const fillDateFields = ({
  startDayFirst,
  endDayFirst,
  startDaySecond,
  endDaySecond,
  diff,
  marker,
}: FillDate) => {
  let currStartDayFirst: null | Dayjs = startDayFirst;
  let currEndDayFirst: null | Dayjs = endDayFirst;
  let currStartDaySecond: null | Dayjs = startDaySecond;
  let currEndDaySecond: null | Dayjs = endDaySecond;
  let diffPeriod = diff;
  switch (marker) {
    case MarkerDate.startFirst:
      if (startDayFirst?.isValid()) {
        currStartDayFirst = startDayFirst;
        currEndDayFirst = currStartDayFirst.add(diff, 'milliseconds');
        diffPeriod = currEndDayFirst.diff(currStartDayFirst);
        currEndDaySecond = startDaySecond
          ? startDaySecond.add(diffPeriod, 'milliseconds')
          : currStartDayFirst.subtract(1, 'day');
        currStartDaySecond = startDaySecond ? startDaySecond : currEndDaySecond?.subtract(diffPeriod, 'milliseconds');
        if (diffPeriod < 0) {
          currEndDayFirst = currStartDayFirst.add(1, 'day');
        }
      }
      break;
    case MarkerDate.endFirst:
      if (endDayFirst?.isValid()) {
        if (startDayFirst && endDayFirst.diff(startDayFirst) < 0) {
          currEndDayFirst = startDayFirst.add(1, 'day');
        } else currEndDayFirst = endDayFirst;
        currStartDayFirst = startDayFirst ? startDayFirst : endDayFirst.subtract(diff, 'milliseconds');
        diffPeriod = currEndDayFirst.diff(currStartDayFirst);
        currEndDaySecond = startDaySecond
          ? startDaySecond.add(diffPeriod, 'milliseconds')
          : currStartDayFirst.subtract(1, 'day');
        currStartDaySecond = startDaySecond ? startDaySecond : currEndDaySecond?.subtract(diffPeriod, 'milliseconds');

        if (diffPeriod < 0) {
          currStartDayFirst = currEndDayFirst.subtract(1, 'day');
        }
      }
      break;
    case MarkerDate.startSecond:
      if (startDaySecond?.isValid()) {
        currStartDaySecond = startDaySecond;
        currEndDaySecond = currStartDaySecond.add(diff, 'milliseconds');
        diffPeriod = currEndDaySecond.diff(currStartDaySecond);
        currEndDayFirst = startDayFirst
          ? startDayFirst.add(diffPeriod, 'milliseconds')
          : currStartDaySecond.subtract(1, 'day');
        currStartDayFirst = startDayFirst ? startDayFirst : currEndDayFirst?.subtract(diffPeriod, 'milliseconds');
        if (diffPeriod < 0) {
          currEndDaySecond = currStartDaySecond.add(1, 'day');
        }
      }
      break;
    case MarkerDate.endSecond:
      if (endDaySecond?.isValid()) {
        if (startDaySecond && endDaySecond.diff(startDaySecond) < 0) {
          currEndDaySecond = startDaySecond.add(1, 'day');
        } else currEndDaySecond = endDaySecond;
        currStartDaySecond = startDaySecond ? startDaySecond : endDaySecond.subtract(diff, 'milliseconds');
        diffPeriod = currEndDaySecond.diff(currStartDaySecond);
        currEndDayFirst = startDayFirst
          ? startDayFirst.add(diffPeriod, 'milliseconds')
          : currStartDaySecond.subtract(1, 'day');
        currStartDayFirst = startDayFirst ? startDayFirst : currEndDayFirst?.subtract(diffPeriod, 'milliseconds');
        if (diffPeriod < 0) {
          currStartDaySecond = currEndDaySecond.subtract(1, 'day');
        }
      }
      break;
  }
  return {
    startFirst: currStartDayFirst,
    endFirst: currEndDayFirst,
    startSecond: currStartDaySecond,
    endSecond: currEndDaySecond,
    diffPeriod: diffPeriod,
  };
};
