import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiAdmin, AdminAuthType } from '../../api/apiAdmin';
import { setAppStatusAC } from '../../bll/reducers/appReducer';
import { handleServerNetworkError } from '../../common/utils/error-utils';
import { isAxiosError } from 'axios';
import { RequestStatuses } from '../../common/enums/requestStatuses';

export const slice = createSlice({
  name: 'admin',
  initialState: {
    isLoggedInToken: '',
  },
  reducers: {
    removeTokenAfterLogout: state => {
      state.isLoggedInToken = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.isLoggedInToken = action.payload.accessToken;
    });
  },
});

export const adminLogin = createAsyncThunk('admin/adminLogin', async (params: AdminAuthType, thunkApi) => {
  thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.loading }));

  try {
    const res = await apiAdmin.adminLogin({
      email_address: params.email_address,
      password: params.password,
    });

    thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    sessionStorage.setItem('token', res.data.accessToken);
    return res.data;
  } catch (err) {
    if (isAxiosError(err)) {
      handleServerNetworkError(err.message, thunkApi.dispatch);

      return thunkApi.rejectWithValue(null);
    }
    return thunkApi.rejectWithValue(null);
  }
});

export const adminAuthActions = slice.actions;
