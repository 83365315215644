import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import exit from '../../../../assets/svg/exit.svg';
import style from '../Header.module.css';
import { StyledMenu } from './styleMenu';

type StyledMenuType = {
  onLogOut: () => void;
  onSettingsBot: () => void;
  toRatePlansPage: () => void;
  toAnalysisBlock: () => void;
};

export default function CustomizedMenus({ onLogOut, onSettingsBot, toRatePlansPage, toAnalysisBlock }: StyledMenuType) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={style.menu}>
      <IconButton
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-label='delete'
        size='large'
        onClick={handleClick}
        style={{ padding: 0 }}
      >
        <MenuIcon fontSize='large' />
      </IconButton>

      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <div className={style.settings} onClick={toAnalysisBlock}>
            Анализ
          </div>
        </MenuItem>

        <MenuItem onClick={handleClose} disableRipple>
          <div className={style.settings} onClick={toRatePlansPage}>
            Тарифы
          </div>
        </MenuItem>

        <MenuItem onClick={handleClose} disableRipple>
          <div className={style.settings} onClick={onSettingsBot}>
            Настройки
          </div>
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <div className={style.exitContainer} onClick={onLogOut}>
            <img src={exit} alt='' />
            <span className={style.exit}>Выход</span>
          </div>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
