import { TableStat } from '../tableStat/TableStat';
import { WidgetsPanel } from '../widgetsPanel/WidgetsPanel';

type Props = {
  className: string;
};

export const StatMain = ({ className }: Props) => {
  return (
    <main className={className}>
      <WidgetsPanel />
      <TableStat />
    </main>
  );
};
