import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiMain, ItemType } from '../../api/apiMain';
import { RootState } from '../store/store';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';

export const activeDraws = createAsyncThunk('mainFinish/activeDraws', async (_, thinkAPI) => {
  const state = thinkAPI.getState() as RootState;
  const country = state.mainFinishDraws.country;
  const { page, pageSize } = state.mainFinishDraws.draws;
  const data = {
    page,
    pageSize,
    country,
  };
  const result = await Promise.all([apiMain.getFinishedDrawsActive(), apiMain.getActiveDrawsActive(data)]);
  return [result[1].data, result[0].data];
});
export const finishDraws = createAsyncThunk('mainFinish/finishDraws', async (_, thinkAPI) => {
  const state = thinkAPI.getState() as RootState;
  const country = state.mainFinishDraws.country;
  const { page, pageSize } = state.mainFinishDraws.draws;
  const date = {
    page,
    pageSize,
    country,
  };
  const result = await Promise.all([apiMain.getFinishedDrawsFinish(date), apiMain.getActiveDrawsFinish()]);
  return [result[0].data, result[1].data];
});

export const getActiveRafflesByCountry = createAsyncThunk<{ activeRaffleByCountry: ItemType[] }, void>(
  'raffle/setActiveRafflesByCountry',
  async (_, thunkAPI) => {
    try {
      const response = await apiMain.getActiveRaffleCountry();
      return { activeRaffleByCountry: response.data };
    } catch (error) {
      thunkErrorHandler(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const slice = createSlice({
  name: 'mainFinish',
  initialState: {
    draws: {
      page: 1,
      pagesCount: 0,
      pageSize: 8,
      totalCount: 0,
      active: [] as ItemType[],
      finished: [] as ItemType[],
    },
    country: '',
  },
  reducers: {
    setCountry: (state, action: PayloadAction<{ country: string }>) => {
      state.country = action.payload.country;
    },
    setPage: (state, action: PayloadAction<{ page: number }>) => {
      state.draws.page = action.payload.page;
    },
    refreshItems: (state, action) => {
      state.draws.active = [];
      state.draws.finished = [];
    },
    setActiveRafflesByCountry: (state, action: PayloadAction<ItemType[]>) => {
      state.draws.active = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(finishDraws.fulfilled, (state, action) => {
      const activeDraws = action.payload[1].items.slice(0, 4);
      return {
        draws: {
          page: action.payload[0].page,
          pageSize: action.payload[0].pageSize,
          active: activeDraws,
          finished: action.payload[0].items,
          totalCount: action.payload[0].totalCount,
          pagesCount: action.payload[0].pagesCount,
        },
        country: state.country,
      };
    });
    builder.addCase(getActiveRafflesByCountry.fulfilled, (state, { payload }) => {
      state.draws.active = payload.activeRaffleByCountry;
    });
    builder.addCase(activeDraws.fulfilled, (state, action) => {
      // const finishDraws = action.payload[1].items.slice(-4, action.payload[1].items.length)
      const finishDraws = action.payload[1].items.slice(0, 4);
      return {
        draws: {
          page: action.payload[0].page,
          pageSize: action.payload[0].pageSize,
          active: action.payload[0].items,
          finished: finishDraws,
          totalCount: action.payload[0].totalCount,
          pagesCount: action.payload[0].pagesCount,
        },
        country: state.country,
      };
    });
  },
});

export const { setCountry, setPage, refreshItems, setActiveRafflesByCountry } = slice.actions;
