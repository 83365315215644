import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import s from './TableHeadStat.module.scss';
import { headCells } from '../../constants';
import { Data } from '../../types';

import { IconSort } from './IconSort';
import { SORT_DIRECTION } from '../../../../common/constants/enumConstants';

type SortingTableProps = {
  order: SORT_DIRECTION;
  orderBy: string;
  rowCount: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
};

export const TableHeadStat = ({ order, orderBy, onRequestSort }: SortingTableProps) => {
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={s.tableHeadContainer}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={s.lableHead}
              IconComponent={() => <IconSort order={order} orderBy={orderBy} headCellId={headCell.id} />}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
