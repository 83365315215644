import s from './SelectorBar.module.scss';
import DropdownMenu from './dropdownMenu/DropdownMenu';
import { MENU_ITEM_OPTIONS } from '../../constants';
import { PeriodMenu } from './periodMenu/PeriodMenu';

export const SelectorBar = () => {
  return (
    <div className={s.selectorMenuContainer}>
      <PeriodMenu />
      {MENU_ITEM_OPTIONS.map(options => (
        <DropdownMenu key={options.join('')} options={options} />
      ))}
    </div>
  );
};
