import { RootState } from '../../bll/store/store';

const isCustomerInitializedSelector = (state: RootState) => state.customerLogin.isInitialized;
const isCustomerLoggedInSelector = (state: RootState) => state.customerLogin.isCustomerLoggedIn;
const isCustomerBotExistSelector = (state: RootState) => state.customerLogin.botExist;
const isCustomerEmailConfirmSelector = (state: RootState) => state.customerLogin.customerInfo.email_confirmation;
const customerEmailSelector = (state: RootState) => state.customerLogin.customerInfo.email_address;
const getCustomerInfoSelector = (state: RootState) => state.info.customerInfo;
const isCustomerSubscribersLimitedSelector = (state: RootState) => state.info.customerInfo.limited;
const customerRatePlanIdSelector = (state: RootState) => state.customerLogin.customerInfo.rate_plan_id;
const customerAllSubsSelector = (state: RootState) => Number(state.raffle.statistic.all_sub);

export {
  isCustomerInitializedSelector,
  isCustomerLoggedInSelector,
  isCustomerBotExistSelector,
  isCustomerEmailConfirmSelector,
  customerEmailSelector,
  getCustomerInfoSelector,
  isCustomerSubscribersLimitedSelector,
  customerRatePlanIdSelector,
  customerAllSubsSelector,
};
