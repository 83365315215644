import { FC, PropsWithChildren } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import s from './Modal.module.scss';

export type ModalProps = {
  onClose?: () => void;
  isOpen: boolean;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ isOpen, onClose, children }) => {
  function handleModalClosed() {
    onClose?.();
  }

  return (
    <Dialog.Dialog open={isOpen} onOpenChange={handleModalClosed}>
      <Dialog.Portal>
        <Dialog.Overlay className={s.overlay} />
        <Dialog.Content className={s.content}>{children}</Dialog.Content>
      </Dialog.Portal>
    </Dialog.Dialog>
  );
};

export const CloseModalButton: FC<PropsWithChildren> = ({ children }) => {
  return <Dialog.Close asChild>{children}</Dialog.Close>;
};
