import { HeadCell } from './types';

export const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    label: 'Номер шаблона',
  },
  {
    id: 'ignored',
    label: 'Динамят',
  },
  {
    id: 'blocked',
    label: 'Заблокировали',
  },
  {
    id: 'returned',
    label: 'Вернулись',
  },
  {
    id: 'reached',
    label: 'Дошли',
  },
];

export const UsersAgeTitles = {
  age_18: 'до 18',
  age_18_24: '18-24',
  age_25_34: '25-34',
  age_35_44: '35-44',
  age_45: '45+',
};
export const UsersCountryTitles = {
  belarus: 'Беларусь',
  rus: 'Россия',
  ukr: 'Украина',
  kz: 'Казахстан',
  uzb: 'Узбекистан',
  other_country: 'Другие',
};
export const NAME_COLORS = {
  COLORATE: '#7F7FD5',
  LIGHT_ORCHID: '#E786D7',
  WHITE: '#ffffff',
};

const TIME = ['Всё время', 'Сегодня', 'Вчера', '7 дней', '30 дней', '3 месяцев', 'Пол года', 'Год'];
const RAFFLE = ['Все', 'Уникальные'];
const RAFFLE_STATUS = ['Всё розыгрыши', 'Завершенные', 'Активные'];

export const MENU_ITEM_OPTIONS = [RAFFLE, TIME, RAFFLE_STATUS];
