import React, { useEffect } from 'react';
import s from './SalesBalance.module.scss';
import { Balance } from './balance/Balance';
import { BalanceHistory } from './history/BalanceHistory';
import { salesBalanceThunks } from './salesBalanceSlice';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/storeHooks';
import { PreLoader } from '../../common/components/preLoader/PreLoader';
import { isBalanceFetchingSelector } from './salesBalanceSelectors';

export const SalesBalance = () => {
  const dispatch = useAppDispatch();
  const isBalanceFetching = useAppSelector(isBalanceFetchingSelector);

  useEffect(() => {
    dispatch(salesBalanceThunks.getBalance());
  }, [dispatch]);

  if (isBalanceFetching) return <PreLoader />;

  return (
    <div className={s.salesBalanceBlock}>
      <Balance />
      <BalanceHistory />
    </div>
  );
};
