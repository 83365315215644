import React, { FC } from 'react';
import { CustomForm } from '../../../../../common/components/customForm/CustomForm';
import { CommonInput } from '../../../../../common/components/commonInput/CommonInput';
import { useFormik } from 'formik';
import { FormikValuesType } from '../../../common/types/commonTypes';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../../../common/hooks/storeHooks';
import { salesAuthThunks } from '../../../salesAuthSlice';
import { RECOVER_STEPS } from './SalesRecoverPass';

type PropsType = {
  onSetStep: (value: RECOVER_STEPS) => void;
  onSetEmail: (value: string) => void;
};
export const SendCode: FC<PropsType> = ({ onSetStep, onSetEmail }) => {
  const dispatch = useAppDispatch();
  const formik = useFormik<FormikValuesType>({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Пожалуйста, введи Email').email('Некорректный Email'),
    }),
    onSubmit: values => {
      if (values.email) {
        const params: { email: string } = {
          email: values.email,
        };
        dispatch(salesAuthThunks.resetPassword(params))
          .unwrap()
          .then(() => {
            onSetEmail(params.email);
            onSetStep(RECOVER_STEPS.checkEmail);
            formik.resetForm();
          });
      }
    },
  });

  return (
    <CustomForm
      formTitle={'Восстановление пароля'}
      btnColor={'primary'}
      btnTitle={'Выслать код на email'}
      handleSubmit={formik.handleSubmit}
    >
      <CommonInput name={'EMAIL'} placeholder={'Email'} inputName={'email'} formik={formik} />
    </CustomForm>
  );
};
