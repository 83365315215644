import { MouseEvent, FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  to: string;
};
export const ScrollLink: FC<Props> = ({ children, to }) => {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const element = document.getElementById(to);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <a href={`#${to}`} onClick={handleClick}>
      {children}
    </a>
  );
};
