import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCustomer, RaffleTypeById } from './API/customersAPI';

export const getRaffleByIdCommon = createAsyncThunk<RaffleTypeById, { id: number }>(
  'drawCom/getRaffleByIdCommon',
  async (arg, thunkApi) => {
    const result = await apiCustomer.getRaffleById(arg.id);
    return result.data;
  }
);

export const slice = createSlice({
  name: 'drawCommon',
  initialState: {} as RaffleTypeById,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRaffleByIdCommon.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});
