import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Card, CardContent } from '@mui/material';
import { useState } from 'react';

type Props = {
  options: string[];
};

export default function DropdownMenu({ options }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const open = Boolean(anchorEl);

  const cls = {
    card: {
      background: 'var(--color-black-11)',
      borderEndEndRadius: open ? 0 : '0.4rem',
      borderEndStartRadius: open ? 0 : '0.4rem',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--color-black-12)',
      },
    },
    cardContent: {
      width: '20rem',
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    list: {
      color: 'var(--color-white-3)',
      padding: 0,
    },
    listItem: {
      fontSize: 'var(--font-size-m)',
      padding: '2.4rem 1.2rem',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '2.4rem',
    },
    menu: { '& .MuiMenu-paper': { borderRadius: 0 } },
    menuList: {
      padding: 0,
      borderRadius: 0,
      background: 'var(--color-black-11)',
    },
    menuItem: {
      fontSize: 'var(--font-size-m)',
      padding: '2.4rem 1.2rem',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '2.4rem',
      color: 'var(--color-white-3)',
      width: '20rem',
      '&.Mui-selected': {
        background: 'var(--color-black-14)',
      },
      '&:hover': {
        background: 'var(--color-black-14)',
      },
      '&.Mui-selected:hover': {
        background: 'var(--color-black-14)',
      },
    },
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={cls.card}>
      <CardContent sx={cls.cardContent}>
        <List component='nav' aria-label='all-time' sx={cls.list}>
          <ListItem
            sx={cls.listItem}
            id='lock-button'
            aria-haspopup='listbox'
            aria-controls='lock-menu'
            aria-label='all-time'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickListItem}
          >
            {options[selectedIndex]}
          </ListItem>
        </List>
        <Menu
          id='lock-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={cls.menu}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
            sx: cls.menuList,
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              sx={cls.menuItem}
              key={option}
              selected={index === selectedIndex}
              onClick={event => handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </CardContent>
    </Card>
  );
}
