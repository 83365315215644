import { Outlet } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import { isAuthorizedSelector } from '../salesAuthSelectors';
import { Header } from '../../../components/header/Header';
import { Footer } from '../../../components/footer';

export const SalesLanding = () => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  return (
    <>
      <div style={{ backgroundColor: 'var(--color-violet-2)' }}>
        <Header isNav dark isAuth={isAuthorized} />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};
