export interface Data {
  id: number;
  ignored: number;
  blocked: number;
  returned: number;
  reached: number;
}

export interface HeadCell {
  id: keyof Data;
  label: string;
}
export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export interface InfoDataUsers {
  count: string;
  increasedByPercentage: string;
  title: string;
}

export interface InfoWidgetsData {
  totalUsers: InfoDataUsers;
  activeUsers: InfoDataUsers;
  blockedUsers: InfoDataUsers;
  nonSubscribersUsers: InfoDataUsers;
  nonRegisteredUsers: InfoDataUsers;
  virality: InfoDataUsers;
}

export type UsersGenderType = {
  male: string;
  female: string;
};
export type UsersAgeType = {
  age_18: string;
  age_18_24: string;
  age_25_34: string;
  age_35_44: string;
  age_45: string;
};
export type UsersCountryType = {
  belarus: string;
  rus: string;
  ukr: string;
  kz: string;
  uzb: string;
  other_country: string;
};

export type StatisticWidgetsData = {
  gender: UsersGenderType;
  age: UsersAgeType;
  country: UsersCountryType;
};

export enum StatisticTypes {
  age = 'age',
  gender = 'gender',
  country = 'country',
}
export enum MarkerDate {
  startFirst = 'startFirst',
  endFirst = 'endFirst',
  startSecond = 'startSecond',
  endSecond = 'endSecond',
}
export enum VariantCalendar {
  first = 'First',
  second = 'Second',
}

export enum StatisticTitle {
  country = 'Страны',
  age = 'Возраст',
}
