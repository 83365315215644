import React, { FC } from 'react';
import s from '../../styles/CommonStyles.module.scss';
import { NoContent } from '../noContent/NoContent';
import { TeamTable } from '../../../components/salesHome/team/teamTable/TeamTable';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';
import { TeammateType } from '../../../API/apiPublicManager';
import { BlockTitle } from '../blockTitle/BlockTitle';

type PropsType = {
  team: TeammateType[];
  btnAction: () => void;
  variant: 'sales' | 'admin';
};
export const Team: FC<PropsType> = ({ team, btnAction, variant }) => {
  const isTeamEmpty = !team.length;
  const noContentMessage =
    variant === 'sales' ? 'В твоей команде пока никого нет' : 'В команде менеджера пока никого нет';

  return (
    <div className={s.clientsBlock}>
      <BlockTitle title={'Команда'} />
      {isTeamEmpty ? (
        <NoContent title={noContentMessage} />
      ) : (
        <>
          <TeamTable team={team} />
          <CustomButton
            title={'Вся команда'}
            textColor={'white'}
            color={'info'}
            width={'150'}
            margin={'23px 0 0 0'}
            font={'main'}
            callback={btnAction}
          />
        </>
      )}
    </div>
  );
};
