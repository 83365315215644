import React, { FC, useState } from 'react';
import s from './AdminManagerInfo.module.scss';
import { CopiedToClipboard } from '../../../../sales/common/components/copiedToClipboard/CopiedToClipboard';
import { TG_FIELD_DEFAULT_VALUE } from '../../../../../common/constants/links';

export enum AdminManagerContactCellMode {
  COPY = 'copy',
  NAVIGATE = 'navigate',
}

type PropsType = {
  text: string;
  allowClickForCopy?: boolean;
  mode?: AdminManagerContactCellMode;
};
export const AdminManagerContactCell: FC<PropsType> = ({
  text,
  allowClickForCopy,
  mode = AdminManagerContactCellMode.COPY,
}) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const contactCellActionByMode = {
    [AdminManagerContactCellMode.COPY]: () => {
      navigator.clipboard.writeText(text).then(() => {
        setShowCopyMessage(true);
        setTimeout(() => {
          setShowCopyMessage(false);
        }, 1500);
      });
    },
    [AdminManagerContactCellMode.NAVIGATE]: () => {
      window.open(`${TG_FIELD_DEFAULT_VALUE}/${text}`, '_blank', 'noreferrer');
    },
  };

  const cellStyle = `${s.contact} ${allowClickForCopy ? s.allowPointerEvent : ''}`;

  return (
    <div className={cellStyle} onClick={contactCellActionByMode[mode]}>
      {!showCopyMessage && text}
      {showCopyMessage && <CopiedToClipboard copiedText={'Скопировано'} />}
    </div>
  );
};
