import { analysisInstance } from './analysisInstance';
import {
  AddChannelValuesType,
  GetFiltrationResponse,
  GetPaginationResponse,
  ResponseBotLinkData,
} from '../common/types/types';
import { AxiosResponse } from 'axios';

export const apiAnalysis = {
  getUserChannels(params: GetPaginationResponse) {
    return analysisInstance.get(`/analytics/channels`, {
      params: {
        page: params.page,
        size: params.size,
        sortBy: params.sortBy,
        sortDirection: params.sortDirection,
      },
    });
  },
  getTotalChannels(params: GetFiltrationResponse) {
    return analysisInstance.get(`/analytics/channels/total`, {
      params: {
        page: params.page,
        size: params.size,
        sortBy: params.sortBy,
        sortDirection: params.sortDirection,
        countries: params.countries,
        cpaAt: params.cpaAt,
        priceBefore: params.priceBefore,
        priceAt: params.priceAt,
      },
    });
  },
  postDataAnalysis(payload: AddChannelValuesType) {
    return analysisInstance.post<AddChannelValuesType, AxiosResponse<ResponseBotLinkData>>(
      `/analytics/create`,
      payload
    );
  },
};
