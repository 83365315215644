import React, { ElementRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { StyledTextFieldPropsModel, TextFieldPropsModel } from '../types';
import SearchIcon from '../../../../assets/images/svg/search-icon.svg';
import { Typography } from '../../../../../components/typography';

export const TextArea = React.memo(
  forwardRef<ElementRef<'textarea'>, TextFieldPropsModel & { label?: string }>(
    ({ error, errorText, label, children, ...restProps }, ref) => {
      return (
        <Root>
          <Typography variant={'subheader_secondary'}>{label}</Typography>
          <StyledTextArea {...restProps} $error={error} ref={ref}>
            {children}
          </StyledTextArea>

          {restProps.iconImg && <Img src={restProps.iconImg} />}

          {error && errorText && (
            <Typography variant={'error'} lineHeight={'24px'}>
              {errorText}
            </Typography>
          )}
        </Root>
      );
    }
  )
);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  position: relative;
`;
const Img = styled.img`
  position: absolute;
  top: 16px;
  left: 20px;
  width: 20px;
  height: 20px;
`;

const StyledTextArea = styled.textarea<StyledTextFieldPropsModel>`
  width: 100%;
  height: 106px;
  border: none;
  border-radius: 17px;
  background-color: #302f2f;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.36px;
  padding: 16px 20px;
  resize: none;
  border: ${props => props.$error && '1px solid var(--color-error)'};

  ${props =>
    props.type === 'search' || props.$iconImg
      ? css`
          padding-left: 45px;

          &::-webkit-search-cancel-button {
            -webkit-appearance: none;
          }
        `
      : ''}
`;
