import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { adminMailingModalSelector, handleMailingModal, setMailingModalVariant } from '../../bll';
import { AdminMailingModalVariant } from '../../types';

export const useAdminMailingModal = () => {
  const mailingModal = useAppSelector(adminMailingModalSelector);
  const dispatch = useAppDispatch();

  const onOpen = () => dispatch(handleMailingModal(true));
  const onClose = () => {
    dispatch(handleMailingModal(false));
  };

  const onToggle = () => dispatch(handleMailingModal(!mailingModal.isOpen));

  const setModalVariant = () => {
    if (mailingModal.variant === AdminMailingModalVariant.INFO) {
      dispatch(setMailingModalVariant(AdminMailingModalVariant.PASSWORD));
    }
  };

  return {
    isOpen: mailingModal.isOpen,
    variant: mailingModal.variant,
    onOpen,
    onClose,
    onToggle,
    setModalVariant,
  };
};
