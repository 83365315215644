import React, { FC } from 'react';
import { StatsWrapper } from './StatsWrapper';
import { StatsItem } from './StatsItem';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { incomeFromClientsSelector, incomeFromTeamSelector, totalProfitSelector } from '../../../managerInfoSelectors';

type PropsType = {};
export const Income: FC<PropsType> = () => {
  const totalProfit = useAppSelector(totalProfitSelector);
  const incomeFromClients = useAppSelector(incomeFromClientsSelector);
  const incomeFromTeam = useAppSelector(incomeFromTeamSelector);

  return (
    <StatsWrapper>
      <StatsItem title={'Заработок'} value={`${totalProfit}$`} cashValue headingTitle />
      <StatsItem title={'Клиенты'} value={`${incomeFromClients}$`} cashValue />
      <StatsItem title={'Команда'} value={`${incomeFromTeam}$`} cashValue />
    </StatsWrapper>
  );
};
