import { RootState } from '../../bll/store/store';
const managerEmailAddressSelector = (state: RootState) => state.adminManager.managerData.manager.email;
const managerBalanceSelector = (state: RootState) => state.adminManager.managerData.manager.balance;
const managerPromoCodeSelector = (state: RootState) => state.adminManager.managerData.manager.promo;
const isIncomeActivatedSelector = (state: RootState) => state.adminManager.managerData.manager.isActivated;
const managerPhoneNumberSelector = (state: RootState) => state.adminManager.managerData.manager.phoneNumber;
const managerTelegramSelector = (state: RootState) => state.adminManager.managerData.manager.tgUsername;
const managerClientsStatsSelector = (state: RootState) => state.adminManager.managerData.managerClients;
const managerProfitSelector = (state: RootState) => state.adminManager.managerData.profit;
const managerTeamStatsSelector = (state: RootState) => state.adminManager.managerData.teamMembers;
const getManagerClientsSelector = (state: RootState) => state.adminManager.managerClients.items;
const managerClientsPagesCountSelector = (state: RootState) => state.adminManager.managerClients.pagesCount;
const getManagerTeamSelector = (state: RootState) => state.adminManager.managerTeam.items;
const managerTeamPagesCountSelector = (state: RootState) => state.adminManager.managerTeam.pagesCount;
const withdrawIdSelector = (state: RootState) => state.adminManager.managerData.withdrawId;
const managerHistoryDataSelector = (state: RootState) => state.adminManager.historyData;
const adminManagersDataSelector = (state: RootState) => state.adminManager.managersData.items;
const adminManagersPagesCountSelector = (state: RootState) => state.adminManager.managersData.pagesCount;
const isAdminManagerHistoryFetchingSelector = (state: RootState) => state.adminManager.isHistoryFetching;

export {
  managerEmailAddressSelector,
  managerBalanceSelector,
  managerPromoCodeSelector,
  isIncomeActivatedSelector,
  managerPhoneNumberSelector,
  managerTelegramSelector,
  managerClientsStatsSelector,
  managerProfitSelector,
  managerTeamStatsSelector,
  getManagerClientsSelector,
  managerClientsPagesCountSelector,
  getManagerTeamSelector,
  managerTeamPagesCountSelector,
  withdrawIdSelector,
  managerHistoryDataSelector,
  adminManagersDataSelector,
  adminManagersPagesCountSelector,
  isAdminManagerHistoryFetchingSelector,
};
