export const ratePlans: { [key: number]: number } = {
  1: 1000,
  2: 3000,
  3: 10000,
  4: 25000,
  5: 100000,
  6: 1000000000,
};

export const getPlanCount = (planId: number) => {
  const plan = ratePlans[planId];
  if (planId === 6) {
    return '\u{221E}';
  }
  return plan?.toString().replace('000', '');
};
