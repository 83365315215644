import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';
import { MarkerDate } from '../../../../types';

type Props = {
  value: null | Dayjs;
  label: string;
  name: MarkerDate;
  toggleBlur: boolean;
  setToggleBlur: (value: boolean) => void;
  setValue: (value: dayjs.Dayjs | null) => void;
  setMarker: (value: MarkerDate) => void;
};
export const FieldDate = ({ value, label, name, toggleBlur, setValue, setToggleBlur, setMarker }: Props) => {
  const currDay = dayjs();
  const cls = {
    dataFieldContent: {
      width: '13.4rem',
      '& legend span': {
        paddingRight: '1.5rem',
      },
      '& .MuiFormLabel-root': {
        color: 'var(--color-white-4)',
        fontSize: 'var(--font-size-m)',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'var(--color-violet-1-stat)',
      },
      '& .MuiFormLabel-root.Mui-focused.Mui-error': {
        color: 'var(--color-error-2)',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'var(--color-error-2)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiFormLabel-root': {
        color: 'var(--color-violet-1-stat)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: '0.1rem solid var(--color-violet-1-stat)',
      },
      '& .MuiOutlinedInput-input': {
        color: 'var(--color-white-4)',
        fontSize: 'var(--font-size-m)',
        outline: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '0.3rem solid var(--color-violet-1-stat)',
      },
      '& .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline ': {
        border: '0.1rem solid var(--color-error-2)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0.1rem solid var(--color-white-6)',
        borderRadius: '0.4rem',
      },
      '& .MuiOutlinedInput-notchedOutline.Mui-focused': {
        outline: '0.1rem solid var(--color-violet-1-stat)',
        borderRadius: '0.4rem',
      },
    },
  };

  const calculateDates = () => {
    setToggleBlur(!toggleBlur);
    setMarker(name);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') calculateDates();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/*  <DateField
        format='DD/MM/YYYY'
        label={label}
        value={value}
        name={name}
        maxDate={currDay}
        onBlur={calculateDates}
        onChange={newValue => setValue(newValue)}
        onKeyDownCapture={handlePressEnter}
        sx={cls.dataFieldContent}
      />*/}
    </LocalizationProvider>
  );
};
