import s from './AboutUs.module.scss';
import React from 'react';
import { ReactComponent as Cup } from '../../../../../../assets/image/sales/landing/cards/cup.svg';
import { ReactComponent as Timer } from '../../../../../../assets/image/sales/landing/cards/timer.svg';
import { ReactComponent as Gift } from '../../../../../../assets/image/sales/landing/cards/gift.svg';
import { ReactComponent as Brain } from '../../../../../../assets/image/sales/landing/cards/brain.svg';

const text1 = (
  <>
    <span className={s.longText}>TELEGIV.com - это</span>
    <span className={s.shortText}>Мы</span>- крупнейший сервис розыгрыша призов в Telegram
  </>
);

const text2 = (
  <>
    <span className={s.longText}>
      У наc более 1000 клиентов: от маленьких магазинов до мировых брендов и топовых блогеров.
    </span>
    <span className={s.shortText}>У наc более 1000 клиентов </span>
  </>
);

const text3 = (
  <>
    <span className={s.longText}>
      Мы работаем более 10 лет и по опыту знаем, как делать максимально вирусные розыгрыши.
    </span>
    <span className={s.shortText}>Мы создаем вирусные розыгрыши</span>
  </>
);
const text4 = (
  <>
    Мы разработали искусственный интеллект<span className={s.longText}>, который управляет розыгрышами.</span>
  </>
);

export const aboutUsCards = [
  { title: 'top', desc: text1, img: <Cup /> },
  {
    title: '> 10 ЛЕТ',
    desc: text3,
    img: <Timer />,
  },
  {
    title: '>1к',
    desc: text2,
    img: <Gift />,
  },
  {
    title: 'ИИ',
    desc: text4,
    img: <Brain />,
  },
];
