import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  const isPop = useBackButton();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname, isPop]);

  useEffect(() => {
    window.addEventListener('beforeunload', scrollToTop);
    return () => {
      window.removeEventListener('beforeunload', scrollToTop);
    };
  }, []);
};
