import React, { FC, PropsWithChildren } from 'react';
import s from './ClientsTable.module.scss';
import { useWindowDimensions } from '../../../../../../common/utils/useWindowDimensions';

export const ClientsTableLayout: FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowDimensions();
  const CHANGE_COL_TITLE_WIDTH = 705;
  const isTitleChange = width <= CHANGE_COL_TITLE_WIDTH;

  return (
    <table className={s.table}>
      <thead>
        <tr>
          <th className={s.dateCol}>Дата регистрации</th>
          <th className={s.emailCol}>email</th>
          <th className={s.shortCountCol}></th>
          <th className={s.subscribersCountCol}>{isTitleChange ? 'Подписчиков' : 'Кол-во подписчиков'}</th>
          <th className={s.telegramCol}>Telegram</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
