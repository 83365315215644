import React, { FC } from 'react';
import { PendingTransfersTableLayout } from './PendingTransfersTableLayout';
import { PendingTransfersTableRow } from './PendingTransfersTableRow';
import { serverDateFormat } from '../../../../sales/common/utils/serverDateFormat';
import { GetTransfersResponseType } from '../../../API/adminTransfersApi';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { isTransfersDataFetchingSelector } from '../../adminTransfersSelectors';
import { CircularProgress } from '@mui/material';
import { SalesPagination } from '../../../../sales/common/components/pagination/Pagination';

type Props = {
  transfersData: GetTransfersResponseType;
  onChangePage: (page: string) => void;
};

export const PendingTransfersTable: FC<Props> = ({ transfersData, onChangePage }) => {
  const isTransfersDataFetching = useAppSelector(isTransfersDataFetchingSelector);

  const mappedUsersTableRows = transfersData.items.map(
    ({ dateTransaction, sum, id, managerId, tgUsername, email, phoneNumber, teamMembers, paidClients }) => {
      const transactionDate = serverDateFormat(dateTransaction ?? '');
      return (
        <PendingTransfersTableRow
          key={id}
          id={id}
          managerId={managerId}
          transactionDate={transactionDate}
          sum={sum}
          tgUsername={tgUsername || 'TG не указан'}
          email={email}
          phoneNumber={phoneNumber || 'Номер не указан'}
          teamMembers={teamMembers}
          paidClients={paidClients}
        />
      );
    }
  );

  if (isTransfersDataFetching) return <CircularProgress />;

  return (
    <div>
      {transfersData.items.length ? (
        <>
          <PendingTransfersTableLayout>{mappedUsersTableRows}</PendingTransfersTableLayout>
          <SalesPagination
            page={transfersData.page.toString()}
            pagesCount={transfersData.pagesCount}
            onChange={onChangePage}
          />
        </>
      ) : (
        <div style={{ fontSize: '16px' }}>Нет запросов на перевод</div>
      )}
    </div>
  );
};
