import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateCreatorType } from '../../common/utils/dateCreator';
import { ItemsType } from './components/createDraw/components/AddItems';
import { apiCustomer } from './API/customersAPI';
import { raffleCreator } from '../../common/utils/raffleCreator';
import { RootState } from '../../bll/store/store';
import { setAppStatusAC } from '../../bll/reducers/appReducer';
import { isAxiosError } from 'axios';
import { handleServerNetworkError } from '../../common/utils/error-utils';
import { formDateCreator } from '../../common/utils/formDate';
import { currentEndTime, currentTime, timeAdapter } from '../../common/utils/currentTime';
import { v4 as uuidv4 } from 'uuid';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { raffles } from './components/profile/utils/raffles';
import { RequestStatuses } from '../../common/enums/requestStatuses';

export const startDrawBot = createAsyncThunk<void, void>(
  'draw/startDrawBot',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
      await apiCustomer.startDrawBot();
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    } finally {
      dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    }
  }
);

export const getDrawForm = createAsyncThunk<any, void, { rejectValue: { message: string } }>(
  'draw/getDrawForm',
  async (param, thunkApi) => {
    thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
    try {
      const State = thunkApi.getState() as RootState;
      const result = raffleCreator(State);
      // this logic botId for python bot
      // const botId = await apiCustomer.getBotId();

      const formDate = await formDateCreator(result, State, 4);
      await apiCustomer.createRaffle(formDate);

      thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    } catch (err) {
      if (isAxiosError(err)) {
        handleServerNetworkError(err.message, thunkApi.dispatch);
        return thunkApi.rejectWithValue({ message: 'упс что-то пошло не так попробуйте еще раз' });
      }
      handleServerNetworkError('упс что-то пошло не так попробуйте еще раз', thunkApi.dispatch);
      return thunkApi.rejectWithValue({ message: 'упс что-то пошло не так попробуйте еще раз' });
    }
  }
);

export const getRaffleById = createAsyncThunk('draw/getRaffleById', async (id: number, thunkApi) => {
  // const info = await apiCustomer.customerInfo()
  // const raffle = await apiCustomer.getRaffleById(id)
  // const result = Promise.all([info,raffle])
  // return result

  //закоментировал старый код который ищет по id розыгрыш так что если код снизу не выйдет просто расскоментирух верхний

  const raffle = await apiCustomer.getRaffle();
  const { planned } = raffles(raffle.data);
  const info = await apiCustomer.customerInfo();
  const test: any = planned;
  const result = Promise.all([info, test]);
  return result;
});

export const updateRaffle = createAsyncThunk<any, number, { rejectValue: { message: string } }>(
  'draw/updateRaffle',
  async (id: number, thunkApi) => {
    try {
      thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
      const State = thunkApi.getState() as RootState;
      const result = raffleCreator(State);
      const botId = await apiCustomer.getBotId();
      const formDate = await formDateCreator(result, State, botId);
      await apiCustomer.putRaffle(formDate, id);
      thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    } catch (err) {
      if (isAxiosError(err)) {
        handleServerNetworkError(err.message, thunkApi.dispatch);
        return thunkApi.rejectWithValue({ message: 'упс что-то пошло не так попробуйте еще раз' });
      }
      handleServerNetworkError('упс что-то пошло не так попробуйте еще раз', thunkApi.dispatch);
      return thunkApi.rejectWithValue({ message: 'упс что-то пошло не так попробуйте еще раз' });
    } finally {
      // commented because when editing the giveaway it didn't show the final modal window
      // thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.failed }));
    }
  }
);

export enum DrawMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type InitialStateType = {
  drawData: DrawData;
  mode: DrawMode;
  drawId: number | null;
};

const initialState: InitialStateType = {
  drawData: {
    photo: '',
    startDate: {
      day: currentTime().day,
      month: currentTime().month,
      year: currentTime().year,
    },
    startTime: { hours: currentTime().hours, minutes: currentTime().minutes },
    endDate: {
      day: currentEndTime().day,
      month: currentEndTime().month,
      year: currentEndTime().year,
    },
    endTime: { hours: 0, minutes: 0 },
    phone: '',
    telegram: '',
    prizes: [] as ItemsType[],
    sponsors: [] as ItemsType[],
    countries: ['all'],
    editableHeader: '',
    description: '',
    header: '',
    file: '',
  },
  mode: DrawMode.CREATE,
  drawId: null,
};

export const slice = createSlice({
  name: 'draw',
  initialState,
  reducers: {
    setDrawMode(state, action: PayloadAction<DrawMode>) {
      state.mode = action.payload;
    },
    setDraw(state, action: PayloadAction<DrawFormikValuesType>) {
      state.drawData = {
        ...action.payload,
        description: state.drawData.description,
        header: state.drawData.header,
        editableHeader: state.drawData.editableHeader,
      };
    },
    setEditableHeader(state, action: PayloadAction<{ editableHeader: string }>) {
      state.drawData.editableHeader = action.payload.editableHeader;
    },
    setMessageDraw(state, action: PayloadAction<{ description: string; header: string }>) {
      state.drawData.description = action.payload.description;
      state.drawData.header = action.payload.header;
    },
    setCounties(state, action: PayloadAction<{ allValues: DrawData; countries: string[] }>) {
      return {
        ...state,
        ...action.payload.allValues,
        countries: [...action.payload.countries],
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getRaffleById.fulfilled, (state, { payload }) => {
      // return {
      //   prizes : payload[1].mockData.prizes!.flat().map((el)=> ({id : uuidv4(),title : el})),
      //   header : payload[1].mockData.header!,
      //   description : payload[1].mockData.description!,
      //   countries : payload[1].mockData.countries!.flat(),
      //   sponsors :payload[1].mockData.sponsors!.flat().map((el)=> ({id : uuidv4(),title : el})),
      //   file : payload[1].mockData.fileName,
      //   photo : payload[1].mockData.fileName!,
      //   endDate : {
      //     day :   timeAdapter(payload[1].mockData.end_timestamp!).day,
      //     month :  timeAdapter(payload[1].mockData.end_timestamp!).month,
      //     year:  timeAdapter(payload[1].mockData.end_timestamp!).year
      //   },
      //   endTime : {
      //     hours : timeAdapter(payload[1].mockData.end_timestamp!).hours,
      //     minutes : timeAdapter(payload[1].mockData.end_timestamp!).minutes
      //   },
      //   startDate : {
      //     day :   timeAdapter(payload[1].mockData.begin_timestamp!).day,
      //     month :  timeAdapter(payload[1].mockData.begin_timestamp!).month,
      //     year:  timeAdapter(payload[1].mockData.begin_timestamp!).year
      //   },
      //   startTime : {
      //     hours : timeAdapter(payload[1].mockData.begin_timestamp!).hours,
      //     minutes : timeAdapter(payload[1].mockData.begin_timestamp!).minutes
      //   },
      //   phone : payload[0].mockData.usr_phone_number,
      //   telegram : payload[0].mockData.usr_tg,
      // }
      //закоментировал старый код который ищет по id розыгрыш так что если код снизу не выйдет просто расскоментирух верхний
      if (payload[1]) {
        state.drawData = {
          prizes: payload[1].prizes!.flat().map((el: any) => ({ id: uuidv4(), title: el })),
          header: payload[1].header!,
          description: payload[1].description!,
          editableHeader: payload[1].editableHeader!,
          countries: payload[1].countries!.flat(),
          sponsors: payload[1].sponsors!.flat().map((el: any) => ({ id: uuidv4(), title: el })),
          file: payload[1].fileName,
          photo: payload[1].fileName!,
          endDate: {
            day: timeAdapter(payload[1].end_timestamp!).day,
            month: timeAdapter(payload[1].end_timestamp!).month,
            year: timeAdapter(payload[1].end_timestamp!).year,
          },
          endTime: {
            hours: timeAdapter(payload[1].end_timestamp!).hours,
            minutes: timeAdapter(payload[1].end_timestamp!).minutes,
          },
          startDate: {
            day: timeAdapter(payload[1].begin_timestamp!).day,
            month: timeAdapter(payload[1].begin_timestamp!).month,
            year: timeAdapter(payload[1].begin_timestamp!).year,
          },
          startTime: {
            hours: timeAdapter(payload[1].begin_timestamp!).hours,
            minutes: timeAdapter(payload[1].begin_timestamp!).minutes,
          },
          phone: payload[0].data.usr_phone_number,
          telegram: payload[0].data.usr_tg,
        };
        state.drawId = payload[1].id;
      }
      return state;
    });
  },
});

export const { setDraw, setMessageDraw, setCounties, setEditableHeader, setDrawMode } = slice.actions;

export type DrawData = {
  photo: string;
  startDate: {
    day: number;
    month: number;
    year: number;
  };
  startTime: { hours: number; minutes: number };
  endDate: {
    day: number;
    month: number;
    year: number;
  };
  endTime: { hours: number; minutes: number };
  phone: string;
  telegram: string;
  prizes: ItemsType[];
  sponsors: ItemsType[];
  countries: string[];
  description: string;
  editableHeader: string;
  header: string;
  file: string;
};

export type DrawFormikValuesType = {
  photo: string;
  startDate: DateCreatorType;
  startTime: { hours: number; minutes: number };
  endDate: DateCreatorType;
  endTime: { hours: number; minutes: number };
  phone: string;
  telegram: string;
  prizes: ItemsType[];
  sponsors: ItemsType[];
  countries: string[];
  file: any;
};

export type InitialStateTypeProps = ReturnType<typeof slice.getInitialState>;
