import React, { ChangeEvent, FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useAppDispatch } from '../../../../common/hooks/storeHooks';
import { adminUsersThunks } from '../../adminUsersSlice';

type PropsType = {
  disabled: boolean;
};
export const AdminFindUser: FC<PropsType> = ({ disabled }) => {
  const [searchEmail, setSearchEmail] = useState('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const dispatch = useAppDispatch();

  const debouncedSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value;
    setSearchEmail(email);

    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        dispatch(adminUsersThunks.getUsers({ email }));
        setTimeoutId(undefined);
      }, 1000)
    );
  };

  return (
    <TextField
      fullWidth
      onChange={debouncedSearch}
      value={searchEmail}
      placeholder={'Поиск'}
      size={'small'}
      disabled={disabled}
    />
  );
};
