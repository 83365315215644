import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_TELEGIV_API,
});

export const apiMain = {
  getActiveDrawsFinish() {
    return instance.get<ResponseType>(`raffle/find/raffles`, {
      params: {
        countries: '',
        sortData: 'active',
        pageSize: 50,
      },
    });
  },
  getFinishedDrawsFinish(params: { page: number; pageSize: number; country: string }) {
    return instance.get<ResponseType>(`raffle/find/raffles`, {
      params: {
        countries: params.country || '',
        page: params.page,
        pageSize: params.pageSize,
        sortData: 'end',
      },
    });
  },
  getFinishedDrawsActive() {
    return instance.get<ResponseType>(`raffle/find/raffles`, {
      params: {
        countries: '',
        sortData: 'end',
        pageSize: 50,
      },
    });
  },
  getActiveDrawsActive(params: { page: number; pageSize: number; country: string }) {
    return instance.get<ResponseType>(`raffle/find/raffles`, {
      params: {
        countries: params.country || '',
        page: params.page,
        pageSize: params.pageSize,
        sortData: 'active',
      },
    });
  },
  getActiveRaffleCountry() {
    return instance.get<ItemType[]>('statistics/country-raffles');
  },
};

export type ItemType = {
  fileName: string;
  customer_id: number | null;
  bot_id: number | null;
  id: number | null;
  prizes: string[] | null;
  description: string | null;
  sponsors: string[] | null;
  begin_timestamp: Date | null;
  end_timestamp: Date | null;
  header: string | null;
  countries: string[] | null;
  tg_name: string;
  phone_number: string | null;
  priority: number;
  originalHeader: string | null;
};

export type ResponseType = {
  pagesCount: number;
  page: number;
  pageSize: number;
  totalCount: number;
  items: ItemType[];
};
