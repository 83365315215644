import React, { FC } from 'react';
import { TeamTableLayout } from './TeamTableLayout';
import { TeamTableRow } from './TeamTableRow';
import { TeammateType } from '../../../../API/apiPublicManager';
import { serverDateFormat } from '../../../../common/utils/serverDateFormat';

type PropsType = {
  team: TeammateType[];
};
export const TeamTable: FC<PropsType> = ({ team }) => {
  const teamTableRows = team.map(({ createdAt, id, clientsCount, email, tg_userName, profit }) => {
    const regDate = serverDateFormat(createdAt);
    return (
      <TeamTableRow
        key={id}
        clients={clientsCount}
        email={email}
        telegram={tg_userName}
        income={profit}
        regDate={regDate}
      />
    );
  });

  return <TeamTableLayout>{teamTableRows}</TeamTableLayout>;
};
