import { Icon } from '../../icons/IconWrapper';
import { ICON } from '../../icons/components/Types';
import { ICON_SIZE_TWENTY_FOUR } from '../../../constants-ui-kit';
import st from './IconClose.module.scss';

type SizeIconClose = 'big' | 'small';

type PropsType = {
  sizeIconClose: SizeIconClose;
  handleOnClickIconClose: () => void;
};
export const IconClose = ({ sizeIconClose, handleOnClickIconClose }: PropsType) => {
  return (
    <div
      className={`${st.block} ${sizeIconClose === 'big' ? st.blockBig : st.blockSmall}`}
      onClick={handleOnClickIconClose}
    >
      <Icon
        className={st.icon}
        iconId={ICON.CLOSE2}
        color={'var(--color-background-1)'}
        height={ICON_SIZE_TWENTY_FOUR}
        width={ICON_SIZE_TWENTY_FOUR}
      />
    </div>
  );
};
