import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  adminTransfersApi,
  GetHistoryArgsType,
  GetManagerResponseType,
  GetTransfersArgsType,
  GetTransfersResponseType,
} from '../API/adminTransfersApi';
import { thunkErrorHandler } from '../../../common/utils/thunkErrorHandler';
import { GetHistoryResponseType } from '../../sales/components/salesBalance/salesBalanceApi';

const slice = createSlice({
  name: 'admin/sales/transfers',
  initialState: {
    isTransfersDataFetching: false,
    isSendTransferFetching: false,
    isHistoryFetching: false,
    isManagerDataFetching: false,
    transfersData: {
      totalCount: 0,
      pagesCount: 0,
      page: 0,
      size: 0,
      items: [],
    } as GetTransfersResponseType,
    historyData: {
      items: [],
      page: 0,
      size: 0,
      pagesCount: 0,
      totalCount: 0,
    } as GetHistoryResponseType,
    managerData: {
      manager: {
        id: 0,
        email: '',
        inviterId: 0,
        tgUsername: '',
        balance: 0,
        createdAt: '',
        phoneNumber: '',
      },
      teamMembers: {
        managers: 0,
        teamClients: 0,
      },
      profit: {
        total: 0,
        incomeFromClients: 0,
        incomeFromTeam: 0,
      },
      managerClients: {
        paidClients: 0,
        freeClients: 0,
        totalClients: 0,
      },
    } as GetManagerResponseType,
  },
  reducers: {
    setEmptyHistoryData: state => {
      state.historyData = {
        items: [],
        page: 0,
        size: 0,
        pagesCount: 0,
        totalCount: 0,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTransfers.pending, state => {
        state.isTransfersDataFetching = true;
      })
      .addCase(getTransfers.fulfilled, (state, action) => {
        state.isTransfersDataFetching = false;
        state.transfersData = action.payload.transfersData;
      })
      .addCase(getTransfers.rejected, state => {
        state.isTransfersDataFetching = false;
      })
      .addCase(sendTransfer.pending, state => {
        state.isSendTransferFetching = true;
      })
      .addCase(sendTransfer.fulfilled, state => {
        state.isSendTransferFetching = false;
      })
      .addCase(sendTransfer.rejected, state => {
        state.isSendTransferFetching = false;
      })
      .addCase(getHistory.pending, state => {
        state.isHistoryFetching = true;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.historyData = action.payload.historyData;
        state.isHistoryFetching = false;
      })
      .addCase(getHistory.rejected, state => {
        state.isHistoryFetching = false;
      })
      .addCase(getManagerInfo.pending, state => {
        state.isManagerDataFetching = true;
      })
      .addCase(getManagerInfo.fulfilled, (state, action) => {
        state.isManagerDataFetching = false;
        state.managerData = action.payload.managerData;
      })
      .addCase(getManagerInfo.rejected, state => {
        state.isManagerDataFetching = false;
      });
  },
});

const getTransfers = createAsyncThunk<{ transfersData: GetTransfersResponseType }, GetTransfersArgsType>(
  'admin/sales/transfers/getTransfers',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await adminTransfersApi.getTransfers(arg);
      return { transfersData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const sendTransfer = createAsyncThunk<any, number>(
  'admin/sales/transfers/sendTransfer',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await adminTransfersApi.sendTransfer(arg);
      return res.data;
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const getHistory = createAsyncThunk<{ historyData: GetHistoryResponseType }, GetHistoryArgsType>(
  'admin/sales/managers/getHistory',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await adminTransfersApi.getHistory(arg);
      return { historyData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const getManagerInfo = createAsyncThunk<{ managerData: GetManagerResponseType }, string>(
  'admin/sales/managers/getManagerInfo',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await adminTransfersApi.getManagerInfo(arg);
      return { managerData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

export const adminTransfersReducer = slice.reducer;
export const adminTransfersActions = slice.actions;
export const adminTransfersThunks = { getTransfers, sendTransfer, getHistory, getManagerInfo };
