import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AdminAuthType } from '../../api/apiAdmin';
import { setAppErrorAC, setAppStatusAC, setAppSuccessMessage } from '../../bll/reducers/appReducer';
import { apiCustomer, CustomerMeResponse } from './API/customersAPI';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { removeTokenInfoFromLS, setTokenInfoToLS, TOKENS } from '../sales/common/utils/tokenHelpers';
import { RequestStatuses } from '../../common/enums/requestStatuses';

export const customerRegistration = createAsyncThunk(
  'customerLogin/customerRegistration',
  async (params: AdminAuthType, { dispatch, rejectWithValue }) => {
    dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
    try {
      const res = await apiCustomer.postRegistrationCustomer({
        email_address: params.email_address,
        password: params.password,
        promo: params.promo,
      });
      dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
      return res.data;
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

export const customerLoginization = createAsyncThunk(
  'customerLogin/customerLoginization',
  async (params: AdminAuthType, { dispatch, rejectWithValue }) => {
    dispatch(setAppStatusAC({ status: RequestStatuses.loading }));

    try {
      const res = await apiCustomer.postLoginizationCustomer({
        email_address: params.email_address,
        password: params.password,
      });
      setTokenInfoToLS(res.data.accessToken, TOKENS.CUSTOMER, TOKENS.CUSTOMER_EXP_TIME);
      dispatch(getMeCustomer());
      dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);
export const setCustomerInfo = createAsyncThunk(
  'customerLogin/setCustomerInfo',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
      const info = await apiCustomer.customerInfo();
      return info.data;
    } catch (e) {
      return rejectWithValue(null);
    } finally {
      dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    }
  }
);
export const getMeCustomer = createAsyncThunk<{ customerInfo: CustomerMeResponse }>(
  'customer/me',
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiCustomer.getMeCustomer();
      return { customerInfo: res.data };
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);
export const confirmEmailResending = createAsyncThunk<void, { email: string }>(
  'customer/confirmEmailResending',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
      const email = { email: arg.email };
      await apiCustomer.resendEmailConfirmationMessage(email);
      dispatch(setAppSuccessMessage({ successMessage: `Письмо отправлено на ваш email: ${arg.email}` }));
    } catch (e) {
      dispatch(setAppErrorAC({ error: 'Что то пошло не так. Попробуйте ещё раз.' }));
      return rejectWithValue(null);
    } finally {
      dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    }
  }
);
export const registrationConfirmation = createAsyncThunk<void, { confirmationCode: string }>(
  'customer/registrationConfirmation',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const confirmationCode = { confirmationCode: arg.confirmationCode };
      await apiCustomer.registrationConfirmation(confirmationCode);
    } catch (e) {
      dispatch(setAppErrorAC({ error: 'Что то пошло не так. Попробуйте ещё раз.' }));
      return rejectWithValue(null);
    }
  }
);
export const customerLogOut = createAsyncThunk('customerLogin/customerLogOut', async (_, { dispatch }) => {
  try {
    dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
    removeTokenInfoFromLS(TOKENS.CUSTOMER, TOKENS.CUSTOMER_EXP_TIME);
    await apiCustomer.customerLogOut();
  } catch (err) {
    thunkErrorHandler(err, dispatch);
  } finally {
    dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
  }
});
export const passwordRecovery = createAsyncThunk<void, { email: string }>(
  'customer/passwordRecovery',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
      const email = { email: arg.email };
      await apiCustomer.passwordRecovery(email);
      dispatch(setAppSuccessMessage({ successMessage: `Письмо отправлено на ваш email: ${arg.email}` }));
    } catch (e) {
      dispatch(setAppErrorAC({ error: 'Что то пошло не так. Попробуйте ещё раз.' }));
      return rejectWithValue(null);
    } finally {
      dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    }
  }
);
export const checkRecoveryCode = createAsyncThunk<void, { recoveryCode: string }>(
  'customer/passwordRecovery',
  async (arg, { rejectWithValue }) => {
    try {
      const recoveryCode = { recoveryCode: arg.recoveryCode };
      await apiCustomer.checkRecoveryCode(recoveryCode);
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);
export const setNewPassword = createAsyncThunk<void, { newPassword: string; recoveryCode: string }>(
  'customer/setNewPassword',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const params = { newPassword: arg.newPassword, recoveryCode: arg.recoveryCode };
      await apiCustomer.setNewPassword(params);
      dispatch(setAppSuccessMessage({ successMessage: `Пароль успешно изменен!` }));
    } catch (e) {
      dispatch(setAppErrorAC({ error: 'Что то пошло не так. Попробуйте ещё раз.' }));
      return rejectWithValue(null);
    }
  }
);

const initialState = {
  customerInfo: {
    id: 0,
    email_address: '',
    usr_tg: '',
    usr_phone_number: '',
    email_confirmation: false,
    rate_plan_id: 0,
    verified: false,
    creation_timestamp: '',
    draws_count: 0,
  },
  isCustomerLoggedIn: false,
  isInitialized: false,
  botExist: false,
};

export const slice = createSlice({
  name: 'customerLogin',
  initialState: initialState,
  reducers: {
    setReinitialized(state) {
      state.isInitialized = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMeCustomer.fulfilled, (state, action) => {
        state.customerInfo = action.payload.customerInfo;
        state.isCustomerLoggedIn = true;
        state.isInitialized = true;
      })
      .addCase(setCustomerInfo.fulfilled, (state, action) => {
        state.botExist = action.payload.botExist;
      })
      .addCase(customerLogOut.fulfilled, () => {
        return initialState;
      })
      .addCase(getMeCustomer.rejected, state => {
        state.isInitialized = true;
      });
  },
});

export const customerAuthActions = slice.actions;
