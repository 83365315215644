import React from 'react';
import s from './AdminManagerStatsBlock.module.scss';
import { AdminManagerClientsCount } from './AdminManagerClientsCount';
import { AdminManagerIncome } from './AdminManagerIncome';
import { AdminManagerTeamCount } from './AdminManagerTeamCount';
export const AdminManagerStatsBlock = () => {
  return (
    <div className={s.memberStatsWrapper}>
      <AdminManagerClientsCount />
      <AdminManagerIncome />
      <AdminManagerTeamCount />
    </div>
  );
};
