import { instance } from '../../API/instance';
import { MailingRaffleDataType, MailingRaffleReturnType } from '../types';

const mailingEndpoints = {
  raffleTest: '/mailing-raffle/test',
  mailingRaffle: '/mailing-raffle',
};

export const mailingRaffleTest = async (data: FormData) => {
  return instance.post<MailingRaffleReturnType>(mailingEndpoints.raffleTest, data);
};

export const mailingRaffle = async (data: MailingRaffleDataType) => {
  return instance.post<MailingRaffleReturnType>(mailingEndpoints.mailingRaffle, data);
};
