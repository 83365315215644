import React, { ChangeEvent, useState } from 'react';
import s from '../CreateDraw.module.css';
import { TitleDraw } from './TitleDraw';

const DEFAULT_SELECTION_FLAG = 'all';
const DEFAULT_SELECTION_VALUE = ['all'];

type ValueType = string | Array<string>;

type SetFieldValue = {
  (field: string, value: ValueType): void;
};

type MultiSelectionPropsModel = {
  selectionValue: ValueType;
  setFieldValue: SetFieldValue;
  fieldName: string;
  selectionArr: string[];
  title: string;
  allSelectionText?: string;
  titleTooltipInfo?: string;
};

export function Selection({
  selectionValue,
  setFieldValue,
  fieldName,
  selectionArr,
  title,
  allSelectionText,
  titleTooltipInfo,
}: MultiSelectionPropsModel) {
  const [value, setValue] = useState<ValueType>(selectionValue);

  const isArraySelectionType = (selection: string, value: Array<string>) => {
    if (selection === DEFAULT_SELECTION_FLAG) {
      // при выборе 'все' нам нужно убрать из массива уже имеющиеся items
      setValue(DEFAULT_SELECTION_VALUE);
      setFieldValue(fieldName, DEFAULT_SELECTION_VALUE);
    } else {
      if (value.includes(selection)) {
        // если в массиве уже есть, то удаляем
        const filteredValue = value.filter(item => item !== selection);
        setValue(filteredValue);
        setFieldValue(fieldName, filteredValue);
        return;
      }

      if (value.includes(DEFAULT_SELECTION_FLAG)) {
        // учитывая, что при мультивыборе либо 'all', либо n-е количество items
        // если в value есть 'all' - меняем на впервый выбранный item
        setValue([selection]);
        setFieldValue(fieldName, [selection]);
        return;
      }

      if (value.length === selectionArr.length - 1) {
        // когда в массиве все items, мы меняем на 'all'
        setValue(DEFAULT_SELECTION_VALUE);
        setFieldValue(fieldName, DEFAULT_SELECTION_VALUE);
        return;
      }
      setValue([...value, selection]); // добавляем страну в массив
      setFieldValue(fieldName, [...value, selection]);
    }
  };
  const changeCheckboxes = (event: ChangeEvent<HTMLInputElement>) => {
    const selection = event.target.dataset.value;
    if (!selection) return;
    if (Array.isArray(value)) {
      isArraySelectionType(selection, value);
    } else {
      setValue(selection);
      setFieldValue(fieldName, selection);
    }
  };

  //стили для кнопки 'все'
  const stylesBtn = value.includes(DEFAULT_SELECTION_FLAG)
    ? `${s.btnCountrySelected} ${s.btnCountry} ${s.btnAllCountries}`
    : `${s.btnCountry} ${s.btnAllCountries}`;

  //возвращает стили для кнопок (страны)
  const selectItem = (item: string) => {
    return value.includes(item) ? `${s.btnCountrySelected} ${s.btnCountry}` : s.btnCountry;
  };

  return (
    <div className={s.container}>
      <div className={s.countrySelectionContainer}>
        <div className={s.titleCountrySelectionBlock}>
          <TitleDraw title={title} titleText={titleTooltipInfo} />
        </div>

        <div className={s.countriesBlock}>
          {allSelectionText && (
            <div className={stylesBtn}>
              <input
                data-value={DEFAULT_SELECTION_FLAG}
                id={fieldName}
                onChange={changeCheckboxes}
                type='checkbox'
                checked={value.includes(DEFAULT_SELECTION_FLAG)}
              />
              <label htmlFor={fieldName}>{allSelectionText}</label>
            </div>
          )}
          {selectionArr.map((selection, index) => (
            <div key={`${selection}_${index}`} className={selectItem(selection)}>
              <input
                data-value={selection}
                type='checkbox'
                id={selection}
                onChange={changeCheckboxes}
                checked={value.includes(selection)}
              />
              <label htmlFor={selection}>{selection}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
