import { setTokenInfoToLS, TOKENS } from '../common/utils/tokenHelpers';
import { instance } from './instance';

export const salesAuthApi = {
  login(params: SalesLoginParams) {
    return instance.post<{ accessToken: string }>('auth/login', params);
  },
  registration(params: SalesRegistrationParams) {
    return instance.post('auth/signUp', params);
  },
  logout() {
    return instance.post('auth/logout');
  },
  resetPassword(params: { email: string }) {
    return instance.post('auth/password/reset', params);
  },
  changePassword(params: ChangePasswordParams) {
    return instance.post('auth/password/change', params);
  },
  salesRefreshToken: async () => {
    const res = await instance.post<{ accessToken: string }>('auth/refresh-token');
    setTokenInfoToLS(res.data.accessToken, TOKENS.SALES, TOKENS.SALES_EXP_TIME);
  },
  getMe() {
    return instance.get<ManagerInfoType>('me');
  },
};

export type SalesLoginParams = {
  email: string;
  password: string;
};
export type SalesRegistrationParams = {
  email: string;
  password: string;
  tg_username: string;
};
export type ChangePasswordParams = {
  code: string;
  password: string;
  password_confirm: string;
};

export type ManagerInfoType = {
  balance: number;
  createdAt: string;
  email: string;
  id: number;
  inviterId: number;
  isActivated: boolean;
  phoneNumber: string;
  promo: string;
  tgUsername: string;
};
