import React from 'react';
import { Section } from '../Section';
import { Green, Typography } from '../../../../../../components/typography';
import s from './Start.module.scss';
import { Button } from '../../../../../../components/button';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../../../common/path/puth';
export const Start = () => {
  return (
    <Section dark className={s.section}>
      <div className={clsx('container', s.container)}>
        <Typography variant={'title_block'} align={'end'}>
          Ну что? <Green>начнем?</Green>
        </Typography>
        <Typography variant={'title_primary_2'} align={'end'} mb={40}>
          обещаю, тебе у нас понравится!
        </Typography>
        <Button variant={'primary'} as={NavLink} to={`/sales/${PATH.registration}`}>
          стать частью команды
        </Button>
      </div>
    </Section>
  );
};
