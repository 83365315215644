import React from 'react';
import { AdminManagerBalance } from './adminManagerBalance/AdminManagerBalance';
import s from './AdminManagerInfoBlock.module.scss';
import { AdminManagerInfo } from './adminManagerInfo/AdminManagerInfo';

export const AdminManagerInfoBlock = () => {
  return (
    <div className={s.managerInfoWrapper}>
      <AdminManagerBalance />
      <AdminManagerInfo />
    </div>
  );
};
