import { PATH } from '../../../../common/path/puth';

export const adminHeaderMenuItems = [
  { buttonTitle: 'Рассылка', buttonLink: PATH.adminMailing },
  { buttonTitle: 'Переводы', buttonLink: PATH.transfers },
  { buttonTitle: 'Команда', buttonLink: PATH.adminTeam },
  { buttonTitle: 'Промокоды', buttonLink: PATH.promoCode },
  { buttonTitle: 'Пользователи', buttonLink: PATH.users },
  { buttonTitle: 'Розыгрыши', buttonLink: PATH.adminDraws },
];
