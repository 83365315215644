import React, { FC } from 'react';
import s from './ManagersTable.module.scss';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../common/path/puth';
import { CustomButton } from '../../../../common/components/customButton/CustomButton';
import { ManagersTableCell } from './ManagersTableCell';
import { serverDateFormat } from '../../../sales/common/utils/serverDateFormat';

type PropsType = {
  id: number;
  email: string;
  balance: number;
  createdAt: string;
  team: string;
  paidClients: string;
  unpaidClients: string;
};
export const ManagersTableRow: FC<PropsType> = ({
  id,
  email,
  balance,
  createdAt,
  team,
  unpaidClients,
  paidClients,
}) => {
  const navigate = useNavigate();
  const date = serverDateFormat(createdAt.toString());

  const editUserHandler = () => {
    navigate(`${PATH.adminTeam}/${id}`);
  };

  return (
    <tr onClick={editUserHandler}>
      <td className={s.date}>
        <ManagersTableCell text={date} />
      </td>
      <td className={s.id}>
        <ManagersTableCell text={id} />
      </td>
      <td className={s.email}>
        <ManagersTableCell text={email} />
      </td>
      <td className={s.team}>
        <ManagersTableCell text={team} />
      </td>
      <td className={s.free}>
        <ManagersTableCell text={unpaidClients} />
      </td>
      <td className={s.paid}>
        <ManagersTableCell text={paidClients} />
      </td>
      <td className={s.sum}>
        <ManagersTableCell text={balance} />
      </td>
      <td className={s.more}>
        <div>
          <CustomButton
            title={'Подробнее'}
            width={'150'}
            textColor={'white'}
            color={'secondary'}
            callback={editUserHandler}
          />
        </div>
      </td>
    </tr>
  );
};
