import React from 'react';
import { ManagerInfo } from '../../../common/components/managerInfo/ManagerInfo';
import s from './ManagerInfoBlock.module.scss';
import { useWindowDimensions } from '../../../../../common/utils/useWindowDimensions';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { isProfitActivatedSelector, managerIdSelector, promoSelector } from '../../../managerInfoSelectors';
import { tooltipsText } from '../../../constants/tooltipsText';
import { INVITATION_TO_TEAM_LINK } from '../../../common/constants';

export const ManagerInfoBlock = () => {
  const { width } = useWindowDimensions();
  const CHANGE_TITLE_WIDTH = 690;
  const isTitleChange = width <= CHANGE_TITLE_WIDTH;

  const promoCode = useAppSelector(promoSelector);
  const managerId = useAppSelector(managerIdSelector);
  const isProfitActivated = useAppSelector(isProfitActivatedSelector);
  const upperCasePromoCode = promoCode ? promoCode.toUpperCase() : '';

  return (
    <div className={s.managerInfoBlock}>
      <div className={s.managerInfoWrapper}>
        <ManagerInfo
          copyTextAllow={true}
          tooltipText={tooltipsText.promoCode}
          copiedText={'Промокод скопирован'}
          title={'Личный промокод'}
          cellInfo={upperCasePromoCode}
        />
        <ManagerInfo
          tooltipText={tooltipsText.inviteToTeam}
          copiedText={'Ссылка скопирована'}
          copyTextAllow={isProfitActivated}
          title={'Приглашение в свою команду'}
          cellInfo={isProfitActivated ? `${INVITATION_TO_TEAM_LINK}/${managerId}` : 'Пока что не активно'}
        />
        <ManagerInfo
          copyTextAllow={false}
          tooltipText={tooltipsText.teamIncome}
          title={isTitleChange ? 'Заработок' : 'Заработок с команды'}
          cellInfo={isProfitActivated ? 'Активирован' : 'На паузе'}
        />
      </div>
    </div>
  );
};
