import * as A from '@radix-ui/react-accordion';
import React, { FC, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import s from './Accordion.module.scss';
import { Add } from '../../ui-kit/components/icons/components/Add';
import { Typography } from '../typography';
import { Button } from '../button';
import { IsMediumSizeScreen } from '../../common/utils/isMediumSizeScreen';
import { IsSmallSizeScreen } from '../../common/utils/isSmallSizeScreen';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../common/path/puth';

export const Accordion: FC<Props> = ({ children }) => {
  return (
    <A.Root className={s.root} type='single' collapsible>
      {children}
    </A.Root>
  );
};
type Props = {
  children: ReactNode;
  className?: string;
};
type PropsTrigger = Props & { number?: number };
type PropsContent = Props & { button?: boolean };
type ItemProps = {
  children: ReactNode;
  value: string;
};

export const AccordionItem: FC<ItemProps> = ({ children, value }) => {
  return (
    <A.Item className={s.item} value={value}>
      {children}
    </A.Item>
  );
};

export const AccordionTrigger: FC<PropsTrigger> = forwardRef<HTMLButtonElement, PropsTrigger>(
  ({ children, className, number, ...props }, ref) => {
    let iconSize = 24;
    if (IsMediumSizeScreen()) iconSize = 20;
    if (IsSmallSizeScreen()) iconSize = 16;
    return (
      <A.Header className={s.header}>
        <Typography variant={'header_secondary'}>{number}</Typography>
        <Typography variant={'header_secondary'} className={s.title}>
          {children}
        </Typography>
        <Add color={'var(--color-text-white)'} className={s.icon} size={iconSize} />
        <A.Trigger className={clsx(s.trigger, className)} {...props} ref={ref}></A.Trigger>
      </A.Header>
    );
  }
);

export const AccordionContent: FC<PropsContent> = forwardRef<HTMLDivElement, PropsContent>(
  ({ children, className, button = false, ...props }, ref) => (
    <A.Content className={clsx(s.content, className)} {...props} ref={ref}>
      <div className={s.contentContainer}>
        <div className={s.contentText}>
          <Typography variant={'typeface_main'}>{children}</Typography>
        </div>
        {button && (
          <div className={s.contentBtn}>
            <AddBtn />
          </div>
        )}
      </div>
    </A.Content>
  )
);

const AddBtn = () => {
  return (
    <Button variant={'primary'} size={'medium'} as={NavLink} to={`/sales/${PATH.registration}`}>
      {IsSmallSizeScreen() ? 'выбрать тариф' : 'стать частью команды'}
    </Button>
  );
};
