import { ItemsType } from '../../features/customer/components/createDraw/components/AddItems';
import dayjs from 'dayjs';
import { RootState } from '../../bll/store/store';

export const raffleCreator = (data: RootState) => {
  const transformationInArrayOfStrings = (data: ItemsType[]): string[] => {
    return data.reduce((acc, items) => {
      acc.push(items.title);
      return acc;
    }, [] as string[]);
  };

  const transformInCorrectDate = (data: DateType, time: TimeType) => {
    return dayjs()
      .set('date', data.day)
      .set('month', data.month)
      .set('year', data.year)
      .set('hours', time.hours)
      .set('minutes', time.minutes)
      .toDate();
  };

  const begin_timestamp = transformInCorrectDate(data.draw.drawData.startDate, data.draw.drawData.startTime);
  const end_timestamp = transformInCorrectDate(data.draw.drawData.endDate, data.draw.drawData.endTime);
  const MINUTES = 15;
  const dateRightNow = new Date();
  const timeRightNow = dateRightNow.getTime();
  if (begin_timestamp.getTime() < timeRightNow) {
    begin_timestamp.setTime(timeRightNow);
    const minutesRightNow = dateRightNow.getMinutes();
    begin_timestamp.setMinutes(minutesRightNow + MINUTES);
  }

  return {
    sponsors: transformationInArrayOfStrings(data.draw.drawData.sponsors),
    prizes: transformationInArrayOfStrings(data.draw.drawData.prizes),
    bot_id: 1,
    countries: data.draw.drawData.countries,
    begin_timestamp,
    end_timestamp,
    header: data.draw.drawData.header,
    user_phoneNumber: data.draw.drawData.phone,
    tg_name: data.draw.drawData.telegram,
    description: data.draw.drawData.description,
    editableHeader: data.draw.drawData.editableHeader,
    file: data.draw.drawData.file,
  };
};

type DateType = {
  day: number;
  month: number;
  year: number;
};
type TimeType = {
  hours: number;
  minutes: number;
};
