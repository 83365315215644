import React, { FC } from 'react';
import s from '../../styles/CommonStyles.module.scss';

type PropsType = {
  title: string;
  value: number | string;
  headingTitle?: true;
  cashValue?: true;
};
export const StatsItem: FC<PropsType> = ({ title, value, headingTitle, cashValue }) => {
  const itemStyle = headingTitle ? s.statsHeader : s.statsInfo;
  const cashValueStyle = cashValue ? s.cashItem : '';

  return (
    <div className={itemStyle}>
      <span>{title}</span>
      <span className={cashValueStyle}>{value}</span>
    </div>
  );
};
