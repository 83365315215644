// generic function
// export const handleServerAppError = <T>(mockData: ResponseType<T>, dispatch: ErrorUtilsDispatchType) => {
//   if (mockData.messages.length) {
//     dispatch(setAppErrorAC({error: mockData.messages[0]}))
//   }
//   else {
//     dispatch(setAppErrorAC({error: 'Some error occurred'}))
//   }
//   dispatch(setAppStatusAC({status: RequestStatuses.failed}))
// }

import { setAppErrorAC, SetAppErrorACType, setAppStatusAC, SetAppStatusACType } from '../../bll/reducers/appReducer';
import { Dispatch } from 'redux';
import { format } from 'date-fns';
import { RequestStatuses } from '../enums/requestStatuses';

export const handleServerNetworkError = (message: string, dispatch: ErrorUtilsDispatchType) => {
  dispatch(setAppStatusAC({ status: RequestStatuses.failed }));
  dispatch(setAppErrorAC({ error: message }));
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, 'HH:mm dd.MM.yy');
};

export type ErrorUtilsDispatchType = Dispatch<SetAppErrorACType | SetAppStatusACType>;
