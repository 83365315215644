import React, { useEffect, useState } from 'react';
import s from './AdminTransfers.module.scss';
import { useSearchParams } from 'react-router-dom';
import { CompletedTransfersTable } from './CompletedTransfers/completedTransfersTable/CompletedTransfersTable';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { transfersDataSelector } from './adminTransfersSelectors';
import { adminTransfersThunks } from './adminTransfersSlice';
import { GetTransfersStatusType } from '../API/adminTransfersApi';
import { PendingTransfersTable } from './PendingTransfers/pendingTransfersTable/PendingTransfersTable';

type WindowType = 'pending' | 'finished';

export const AdminTransfers = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const [window, setWindow] = useState<WindowType>('pending');
  const [transfersType, setTransfersType] = useState<GetTransfersStatusType>('pending');
  const transfersData = useAppSelector(transfersDataSelector);
  const currentPage = searchParams.get('page') || '1';
  const pageSize = searchParams.get('size') || '10';

  useEffect(() => {
    dispatch(adminTransfersThunks.getTransfers({ status: transfersType, page: currentPage, size: pageSize }));
  }, [transfersType, currentPage]);

  const onChangePage = (page: string) => {
    if (page === '1') {
      delete params.page;
      setSearchParams({ ...params });
    } else {
      setSearchParams({ page: page });
    }
  };
  const changeStatusHandler = (status: WindowType) => {
    delete params.page;
    setSearchParams({ ...params });
    setWindow(status);
    setTransfersType(status);
  };

  return (
    <div>
      <div className={s.adminTransfersBlock}>
        <h3>Переводы</h3>
        <div className={s.headerButtonsWrapper}>
          <div className={s.headerButtons}>
            <button
              className={window === 'pending' ? s.headerButtonsActive : ''}
              onClick={() => changeStatusHandler('pending')}
            >
              В ожидании
            </button>
            <button
              className={window === 'finished' ? s.headerButtonsActive : ''}
              onClick={() => changeStatusHandler('finished')}
            >
              Выполненные
            </button>
          </div>
        </div>
        {window === 'pending' && <PendingTransfersTable transfersData={transfersData} onChangePage={onChangePage} />}
        {window === 'finished' && <CompletedTransfersTable transfersData={transfersData} onChangePage={onChangePage} />}
      </div>
    </div>
  );
};
