import React, { useState, useEffect, FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '../../../../../../../components/typography';
import './TextSlider.scss';

type Props = {
  texts: string[];
  interval: number;
};

export const TextSlider: FC<Props> = ({ texts, interval }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsVisible(false); // Скрываем текущий текст
      setTimeout(() => {
        setCurrentTextIndex(prevIndex => (prevIndex === texts.length - 1 ? 0 : prevIndex + 1));
        setIsVisible(true); // Показываем новый текст
      }, 1000); // Задержка перед показом нового текста
    }, interval);

    return () => clearInterval(timer);
  }, [texts, interval]);

  return (
    <div className={'text-slider'}>
      <CSSTransition in={isVisible} timeout={1000} classNames='slide' unmountOnExit>
        <div className={'text'}>
          <Typography nonAdapt variant='btn_l'>
            {texts[currentTextIndex]}
          </Typography>
        </div>
      </CSSTransition>
    </div>
  );
};
