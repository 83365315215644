import { RootState } from '../../bll/store/store';

export const formDateCreator = async (result: any, State: RootState, botId: number) => {
  const formData = new FormData();
  formData.set('description', result.description);
  formData.set('begin_timestamp', result.begin_timestamp.toString());
  formData.set('end_timestamp', result.end_timestamp.toString());
  formData.set('header', result.header);
  formData.set('editableHeader', result.editableHeader);
  if (result.sponsors.length > 1) {
    result.sponsors.forEach((el: string) => {
      formData.append('sponsors', el);
    });
  } else {
    formData.append('sponsors', result.sponsors[0]);
  }
  if (result.prizes.length > 1) {
    result.prizes.forEach((el: string) => {
      formData.append('prizes', el);
    });
  } else {
    formData.set('prizes', result.prizes.toString());
  }
  if (result.countries.length > 1) {
    result.countries.forEach((el: string) => {
      formData.append('countries', el);
    });
  } else if (result.countries[0] === 'all') {
    formData.append('countries', 'Беларусь');
    formData.append('countries', 'Украина');
    formData.append('countries', 'Россия');
    formData.append('countries', 'Казахстан');
    formData.append('countries', 'Узбекистан');
  } else {
    formData.set('countries', result.countries.toString());
  }

  let blob;
  let asyncFetch: Function;
  if (typeof State.draw.drawData.file === 'string') {
    // blob = new File([State.draw.file], State.draw.file.slice(69 + 1, State.draw.file.length), { type: `image/${State.draw.file.slice(69 + 1, State.draw.file.length)}` });
    // formData.append('file', blob);

    //test
    asyncFetch = async () => {
      await fetch(`${State.draw.drawData.file}`, { cache: 'no-cache' })
        .then(res => res.blob())
        .then(res => {
          blob = new File([res], State.draw.drawData.file.slice(69 + 1, State.draw.drawData.file.length), {
            type: `image/${State.draw.drawData.file.slice(69 + 1, State.draw.drawData.file.length)}`,
          });
          formData.append('file', blob);
        });
    };
  } else {
    formData.append('file', State.draw.drawData.file);
  }

  const formDataAsync = async () => {
    await asyncFetch();
    formData.set('bot_id', botId.toString());
    formData.set('user_phoneNumber', result.user_phoneNumber);
    formData.set('tg_name', result.tg_name);
    return formData;
  };
  // @ts-ignore
  if (asyncFetch) {
    return await formDataAsync();
  } else {
    formData.set('bot_id', botId.toString());
    formData.set('user_phoneNumber', result.user_phoneNumber);
    formData.set('tg_name', result.tg_name);
    return formData;
  }
};
