import React, { ChangeEvent, useRef, useState } from 'react';
import { CardContent } from '@mui/material';
import * as Sentry from '@sentry/browser';
import style from '../customer/components/createDraw/CreateDraw.module.css';
import s from './feedback.module.scss';
import { Typography } from '../../components/typography';
import { Button, ButtonVariant } from './components/Button';
import { TextField } from './components/TextField';
import { TextArea } from './components/TextArea';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

export const ErrorFeedbackForm = () => {
  const [step, setStep] = useState(0);
  const [telegramHandle, setTelegramHandle] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [imagePreview, setImagePreview] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [photoError, setPhotoError] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const eventId = Sentry.captureMessage('User Feedback');

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setPhotoError('');

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setPhotoError('Фото должно быть не больше 5MB');
        setSelectedFile(null);
        setImagePreview('');
        return;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert file to base64'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  function base64ToUint8Array(base64: any) {
    const cleanedBase64 = base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
    const binaryString = window.atob(cleanedBase64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  const handleSubmit = async () => {
    let fileBase64 = null;
    if (selectedFile) {
      fileBase64 = await fileToBase64(selectedFile);
    }

    const userFeedback = {
      name: telegramHandle,
      message: errorDescription,
      associatedEventId: eventId,
    };

    Sentry.getCurrentScope().addAttachment({
      filename: selectedFile?.name!,
      data: base64ToUint8Array(fileBase64),
      contentType: 'application/png',
    });
    Sentry.captureFeedback(userFeedback);
    setStep(2);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div className={s.wrapper}>
            <div className={s.content}>
              <Typography variant={'title_secondary'}>Форма для ошибок</Typography>
              <div className={s.content_text}>
                <Typography variant={'header_secondary'} color='text.secondary'>
                  Наше приложение находится в Beta-тесте. Сейчас мы ищем ошибки внутри приложения и быстро их правим.
                  Если ты нашел ошибку, то опиши ее пожалуйста.
                </Typography>
                <Typography variant={'header_secondary'} color='text.secondary'>
                  Пользователям, которые нашли больше всего багов мы отправим по 500 монет
                </Typography>
              </div>
            </div>
            <Button onClick={() => setStep(1)}>Отправить описание ошибки</Button>
          </div>
        );
      case 1:
        return (
          <div className={s.wrapper}>
            <div className={s.content}>
              <TextField
                label='Твой ник в Telegram'
                value={telegramHandle}
                onChange={e => setTelegramHandle(e.target.value)}
              />
              <TextArea
                label='Описание ошибки'
                value={errorDescription}
                onChange={e => setErrorDescription(e.target.value)}
              />
              {imagePreview ? (
                <img src={imagePreview} className={style.photoUpload} alt='Preview' />
              ) : (
                <div className={style.emptyAvatar} />
              )}
              <input
                type='file'
                accept='image/jpeg,image/png,image/bmp,image/webp'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <Typography color='error' variant={'btn_s'}>
                {selectedFile && `Выбран: ${selectedFile.name}`}
              </Typography>

              <Button
                className={s.upload_btn}
                variant={ButtonVariant.TRANSPARENT}
                width={'300px'}
                onClick={() => fileInputRef.current?.click()}
              >
                {'Загрузить файл (Max 5MB)'}
              </Button>
              {photoError && (
                <Typography color='error' variant={'btn_s'}>
                  {photoError}
                </Typography>
              )}
            </div>
            <Button onClick={handleSubmit} disabled={!telegramHandle || !errorDescription}>
              Отправить
            </Button>
          </div>
        );
      case 2:
        return (
          <div className={s.wrapper}>
            <div className={s.content}>
              <Typography variant={'btn_l'} align='center'>
                Большое спасибо!
              </Typography>
              <Typography variant={'btn_l'} color='text.secondary' align='center'>
                Мы приняли твое сообщение и постараемся максимально оперативно решить данную проблему.
              </Typography>
            </div>
            <Button onClick={() => setStep(0)}>Закрыть</Button>
          </div>
        );
    }
  };

  return (
    <div className={s.root}>
      <CardContent>{renderStep()}</CardContent>
    </div>
  );
};
