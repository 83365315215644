import React from 'react';
import s from './Winner.module.css';

type WinnerProps = {
  winner: string | undefined;
  prize: string | undefined;
  place: number | undefined;
};

export const Winner = ({ winner, prize, place }: WinnerProps) => {
  return (
    <li key={place} className={s.winnerContainer}>
      <span className={s.place}>{place} место</span> -{' '}
      <a href={`https://t.me/${winner}`} target='_blank' rel='noreferrer' className={s.tgName}>
        @{winner}
      </a>
      <span>забирает {prize}</span>
    </li>
  );
};
