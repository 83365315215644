import * as React from 'react';

export type IconProps = {
  color?: string;
  size?: number;
  autoSize?: boolean;
  svgProps?: React.SVGProps<SVGSVGElement>;
} & Omit<React.HTMLProps<HTMLSpanElement>, 'color' | 'size'>;

import SpriteIcons from '../../../assets/icons/svgs/sprite-icons.svg';

export type _IconProps = {
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  iconId?: string;
  className?: string;
  onClick?: () => void;
};

export const Icon: React.FC<_IconProps> = ({
  color: colorProp,
  width,
  height,
  viewBox,
  iconId,
  className,
  onClick,
}) => {
  const color = colorProp ? colorProp : 'var(--color-background-1)';

  return (
    <svg
      role='icon'
      width={width || '24px'}
      height={height || '24px'}
      viewBox={viewBox || '0 0 24 24'}
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <use xlinkHref={`${SpriteIcons}#${iconId}`} />
    </svg>
  );
};
