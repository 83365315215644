import React from 'react';
import { Green, Typography } from '../../../../../../components/typography';
import { Section } from '../Section';
import s from './Pluses.module.scss';
import clsx from 'clsx';
import { GetCardDarkStatus } from '../../../../../../common/utils/getCardDarkStatus';
import { plusesCards } from './plusesCards';
import { screenSizes } from '../../../../../../common/constants/screenSizes';
import { Card } from '../../../../../../components/Card/Card';

export const Pluses = () => {
  return (
    <Section className={s.section}>
      <div className={clsx('container', s.container)}>
        <div>
          <Typography variant={'title_block'} align={'start'} mb={4}>
            <Green>больше плюсов!</Green>
          </Typography>
          <Typography variant={'title_primary_2'} dark align={'start'} className={s.desc}>
            ты сам выбираешь клиента <br /> и решаешь сколько зарабатывать
          </Typography>
        </div>
        <div className={s.cardsContainer}>
          {plusesCards.map((card, index) => {
            return (
              <Card
                cardName={'pluses'}
                key={index}
                title={card.title}
                desc={card.desc}
                dark={GetCardDarkStatus([0], [1], screenSizes.small, index)}
                img={card.img}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
};
