import React from 'react';
import style from './ResultInfo.module.css';

export const ResultInfo = () => {
  return (
    <div>
      <div className={style.infoContainer}>
        <h4 className={style.title}>Для участников розыгрыша. Важно!</h4>
        <p className={style.text}>
          Все расходы за доставку приза оплачивает организатор розыгрыша.
          <br />
          Если с вами кто-то связался и требует какие-либо деньги - ничего не платите.
          <br />
          Победитель оплачивать ничего не должен.
        </p>
      </div>
    </div>
  );
};
