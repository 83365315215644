import { FC, memo, useEffect } from 'react';
import { useValidImage } from '../hooks/checkImage';

export type PictureProps = {
  src: string;
  alt?: string;
  className?: string;
  [key: string]: any;
};
export const PictureRaffle: FC<PictureProps> = memo(({ src, className, alt = 'Raffle', ...rest }) => {
  const srcSmall = `https://telegiv.s3.eu-central-1.amazonaws.com/raffles/avatar/260X260_${src}`;
  const srcAverage = `https://telegiv.s3.eu-central-1.amazonaws.com/raffles/avatar/460X460_${src}`;
  const srcBackUp = `https://telegiv.s3.eu-central-1.amazonaws.com/raffles/avatar/${src}`;

  const { isImage, checkImage } = useValidImage();
  useEffect(() => {
    const validateAndSetImage = async () => {
      await checkImage(srcBackUp);
    };
    validateAndSetImage();
  }, [src]);

  return (
    <picture className={className} {...rest}>
      <source srcSet={isImage ? srcBackUp : srcSmall} media='(min-width: 328px)' />
      <source srcSet={isImage ? srcBackUp : srcAverage} media='(min-width: 416px)' />
      <img className={className} src={isImage ? srcBackUp : srcAverage} alt={alt} />
    </picture>
  );
});
