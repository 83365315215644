import React, { useEffect, useState } from 'react';
import s from './AdminDraws.module.scss';
import { AdminDrawsCategoryButtons } from './adminDrawsHeader/drawsCategoryButtons/AdminDrawsCategoryButtons';
import { AdminDrawsSearch } from './adminDrawsHeader/adminDrawsSearch/AdminDrawsSearch';
import { AdminDrawCard } from './adminDrawCard/AdminDrawCard';
import { SalesPagination } from '../../sales/common/components/pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { useCustomSearchParams } from '../../../common/hooks/useCustomSearchParams';
import {
  adminDrawsItemsSelector,
  adminDrawsPagesCountSelector,
  isAdminDrawsFetchingSelector,
} from '../adminDrawsSelectors';
import { adminRafflesThunks } from '../adminDrawsSlice';
import { PreLoader } from '../../sales/common/components/preLoader/PreLoader';
import { SORT_DIRECTION } from '../../../common/constants/enumConstants';

export enum RAFFLES_STATUSES {
  Active = 'active',
  End = 'end',
  HiddenActive = 'hiddenActive',
  HiddenEnd = 'hiddenEnd',
}

export type DrawsCategoryType =
  | RAFFLES_STATUSES.Active
  | RAFFLES_STATUSES.End
  | RAFFLES_STATUSES.HiddenActive
  | RAFFLES_STATUSES.HiddenEnd;

export type AdminRafflesQueryType = 'page' | 'pageSize' | 'countries' | 'title' | 'sortData';

type SortDataStatuses = { [key: string]: DrawsCategoryType };

const sortDataStatuses: SortDataStatuses = {
  active: RAFFLES_STATUSES.Active,
  end: RAFFLES_STATUSES.End,
  hiddenActive: RAFFLES_STATUSES.HiddenActive,
  hiddenEnd: RAFFLES_STATUSES.HiddenEnd,
};

export const AdminDraws = () => {
  const dispatch = useAppDispatch();
  const pagesCount = useAppSelector(adminDrawsPagesCountSelector);
  const adminDrawsItems = useAppSelector(adminDrawsItemsSelector);
  const isAdminDrawsFetching = useAppSelector(isAdminDrawsFetchingSelector);

  const [drawsCategory, setDrawsCategory] = useState<DrawsCategoryType>(RAFFLES_STATUSES.Active);

  const [searchParams, setSearchParams] = useCustomSearchParams<AdminRafflesQueryType>();
  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.pageSize || '10';
  const title = searchParams.title || undefined;
  const sortData = searchParams.sortData || RAFFLES_STATUSES.Active;
  const sortDirection = SORT_DIRECTION.Desc;

  useEffect(() => {
    dispatch(
      adminRafflesThunks.getAdminRaffles({
        params: {
          page: +currentPage,
          pageSize: +pageSize,
          sortData,
          title,
          sortDirection,
        },
      })
    )
      .unwrap()
      .then(() => setDrawsCategory(sortDataStatuses[sortData]));
  }, [currentPage, sortData, title, pageSize]);

  const onRaffleCategoryChange = (category: DrawsCategoryType) => {
    setDrawsCategory(category);
    setSearchParams({ ...searchParams, sortData: category });
  };

  const onPageChange = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const getRafflesAfterEdit = () => {
    dispatch(
      adminRafflesThunks.getAdminRaffles({
        params: {
          page: +currentPage,
          pageSize: +pageSize,
          sortData,
          title,
          sortDirection,
        },
      })
    );
  };

  const mappedDrawsCards = adminDrawsItems.map(drawData => {
    return (
      <AdminDrawCard
        key={drawData.id}
        drawData={drawData}
        getRafflesAfterEdit={getRafflesAfterEdit}
        drawsCategory={drawsCategory}
      />
    );
  });

  return (
    <>
      <div className={s.adminDrawsNavWrapper}>
        <AdminDrawsCategoryButtons
          onRaffleCategoryChange={onRaffleCategoryChange}
          drawsCategory={drawsCategory}
          setDrawsCategory={setDrawsCategory}
        />
        <AdminDrawsSearch
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          disabled={isAdminDrawsFetching}
        />
      </div>
      {isAdminDrawsFetching ? (
        <PreLoader />
      ) : (
        <>
          <div className={s.adminDrawsCardsWrapper}>{mappedDrawsCards}</div>
          <SalesPagination page={currentPage} pagesCount={pagesCount} onChange={onPageChange} />
        </>
      )}
    </>
  );
};
