import axios from 'axios';
import { isTokenShouldRefresh, TOKENS } from '../../sales/common/utils/tokenHelpers';
import { apiCustomer } from './customersAPI';

export const customerInstance = axios.create({
  baseURL: process.env.REACT_APP_TELEGIV_API,
});

customerInstance.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem(TOKENS.CUSTOMER)}`;
  config.withCredentials = true;
  return config;
});

customerInstance.interceptors.response.use(value => {
  const isRefresh = isTokenShouldRefresh(TOKENS.CUSTOMER_EXP_TIME);
  isRefresh && apiCustomer.refreshToken();
  return value;
});
