import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  ClientType,
  CommonDataType,
  InfoDataType,
  ParamsType,
  publicManagerApi,
  TeammateType,
} from './API/apiPublicManager';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { clearStateAfterLogout } from './common/actions/clearStateAfterLogout';

const slice = createSlice({
  name: 'sales/publicManager',
  initialState: {
    isFetching: false,
    clients: {
      totalCount: 0,
      pagesCount: 0,
      items: [],
    } as CommonDataType<ClientType>,
    team: {
      totalCount: 0,
      pagesCount: 0,
      items: [],
    } as CommonDataType<TeammateType>,
    info: {
      manager: {},
      teamMembers: {},
      profit: {},
      managerClients: {},
    } as InfoDataType,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getClients.fulfilled, (state, action) => {
        state.clients = action.payload.clients;
      })
      .addCase(getTeam.fulfilled, (state, action) => {
        state.team = action.payload.team;
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.info = action.payload.info;
      })
      .addCase(clearStateAfterLogout, state => {
        state.clients = {
          totalCount: 0,
          pagesCount: 0,
          items: [],
        } as CommonDataType<ClientType>;
        state.team = {
          totalCount: 0,
          pagesCount: 0,
          items: [],
        } as CommonDataType<TeammateType>;
        state.info = {
          manager: {},
          teamMembers: {},
          profit: {},
          managerClients: {},
        } as InfoDataType;
      })
      .addMatcher(isAnyOf(getInfo.pending, getClients.pending, getTeam.pending), state => {
        state.isFetching = true;
      })
      .addMatcher(isAnyOf(getInfo.fulfilled, getClients.fulfilled, getTeam.fulfilled), state => {
        state.isFetching = false;
      });
  },
});

const getClients = createAsyncThunk<{ clients: CommonDataType<ClientType> }, ParamsType | undefined>(
  'sales/getClients',
  async (data = {}, { rejectWithValue, dispatch }) => {
    try {
      const res = await publicManagerApi.getClients(data);
      return { clients: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const getTeam = createAsyncThunk<{ team: CommonDataType<TeammateType> }, ParamsType | undefined>(
  'sales/getTeam',
  async (data = {}, { rejectWithValue, dispatch }) => {
    try {
      const res = await publicManagerApi.getTeam(data);
      return { team: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const getInfo = createAsyncThunk<{ info: InfoDataType }>(
  'sales/getInfo',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await publicManagerApi.getInfo();
      return { info: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);
export const salesPublicManagerReducer = slice.reducer;
export const publicManagerThunks = {
  getTeam,
  getInfo,
  getClients,
};
