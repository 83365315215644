import { NavigateOptions, URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions
) => void;

export function useCustomSearchParams<T extends string>(): [{ [key in T]: string }, SetURLSearchParams] {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(new URLSearchParams(search)) as { [key in T]: string };
  return [searchAsObject, setSearch];
}
