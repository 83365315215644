import React from 'react';
import style from '../CreateDraw.module.css';
import { TooltipComponent } from './TooltipComponent';

type TitleDrawType = {
  title: string;
  titleText?: string;
  id?: string;
};

export const TitleDraw = ({ title, titleText, id }: TitleDrawType) => {
  return (
    <div className={style.wrapperTitleDraw}>
      <span id={id}>{title}</span>
      {titleText && <TooltipComponent title={titleText} />}
    </div>
  );
};
