export const setWinnersDisplaying = (winnersLength: number) => {
  const displayingSchema = {
    title: 'Победитель:',
    dots: '',
  };
  if (winnersLength > 1) {
    displayingSchema.title = 'Победители:';
    displayingSchema.dots = '...';
  }
  return displayingSchema;
};
