import React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { AdminHeader } from '../adminHeader/AdminHeader';
import { useFormik } from 'formik';
import { adminLogin } from '../adminAuthSlice';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { Navigate } from 'react-router-dom';
import { PATH } from '../../../common/path/puth';
import s from './AdminLogin.module.scss';
import { CustomButton } from '../../../common/components/customButton/CustomButton';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';

export const AdminLogin = () => {
  const dispatch = useAppDispatch();
  const isLoggedInToken = useAppSelector(state => state.admin.isLoggedInToken);

  const formik = useFormik({
    initialValues: {
      email: '',
      pass: '',
    },
    onSubmit: values => {
      dispatch(adminLogin({ email_address: values.email, password: values.pass }));
    },
  });
  if (isLoggedInToken) {
    return <Navigate to={PATH.users} />;
  }

  return (
    <div>
      <AdminHeader onlyLogo />
      <BlockTitle title={'Admin Sign In'} />
      <Paper elevation={0} style={{ maxWidth: 400, margin: '30px auto', padding: 50 }}>
        <form onSubmit={formik.handleSubmit} className={`${s.formContainer}`}>
          <TextField size='small' label='Email' id='email' type='text' {...formik.getFieldProps('email')} />
          <TextField size='small' label='Пароль' id='password' type='password' {...formik.getFieldProps('pass')} />
          <CustomButton title={'Log In'} textColor={'white'} />
        </form>
      </Paper>
    </div>
  );
};
