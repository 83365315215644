import React, { FC, useState } from 'react';
import s from '../AdminDrawCard.module.scss';

type PropsType = {
  onChangeVisibilityDraw: (isHidden: boolean) => void;
  title: 'Скрыть' | 'Показать';
  isHidden: boolean;
  drawCardHideBtnClassName: string;
};

export const HideButton: FC<PropsType> = ({ onChangeVisibilityDraw, title, isHidden, drawCardHideBtnClassName }) => {
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const showConfirmButtonHandler = () => {
    setShowConfirmButton(true);
    setTimeout(() => {
      setShowConfirmButton(false);
    }, 3000);
  };

  const onHideChangeConfirm = () => {
    onChangeVisibilityDraw(isHidden);
  };

  return showConfirmButton ? (
    <button className={s.drawCardConfirmBtn} onClick={onHideChangeConfirm}>
      Подтвердить
    </button>
  ) : (
    <button className={drawCardHideBtnClassName} onClick={showConfirmButtonHandler}>
      {title}
    </button>
  );
};
