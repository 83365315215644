import { createSlice } from '@reduxjs/toolkit';
import { handleMailingModal, setFormFields, setMailingModalVariant } from './adminMailingActions';
import { mailingRaffleTestAsyncAction } from './adminMailingAsyncActions';
import { AdminMailingModalVariant, AdminMailingState, ButtonUsage } from '../types';
import { AgesDisplayValues, GenderDisplayValues } from '../../../../common/shemas';

const initialState: AdminMailingState = {
  form: {
    photo: '',
    countries: ['all'],
    ages: [AgesDisplayValues.ALL],
    gender: [GenderDisplayValues.ALL],
    description: '',
    buttonUsage: ButtonUsage.YES,
    buttonText: '',
    buttonLink: '',
  },
  modal: {
    isOpen: false,
    variant: AdminMailingModalVariant.INFO,
  },
  raffleId: null,
};

export const adminMailingSlice = createSlice({
  name: 'adminMailing',
  initialState: initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(setFormFields, (state, action) => {
        state.form = action.payload;
      })
      .addCase(handleMailingModal, (state, action) => {
        state.modal = {
          isOpen: action.payload,
          variant: AdminMailingModalVariant.INFO,
        };
      })
      .addCase(setMailingModalVariant, (state, action) => {
        state.modal.variant = action.payload;
      })
      .addCase(mailingRaffleTestAsyncAction.fulfilled, (state, action) => {
        state.modal.isOpen = true;
        state.raffleId = action.payload.raffleId;
      }),
});

export const adminMailingReducer = adminMailingSlice.reducer;
