export const TEXT_TOGGLE_FIELD = 'Маркетинговые Cookies';
export const TEXT_SMALL_POPUP_COOKIE = 'Мы используем файлы Сookie, для вашего максимального удобства.';
export const TEXT_lINK_SMALL_POPUP_COOKIE = 'ПОЛИТИКА COOKIE';
export const TEXT_BUTTON_SMALL_POPUP_COOKIE = 'принять';
export const TEXT_1_HEADER_BIG_COOKIE = 'настрой';
export const TEXT_2_HEADER_BIG_COOKIE = 'cookies';
export const TEXT_1_BIG_POPUP_COOKIE =
  'Мы используем файлы Сookie, чтобы обеспечить вам максимальное удобство. Нажимая «Разрешить все», вы даёте нам своё согласие на размещение и использование Сookies в соответствии с ';
export const TEXT_2_BIG_POPUP_COOKIE = 'ПОЛИТИКОЙ COOKIE';
export const TEXT_BUTTON_LEFT_POPUP_COOKIE = 'разрешить все';
export const TEXT_BUTTON_RIGHT_POPUP_COOKIE = 'отклонить все';
export const ID_HEADER = 'header';
export const DATA_TOGGLE_FILDS = [
  { id: ID_HEADER, text: 'Основные Cookies', element: 'ВАЖНОЕ' },
  { id: 'marketCookie', text: 'Маркетинговые Cookies', element: 'switch' },
  { id: 'personalCookie', text: 'Cookies персонализации', element: 'switch' },
  { id: 'analiticCookie', text: 'Cookies аналитики', element: 'switch' },
];
