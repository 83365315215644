import React from 'react';
import { WithdrawalsAction } from './withdrawalsAction/WithdrawalsAction';
import s from './SalesWithdrawals.module.scss';

export const SalesWithdrawals = () => {
  return (
    <div className={s.withdrawalsBlock}>
      <WithdrawalsAction />
    </div>
  );
};
