import {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import s from './ButtonUiKit.module.scss';
import { TypographyUiKit } from '../typography/TypographyUiKit';
import { Icon } from '../icons/IconWrapper';
import { VariantButton } from '../../../common/enums/variantButton';
import { SizeButton } from '../../../common/enums/sizeButton';

type ButtonProps<T extends ElementType> = {
  as?: T;
  variant?: VariantButton;
  children?: ReactNode;
  className?: string;
  iconStart?: string;
  iconEnd?: string;
  dark?: boolean;
  size?: SizeButton;
  disabled?: boolean;
  viewBox?: string;
};
export const ButtonUiKit = <T extends ElementType = 'button'>(
  props: ButtonProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>
) => {
  let {
    variant = 'primary',
    className,
    as: Component = 'button',
    children,
    iconStart,
    iconEnd,
    size = SizeButton.large,
    dark = false,
    disabled = false,
    viewBox = '0 0 24 24',
    ...rest
  } = props;
  const classNames = clsx(s[variant], className, size && s[size], dark && s.dark, disabled && s.disabled);
  const classNamesNoAnimation = clsx(classNames, s.noAnimation);

  // this code accoding figma layout
  //let typographyVariant: VariantTypographyType = 'buttonL';
  // const getVariantTypography = useMemo(() => {
  //   if (size === 'small') typographyVariant = 'buttonS';
  //   if (size === 'medium') typographyVariant = 'buttonM';
  //   if (size === 'large') typographyVariant = 'buttonL';
  //   if (variant === 'link') {
  //     if (size === 'small') typographyVariant = 'smallLink';
  //     if (size === 'large') typographyVariant = 'bigLink';
  //   }
  //   if (variant === 'text') {
  //     if (size === 'small') typographyVariant = 'smallText';
  //     if (size === 'large') typographyVariant = 'buttonS';
  //   }
  //   if (variant === 'navigation') typographyVariant = 'buttonS';
  //   return (typographyVariant = 'buttonL');
  // }, [size, variant]);

  //this is common variant of typography
  const typographyVariant = useMemo(() => {
    switch (size) {
      case SizeButton.small:
        return variant === 'link' ? 'smallLink' : 'buttonS';
      case SizeButton.medium:
        return variant === 'link' ? 'smallText' : 'buttonM';
      case SizeButton.large:
        return variant === 'link' ? 'bigLink' : 'buttonL';
      default:
        return 'buttonL';
    }
  }, [size, variant]);

  const id = useId();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (buttonRef && buttonRef.current !== null) {
      const rect = buttonRef.current?.getBoundingClientRect();
      const y = e.clientY - (rect?.top || 0);
      const x = e.clientX - (rect?.left || 0);
      buttonRef.current?.style.setProperty('--x', x + 'px');
      buttonRef.current?.style.setProperty('--y', y + 'px');
    }
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.addEventListener('mousemove', handleMouseMove);
    }
    return () => buttonRef.current?.removeEventListener('mousemove', handleMouseMove);
  }, [id]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  function isMobileDevice() {
    return navigator.userAgent.indexOf('Mobile') !== -1;
  }

  return (
    <Component ref={buttonRef} id={id} className={isMobile ? classNamesNoAnimation : classNames} {...rest}>
      {iconStart ? <Icon className={s.icon} iconId={iconStart} viewBox={viewBox} /> : ''}
      <TypographyUiKit variant={typographyVariant} className={s.textBtn}>
        {children}
      </TypographyUiKit>
      {iconEnd ? <Icon className={s.icon} iconId={iconEnd} viewBox={viewBox} /> : ''}
    </Component>
  );
};
