import React, { FC, ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from 'usehooks-ts';

type Props = {
  children: ReactNode;
  closeModalOnOutsideClick: () => void;
  className: string;
};

export const PortalModal: FC<Props> = ({ children, closeModalOnOutsideClick, className }) => {
  const portalContainer = document.getElementById('modal-root');
  const ref = useRef(null);
  useOnClickOutside(ref, closeModalOnOutsideClick);

  return ReactDOM.createPortal(
    <div className={className}>
      <div ref={ref}>{children}</div>
    </div>,
    portalContainer!
  );
};
