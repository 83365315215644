import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../../common/path/puth';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { getTeamSelector } from '../../../managerPublicSelectors';
import { Team } from '../../../common/components/team/Team';

export const ManagerTeam = () => {
  const navigate = useNavigate();
  const toAllTeam = () => {
    navigate(`/sales/${PATH.salesAllTeam}`);
  };

  const team = useAppSelector(getTeamSelector);

  return <Team team={team} btnAction={toAllTeam} variant={'sales'} />;
};
