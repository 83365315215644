import React, { useMemo } from 'react';
import styles from '../../styles/AdminMailingModal.module.scss';
import { BasicModal } from '../../../../../common/components/modal/Modal';
import { BotMessage, AdminMailingPasswordForm } from '../../common';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { adminMailingModalSelector } from '../../bll';
import { AdminMailingModalVariant } from '../../types';
import { adminMailingPasswordFormValidationSchema, useAdminMailingModal } from '../../lib';
import { mailingRaffleAsyncAction } from '../../bll/adminMailingAsyncActions';

const BASIC_MODAL_WIDTH = '50%';
const BASIC_QUERY_MATCH_WIDTH = 820;

export const AdminMailingModal = () => {
  const dispatch = useAppDispatch();
  const { isOpen, variant, onClose, setModalVariant } = useAdminMailingModal();
  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: variant === AdminMailingModalVariant.INFO,
    initialValues: {
      code: '',
    },
    validationSchema: adminMailingPasswordFormValidationSchema,
    onSubmit: async values => {
      await dispatch(mailingRaffleAsyncAction({ code: values.code }));
    },
  });

  const isSubmitBtnDisabled = !formik.isValid || formik.isSubmitting;

  const buttonYesCallbackByModalVariant = useMemo(() => {
    return variant === AdminMailingModalVariant.INFO ? setModalVariant : formik.handleSubmit;
  }, [variant]);

  return (
    <BasicModal
      open={isOpen}
      setClose={onClose}
      basicWidth={BASIC_MODAL_WIDTH}
      queryMatchWidth={BASIC_QUERY_MATCH_WIDTH}
      bg={'#F8F8F8'}
    >
      <div className={styles.container}>
        {variant === AdminMailingModalVariant.INFO && <BotMessage />}
        {variant === AdminMailingModalVariant.PASSWORD && <AdminMailingPasswordForm formik={formik} />}
        <div className={styles.buttonsGroup}>
          <CustomButton callback={onClose} title={'Назад'} textColor={'white'} />
          <CustomButton
            callback={buttonYesCallbackByModalVariant}
            disable={isSubmitBtnDisabled}
            title={'Да, я уверен'}
            color={'warning'}
            textColor={'white'}
          />
        </div>
      </div>
    </BasicModal>
  );
};
