import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import s from './EditUser.module.scss';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { getPlanCount, ratePlans } from '../../../common/utils/ratePlanCount';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import { getSelectedUser } from '../adminSelectors';
import { FormikProps } from 'formik';
import { FormikValuesType } from '../../sales/common/types/commonTypes';

enum Options {
  Option1 = '1k',
  Option2 = '3k',
  Option3 = '10k',
  Option4 = '25k',
  Option5 = '100k',
  Option6 = '\u{221E}k',
}
enum OptionRaty {
  '1k' = 1,
  '3k' = 2,
  '10k' = 3,
  '25k' = 4,
  '100k' = 5,
  '\u{221E}k' = 6,
}

type PropsType = {
  formik: FormikProps<FormikValuesType>;
};

export const EditRatePlan: FC<PropsType> = ({ formik }) => {
  const selectedUser = useAppSelector(getSelectedUser);
  const [ratePlan, setRatePlan] = useState(1);
  const isSubscribersOverflow = selectedUser.draws_count >= ratePlans[ratePlan];

  useEffect(() => {
    setRatePlan(selectedUser.rate_plan_id);
  }, [selectedUser.rate_plan_id]);

  const checkedElementHandler = (optional: string, ratePlanId: number = 1) => {
    const isMatchedPlan = `${getPlanCount(ratePlanId)}k` === optional;
    const displayData = optional === '\u{221E}k' ? '\u{221E}' : optional;
    return <div className={`${s.ratePlanCell} ${isMatchedPlan ? s.checked : ''}`}>{displayData}</div>;
  };

  const onChangeRatePlan = (event: ChangeEvent<HTMLInputElement>) => {
    for (const key in OptionRaty) {
      if (key === event.currentTarget.value) {
        setRatePlan(+OptionRaty[key]);
        formik.setFieldValue(radioGroupName, +OptionRaty[key]);
      }
    }
  };

  const radioGroupName = 'ratePlanId';
  const ratePlansList = [
    { value: Options.Option1, ratePlanId: OptionRaty['1k'], label: checkedElementHandler(Options.Option1, ratePlan) },
    { value: Options.Option2, ratePlanId: OptionRaty['3k'], label: checkedElementHandler(Options.Option2, ratePlan) },
    { value: Options.Option3, ratePlanId: OptionRaty['10k'], label: checkedElementHandler(Options.Option3, ratePlan) },
    { value: Options.Option4, ratePlanId: OptionRaty['25k'], label: checkedElementHandler(Options.Option4, ratePlan) },
    { value: Options.Option5, ratePlanId: OptionRaty['100k'], label: checkedElementHandler(Options.Option5, ratePlan) },
    { value: Options.Option6, ratePlanId: OptionRaty['∞k'], label: checkedElementHandler(Options.Option6, ratePlan) },
  ];
  const mappedRatePlans = ratePlansList.map(({ value, label, ratePlanId }) => (
    <FormControlLabel
      sx={{ m: '0px' }}
      key={value}
      value={value}
      control={<Radio sx={{ display: 'none' }} />}
      label={label}
      disabled={ratePlanId < selectedUser.rate_plan_id}
    />
  ));

  return (
    <FormControl className={s.ratePlansContainer}>
      <FormLabel component='legend'>Тарифный план</FormLabel>
      <RadioGroup row className={s.ratePlansItemsBlock} name={radioGroupName} onChange={onChangeRatePlan}>
        {mappedRatePlans}
        <div className={`${s.subs} ${isSubscribersOverflow ? s.subsError : ''}`}>{selectedUser.draws_count}</div>
      </RadioGroup>
    </FormControl>
  );
};
