export const getNearestDate = () => {
  const currentDate = new Date();
  const minutesNow = currentDate.getMinutes();
  let nearestStartMinutes = Math.ceil((minutesNow + 20) / 10) * 10;
  let nearestStartHours = currentDate.getHours();

  if (minutesNow >= 38 && minutesNow < 40) {
    nearestStartMinutes = 0;
    nearestStartHours = new Date().getHours() + 1;
  } else if (minutesNow >= 40 && minutesNow < 50) {
    nearestStartMinutes = 10;
    nearestStartHours = new Date().getHours() + 1;
  } else if (minutesNow >= 50) {
    nearestStartMinutes = 20;
    nearestStartHours = new Date().getHours() + 1;
  }
  return { nearestStartMinutes, nearestStartHours, currentDate };
};
