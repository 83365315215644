import s from './Section3.module.scss';
import { Section } from '../Section';
import { Green, Typography } from '../../../../../../components/typography';
import { Button } from '../../../../../../components/button';
import { TextSlider } from './text-slider';
import { texts } from './textsSlider';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../../../common/path/puth';
import { ScrollLink } from '../../../../../../common/components/scrollLink/scrollLink';
import React from 'react';

export const Section3 = () => {
  return (
    <div className={s.section}>
      <Section className={s.bgcRight} dark></Section>
      <Section className={s.bgcLeft} dark></Section>

      <div className={clsx('container', s.container)}>
        <Section className={s.right} dark>
          <Typography variant={'title_primary_1'} align={'start'} className={s.title}>
            Работать
            <br /> с сервисом розыгрыша призов <Green>Telegive</Green> - просто!
          </Typography>

          <div className={s.rightBottom}>
            <div className={s.text}>
              <Typography variant={'small_text'} uppercase align={'start'} nonAdapt>
                Хочешь присоединиться к команде?
                <br /> Для это достаточно пройти регистрацию на платформе!
              </Typography>
            </div>
            <div className={s.btnContainer}>
              <Button variant={'primary'} as={NavLink} to={`/sales/${PATH.registration}`}>
                присоединиться
              </Button>
              <ScrollLink to={'faq'}>
                <Button variant={'secondary'}>подробнее</Button>
              </ScrollLink>
            </div>
          </div>
        </Section>

        <Section className={s.left} dark>
          <div className={s.sliderContainer}>
            <Typography variant={'title_secondary'} bold nonAdapt>
              <Green>
                тебе
                <br /> не нужно
              </Green>
            </Typography>
            <TextSlider texts={texts} interval={7000} />
          </div>
        </Section>
      </div>
    </div>
  );
};
