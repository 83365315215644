import React from 'react';
import styles from '../styles/AdminMailing.module.scss';
import { AdminMailingForm } from './components';
import { BlockTitle } from '../../../sales/common/components/blockTitle/BlockTitle';
import { AdminMailingModal } from './components/AdminMailingModal';

export const AdminMailingPage = () => {
  return (
    <>
      <div className={styles.pageHeader}>
        <BlockTitle title={'Рассылка'} />
      </div>
      <AdminMailingForm />
      <AdminMailingModal />
    </>
  );
};
