import React, { ChangeEvent, FC } from 'react';
import s from '../AdminDrawCard.module.scss';
import TextField from '@mui/material/TextField';

type PropsType = {
  editMode: boolean;
  editableHeader: string;
  changeTitleHandler: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  outOfEditMode: (e: React.KeyboardEvent) => void;
};
export const EditableDrawTitle: FC<PropsType> = ({ editMode, editableHeader, changeTitleHandler, outOfEditMode }) => {
  return (
    <div className={s.drawCardTitleWrapper}>
      {editMode ? (
        <TextField
          fullWidth={true}
          value={editableHeader}
          onKeyDown={outOfEditMode}
          onChange={changeTitleHandler}
          multiline
          autoFocus={true}
          maxRows={7}
          onFocus={e => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
        />
      ) : (
        <span className={s.drawTitle}>{editableHeader}</span>
      )}
    </div>
  );
};
