import { RootState } from '../../bll/store/store';
import { MANAGER_PAID_CLIENTS_MINIMUM_VALUE } from './common/constants';

const promoSelector = (state: RootState) => state.salesPublicManager.info.manager.promo;
const managerIdSelector = (state: RootState) => state.salesPublicManager.info.manager.id;
const isProfitActivatedSelector = (state: RootState) =>
  state.salesPublicManager.info.managerClients.paidClients >= MANAGER_PAID_CLIENTS_MINIMUM_VALUE;
const managersSelector = (state: RootState) => state.salesPublicManager.info.teamMembers.managers;
const teamClientsSelector = (state: RootState) => state.salesPublicManager.info.teamMembers.teamClients;
const totalProfitSelector = (state: RootState) => state.salesPublicManager.info.profit.total;
const incomeFromClientsSelector = (state: RootState) => state.salesPublicManager.info.profit.incomeFromClients;
const incomeFromTeamSelector = (state: RootState) => state.salesPublicManager.info.profit.incomeFromTeam;
const paidClientsSelector = (state: RootState) => state.salesPublicManager.info.managerClients.paidClients;
const freeClientsSelector = (state: RootState) => state.salesPublicManager.info.managerClients.freeClients;
const totalClientsSelector = (state: RootState) => state.salesPublicManager.info.managerClients.totalClients;

export {
  promoSelector,
  managerIdSelector,
  isProfitActivatedSelector,
  managersSelector,
  teamClientsSelector,
  totalProfitSelector,
  incomeFromClientsSelector,
  incomeFromTeamSelector,
  paidClientsSelector,
  freeClientsSelector,
  totalClientsSelector,
};
