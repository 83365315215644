import React, { ReactNode } from 'react';

type itemType = {
  title: string;
  content: string | ReactNode;
  button?: boolean;
};

export const faqItems: itemType[] = [
  {
    title: 'Что такое Telegiv?',
    content: (
      <>
        Telegiv - это сервис розыгрыша призов в Telegram.
        <br />С помощью Telegiv, бренды и блогеры за считанные дни получают десятки тысяч реальных подписчиков в свои
        Telegram каналы.
      </>
    ),
  },
  {
    title: 'Как начать зарабатывать с Telegiv?',
    content: (
      <>
        1. Зарегистрируйся
        <br />
        2. Скопируй свой промокод
        <br />
        3. Отправь его брендам и блогерам
        <br />
        Готово!
      </>
    ),
  },
  {
    title: 'Как и когда я получу свой заработок?',
    content: (
      <>
        Деньги отправляются <b>в течении 3 рабочих дней</b> после того, как ты на странице баланса нажмешь кнопку «вывод
        средств».
        <br />
        <br />
        Менеджер Telegiv пришлет тебе сумму в USDT в твой Telegram. После этого ты сможешь легко вывести деньги на свою
        карту.
        <br />
        <b>Обрати внимание на условия вывода средств из Telegram.</b>
      </>
    ),
  },

  {
    title: 'Я буду получать заработок один или несколько раз?',
    content: (
      <>
        Ты будешь получать заработок с клиента <b>каждый раз, когда он оплачивает новый тариф.</b>
        <br />
        <br />У нас есть 6 тарифов.
        <br />
        Они различаются между собой стоимостью и количеством подписчиков.
        <br />
        <br />
        Клиенты обычно покупают тарифы плавно, один за другим, тем самым плавно поднимая лимит подписчиков, которые они
        могут привлечь на свои каналы с помощью Telegiv.
        <br />
        <br />
        Так что каждый клиент будет приносить заработок в течении длительного времени.
        <br />И каждый раз твое вознаграждение (с новой оплаты) будет <b>все больше и больше.</b>
      </>
    ),
  },
  {
    title: 'Почему Telegiv предлагает вознаграждение за привлечение клиентов?',
    content: (
      <>
        <b>У нас есть ясная цель:</b>
        <br />
        Мы стремимся к созданию мощной команды, в которой каждый участник будет иметь возможность раскрыть свой
        потенциал и достичь великих результатов.
        <br />
        <br />
        Мы ценим и по достоинству оцениваем вклад наших партнеров в формирование этой команды и нашу общую цель.
      </>
    ),
    button: true,
  },
  {
    title: 'Сколько промокодов я могу подарить?',
    content: (
      <>
        Сколько угодно.
        <br />
        <b>Лимитов нет.</b>
      </>
    ),
  },
  {
    title: 'Могу ли я пригласить работать своих друзей?',
    content: (
      <>
        Да, ты можешь приглашать друзей.
        <br />
        И они тоже могут начать зарабатывать с Telegiv.
        <br /> <br />
        Кстати, как только у тебя появится 5 платных клиентов, в твоем кабинете откроется
        <b> доступ к твоей личной пригласительной ссылке.</b>
        <br /> <br /> Ты сможешь отправлять эту ссылку друзьям и зарабатывать дополнительные деньги с их продаж.
        <br />
        Мы будем тебе платить <b>+10% от каждой продажи приглашённого друга.</b>
        <br /> <br />А друзей ты можешь пригласить сколько угодно.
        <br /> <br />
        <b>P.S</b>
        <br />
        <b>Именно здесь тебя и будут ждать самые большие заработки.</b>
      </>
    ),
    button: true,
  },
  {
    title: 'Где искать клиентов?',
    content: (
      <>
        После регистрации в личном кабинете ты найдешь много полезной информации.
        <br />
        Там мы подробно описали, <b>где искать</b> клиентов и <b>как с ними общаться.</b>
      </>
    ),
  },
  {
    title: 'А что с налогами?',
    content: (
      <>
        Компания Telegiv уважает закон и платит налоги с каждой продажи.
        <br />
        <br /> А что касается твоего вознаграждения, мы тебе отправляем <b>USDT.</b>
        <br />
        <b>Это криптовалюта.</b>
        <br />
        <br />
        Криптовалюты в большинстве стран не являются денежными средствами и
        <b> налоги за их отправку платить не нужно.</b>
        <br />
        <br />
        <b>Что касается вывода</b> криптовалюты в обычные деньги - здесь у каждой страны <b>свои законы.</b>
        <br />В каких-то странах налогов нет, а в каких-то - есть.
        <br />
        <br />
        Поэтому лучше всего узнай, какое налоговое законодательство действует у тебя в стране.
        <br />И нужно ли тебе будет платить налог с заработка.
      </>
    ),
    button: true,
  },
];
