import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_TELEGIV_API,
});

instance.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  return config;
});

export const apiAdmin = {
  adminLogin(payload: AdminAuthType) {
    return instance.post<{ accessToken: string }>('auth/admin/login', payload);
  },
  getCustomers(params: { searchNameTerm?: string; page?: string; size?: string }) {
    return instance.get<UsersResponseType>(`admin/customers`, { params });
  },
  getCustomerById(id: string) {
    return instance.get(`admin/customers/${id}`);
  },
  getPromoCode(token: string) {
    return instance.get<PromoCodeType[]>('admin/promo', {
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },
  putUserPhone(payload: UpdateUserPhoneType) {
    return instance.put<boolean>('admin/customer/phone', {
      email_address: payload.email_address,
      usr_phone_number: payload.usr_phone_number,
    });
  },
  putUserTgName(payload: UpdateUserTgNameType) {
    return instance.put<boolean>('admin/customer/name', {
      email_address: payload.email_address,
      usr_tg: payload.usr_tg,
    });
  },
  putRatePlan(payload: UpdateUserRateType) {
    return instance.put<boolean>('admin/admin/rateplan', {
      email_address: payload.email_address,
      ratePlanId: payload.rate_plan_id,
    });
  },
};

export type AdminAuthType = {
  email_address: string;
  password: string;
  promo?: string;
};
export type UsersResponseType = {
  pagesCount: number;
  page: number;
  pageSize: number;
  totalCount: number;
  items: Array<CustomersItemType>;
};
export type PostRegistrationCustomerType = {
  id: number;
  email_address: string;
  verified: boolean;
  rate_plan_id: number;
  draws_count: number;
  creation_timestamp: string;
};
export type CustomersItemType = {
  id: number;
  email_address: string;
  verified: boolean;
  rate_plan_id: number;
  draws_count: number;
  creation_timestamp: string;
  usr_phone_number: null | string;
  usr_tg: null | string;
};

export type PromoCodeType = {
  name: string;
};
export type UpdateUserPhoneType = {
  email_address: string;
  usr_phone_number: string;
  token?: string;
};
export type UpdateUserTgNameType = {
  email_address: string;
  usr_tg: string;
  token?: string;
};
export type UpdateUserRateType = {
  email_address: string;
  rate_plan_id: number;
  token?: string;
};
