import React, { FC } from 'react';
import s from './AdminDrawsCategoryButtons.module.scss';
import { DrawsCategoryType, RAFFLES_STATUSES } from '../../AdminDraws';

type CategoryButtonType = {
  title: string;
  activeStyle: string;
  category: DrawsCategoryType;
};

type PropsType = {
  drawsCategory: DrawsCategoryType;
  setDrawsCategory: (category: DrawsCategoryType) => void;
  onRaffleCategoryChange: (category: DrawsCategoryType) => void;
};
export const GeneralDrawsCategory: FC<PropsType> = ({ drawsCategory, onRaffleCategoryChange }) => {
  const categoryButtons: CategoryButtonType[] = [
    {
      title: 'Активные',
      activeStyle: `${drawsCategory === RAFFLES_STATUSES.Active ? s.activeButton : ''}`,
      category: RAFFLES_STATUSES.Active,
    },
    {
      title: 'Завершенные',
      activeStyle: `${drawsCategory === RAFFLES_STATUSES.End ? s.activeButton : ''}`,
      category: RAFFLES_STATUSES.End,
    },
    {
      title: 'Скрытые',
      activeStyle: '',
      category: RAFFLES_STATUSES.HiddenActive,
    },
  ];

  const mappedCategoryButtons = categoryButtons.map(({ title, activeStyle, category }, index) => {
    const onClickHandler = () => {
      onRaffleCategoryChange(category);
    };
    return (
      <button key={index} className={`${s.drawsCategoryBtn} ${activeStyle}`} onClick={onClickHandler}>
        {title}
      </button>
    );
  });

  return <div className={s.buttonContainer}>{mappedCategoryButtons}</div>;
};
