import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import s from './StatisticWidget.module.scss';
import { StatisticTitle, StatisticTypes, StatisticWidgetsData } from '../../types';
import { StatisticItemsTitle } from '../../mockData';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#7F7FD5' : '#308fe8',
  },
}));

type Props<T extends keyof StatisticWidgetsData> = {
  statistic: T;
  data: StatisticWidgetsData[T];
};

export const UsersStatisticsRange = <T extends keyof StatisticWidgetsData>({ data, statistic }: Props<T>) => {
  const title = statistic === StatisticTypes.age ? StatisticTitle.age : StatisticTitle.country;
  return (
    <div className={s.boxStats}>
      <h4>{title}</h4>

      <div className={s.dataStatic}>
        {Object.keys(data).map(key => {
          const currentKey = key as keyof StatisticWidgetsData[T];
          const valueRange = data[currentKey] as string;
          const titleRange = StatisticItemsTitle[statistic][currentKey] as string;

          return (
            <Box key={key} className={s.dataUsers}>
              <div className={s.typograhyLinear}>
                <Typography gutterBottom className={s.titleRange}>
                  {titleRange}
                </Typography>
                <Typography gutterBottom className={s.valueRange}>
                  {valueRange}%
                </Typography>
              </div>
              <BorderLinearProgress variant='determinate' value={Math.min(parseFloat(valueRange), 100)} />
            </Box>
          );
        })}
      </div>
    </div>
  );
};
