import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/';

import question from '../../../../../assets/svg/question.svg';
import style from '../CreateDraw.module.css';

type TooltipComponentType = {
  title: string;
};

const theme = createTheme({
  components: {
    MuiTooltip: {
      defaultProps: {
        style: {
          padding: '0 5px',
        },
      },
      styleOverrides: {
        tooltip: {
          fontSize: 16,
          lineHeight: 'normal',
        },
      },
    },
  },
});

export const TooltipComponent = ({ title }: TooltipComponentType) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Tooltip
          title={title}
          placement='bottom'
          leaveTouchDelay={4000}
          enterDelay={50}
          enterTouchDelay={50}
          className={style.tooltipSize}
        >
          <img src={question} alt={'question'} className={style.tooltipIcon} />
        </Tooltip>
      </ThemeProvider>
    </>
  );
};
