import React, { FC, PropsWithChildren } from 'react';
import s from './UsersTable.module.scss';
import { useWindowDimensions } from '../../../../common/utils/useWindowDimensions';
import sortAsc from '../../../../assets/svg/sort_asc.svg';
import sortDesc from '../../../../assets/svg/sort_desc.svg';
import { SortFieldsType, SortVariantType } from '../../commonAdminTypes';
import { FIELD_SORTING, SORT_DIRECTION } from '../../../../common/constants/enumConstants';

type PropsType = {
  sortDir: SortVariantType;
  sortField: SortFieldsType;
  sortChangeHandler: (field: SortFieldsType) => void;
};

export const UsersTableLayout: FC<PropsType & PropsWithChildren> = ({
  children,
  sortChangeHandler,
  sortField,
  sortDir,
}) => {
  const { width } = useWindowDimensions();
  const CHANGE_DATE_TITLE_WIDTH = 905;
  const CHANGE_SUBSCRIBERS_TITLE_WIDTH = 775;
  const isDateTitleChange = width <= CHANGE_DATE_TITLE_WIDTH;
  const isSubscribersTitleChange = width <= CHANGE_SUBSCRIBERS_TITLE_WIDTH;

  const sortIconVariant = (field: string) =>
    field === sortField && sortDir === SORT_DIRECTION.Desc ? sortDesc : sortAsc;

  return (
    <div className={s.tableWrapper}>
      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.dateCol}>{isDateTitleChange ? 'Дата' : 'Дата регистрации'}</th>
            <th className={s.idCol}>
              ID
              <img
                className={s.sort_icon}
                onClick={() => sortChangeHandler(FIELD_SORTING.Id)}
                src={sortIconVariant(FIELD_SORTING.Id)}
                alt={'sort'}
              />
            </th>
            <th className={s.emailCol}>Email</th>
            <th className={s.subscriptionCol}></th>
            <th className={s.subscribersCountCol}>
              {isSubscribersTitleChange ? 'Подписч' : 'Кол-во Подписчиков'}
              <img
                className={s.sort_icon}
                onClick={() => sortChangeHandler(FIELD_SORTING.DrawsCount)}
                src={sortIconVariant(FIELD_SORTING.DrawsCount)}
                alt={'sort'}
              />
            </th>
            <th className={s.editCol}></th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
