export const CUSTOMER_EMAIL = 'customer_email';
export const saveEmailToLocalStorage = (emailKeyName: string, email: string) => {
  localStorage.setItem(emailKeyName, email);
};

export const removeEmailFromLocalStorage = (emailKeyName: string) => {
  localStorage.removeItem(emailKeyName);
};

export const getEmailFromLocalStorage = (emailKeyName: string) => {
  return localStorage.getItem(emailKeyName);
};
