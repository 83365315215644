import React, { useEffect } from 'react';
import { ManagerInfo } from './ManagerInfo/ManagerInfo';
import s from './Transfer.module.scss';
import { AdminTransferHistory } from './history/AdminTransferHistory';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/storeHooks';
import { adminTransfersThunks } from '../adminTransfersSlice';
import { isManagerDataFetchingSelector, managerDataSelector } from '../adminTransfersSelectors';
import { PreLoader } from '../../../sales/common/components/preLoader/PreLoader';

export const Transfer = () => {
  const dispatch = useAppDispatch();
  const { managerId } = useParams();
  const managerData = useAppSelector(managerDataSelector);

  useEffect(() => {
    if (managerId) {
      dispatch(adminTransfersThunks.getManagerInfo(managerId));
    }
  }, [dispatch]);

  const isManagerDataFetching = useAppSelector(isManagerDataFetchingSelector);

  if (isManagerDataFetching) return <PreLoader />;

  return (
    <div>
      <div className={s.transfer}>
        <h2>
          <b>Перевод</b> - <a href={`mailto:${managerData.manager.email}`}>{managerData.manager.email}</a>
        </h2>
        <ManagerInfo managerData={managerData} />
        <br />
        <br />
        <AdminTransferHistory />
      </div>
    </div>
  );
};
