import { createTheme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    auth: true;
    main: true;
  }
}

export const muiTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3751FF',
    },
    secondary: {
      main: '#2F80ED',
    },
    info: {
      main: '#2D9CDB',
    },
    success: {
      main: '#27AE60',
    },
    warning: {
      main: '#EB5757',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            fontFamily: 'Mulish, sans-serif',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
      },
    },
  },
});
