import React, { FC } from 'react';
import s from './ClientsTable.module.scss';
import { ClientsTableCell } from './ClientsTableCell';
import { clientsRatePlans } from '../../../../constants/clientsRatePlans';

type PropsType = {
  regDate: string;
  email: string;
  ratePlan: string;
  subscribers: number;
  telegram: string;
};
export const ClientsTableRow: FC<PropsType> = ({ email, subscribers, telegram, ratePlan, regDate }) => {
  const isSubscribersOverflow = subscribers >= clientsRatePlans[ratePlan];
  const getSubscribersCellVariant = isSubscribersOverflow ? 'subscribersWarning' : 'subscribers';

  return (
    <tr>
      <td className={s.date}>
        <ClientsTableCell text={regDate} />
      </td>
      <td className={s.email}>
        <ClientsTableCell text={email} />
      </td>
      <td className={s.subscription}>
        <ClientsTableCell text={ratePlan} variant={'subscription'} />
      </td>
      <td className={s.subscribers}>
        <ClientsTableCell text={subscribers} variant={getSubscribersCellVariant} />
      </td>
      <td className={s.telegram}>
        <ClientsTableCell text={telegram} />
      </td>
    </tr>
  );
};
