import React from 'react';
import { StatsWrapper } from './StatsWrapper';
import { StatsItem } from './StatsItem';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { freeClientsSelector, paidClientsSelector, totalClientsSelector } from '../../../managerInfoSelectors';
export const MyClientsCount = () => {
  const totalClientsCount = useAppSelector(totalClientsSelector);
  const paidClientsCount = useAppSelector(paidClientsSelector);
  const freeClientsCount = useAppSelector(freeClientsSelector);

  return (
    <StatsWrapper>
      <StatsItem title={'Мои клиенты'} value={totalClientsCount} headingTitle />
      <StatsItem title={'Платные'} value={paidClientsCount} />
      <StatsItem title={'Бесплатные'} value={freeClientsCount} />
    </StatsWrapper>
  );
};
