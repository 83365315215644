import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { FormikValues } from 'formik';
import style from '../CreateDraw.module.css';
import { TitleDraw } from './TitleDraw';

type AddAvatarPropsType = {
  addPhoto: (value: string | ArrayBuffer) => void;
  avatar: string;
  formik: FormikValues;
};

export const AddAvatar = React.memo(({ addPhoto, avatar, formik }: AddAvatarPropsType) => {
  const [newAvatar, setNewAvatar] = useState<string>(avatar || '');
  const [photoError, setPhotoError] = useState<string>(''); // Состояние для отслеживания ошибки для отдельного поля формы

  useEffect(() => {
    setPhotoError('');

    if (avatar) {
      setNewAvatar(avatar);
    }
  }, [avatar]);

  const getPhotoHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();

    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Если размер файла больше 5MB, показываем ошибку
      if (selectedFile.size > 5000000) {
        setPhotoError('Фото должно быть не больше 5MB');
        formik.setFieldError('photo', 'Фото должно быть не больше 5MB');
        return;
      }

      // Устанавливаем файл в формик
      formik.setFieldValue('file', selectedFile);
      setPhotoError('');

      // Читаем содержимое файла и обновляем изображение
      fileReader.onload = () => {
        if (fileReader.result) {
          setNewAvatar(fileReader.result as string);
          addPhoto(fileReader.result);
        }
      };

      fileReader.readAsDataURL(selectedFile);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.titleAvatarBtnContainer}>
        <div id='photo' />
        <TitleDraw
          title={'Фото розыгрыша'}
          titleText={'Максимальный размер фото - 5MB. Рекомендуем использовать квадратное фото.'}
        />

        <div className={style.containerAvatar}>
          {newAvatar ? (
            <img src={newAvatar} className={style.photoUpload} alt={'avatar'} />
          ) : (
            <div className={style.emptyAvatar} />
          )}

          {photoError && <p className={style.avatarError}>{photoError}</p>}
        </div>

        <div className={style.addPhotoBtnContainer}>
          <Button className={style.addPhotoBtn} variant='contained' component='label' sx={{ margin: 0 }}>
            {newAvatar ? 'Изменить фото' : 'Добавить фото'}

            <input
              hidden
              accept='image/jpeg, image/png, image/bmp, image/webp'
              multiple
              type='file'
              onChange={getPhotoHandler}
            />
          </Button>
        </div>
      </div>
    </div>
  );
});
