import '../../../../styles/index.scss';
import { Main } from './section/main/Main';
import { AboutUs } from './section/about-us/AboutUs';
import React from 'react';
import { Section3 } from './section/section3/Section3';
import { HowWork } from './section/how-work/HowWork';
import { SectionBigTitle } from './section/sectionBigTitle/SectionBigTitle';
import { Account } from './section/account/Account';
import { Faq } from './section/faq/Faq';
import { Steps } from './section/steps/Steps';
import { Pluses } from './section/pluses/Pluses';
import { Start } from './section/start/Start';
import { Footer } from '../../../../components/footer';
import { Green } from '../../../../components/typography';
import { Header } from '../../../../components/header/Header';
import { SmallPopUpCookie } from '../../../../ui-kit/components/elements-cookies/small-popUp-cookie';

export const SalesLanding = () => {
  return (
    <div style={{ backgroundColor: 'var(--color-violet-2)' }}>
      <Header />
      <Main />
      <AboutUs />
      <Section3 />
      <SectionBigTitle>
        <Green>зарабатывай</Green>
        <br /> вместе с нами <br />
        <Green> до 200$ с клиента</Green>
      </SectionBigTitle>
      <HowWork />
      <SectionBigTitle largePadding>
        мы поможем тебе
        <br />
        <Green>начать зарабатывать</Green>
        <br />
        прямо сейчас
      </SectionBigTitle>
      <Faq />
      <Account />
      <Steps />
      <Pluses />
      <Start />
      <Footer />
      <SmallPopUpCookie />
    </div>
  );
};
