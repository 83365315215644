import React from 'react';
import { ReactComponent as Gift } from '../../../../../../assets/image/sales/landing/cards/gift.svg';
import { ReactComponent as Ticket } from '../../../../../../assets/image/sales/landing/cards/ticket-star.svg';
import { ReactComponent as Profile } from '../../../../../../assets/image/sales/landing/cards/profile-add.svg';
import { ReactComponent as Wallet } from '../../../../../../assets/image/sales/landing/cards/wallet-money.svg';
export const howWorkCards = [
  {
    title: 'получи промокод',
    desc: 'После регистрации у тебя в кабинете появится личный промокод.',
    img: <Ticket />,
  },
  {
    title: 'подари его клиенту',
    desc: 'Расскажи клиенту про Telegiv  и подари ему промокод на 1000 подписчиков.',
    img: <Gift />,
  },
  {
    title: 'подожди его регистрации',
    desc: 'При регистрации клиент вводит промокод и закрепляется за тобой в нашей базе данных.',
    img: <Profile />,
  },
  {
    title: 'получи свои деньги',
    desc: 'Со стоимости каждого тарифа, который купит твой клиент, ты будешь получать 15%.',
    img: <Wallet />,
  },
];
