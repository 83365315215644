import { useWindowSize } from '../hooks/useWindowSize';

export const GetCardDarkStatus = (
  darkElInSmallScreen: number[],
  darkElInBigScreen: number[],
  breakPoint: number,
  index: number
) => {
  const windowSize = useWindowSize();
  let dark = false;
  if (windowSize.width > breakPoint && darkElInBigScreen.includes(index)) {
    dark = true;
  }
  if (windowSize.width < breakPoint && darkElInSmallScreen.includes(index)) {
    dark = true;
  }
  return dark;
};
