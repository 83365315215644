import s from './../WidgetsPanel.module.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { InfoDataUsers } from '../../types';

type Props = InfoDataUsers;

export const InfoWidget = ({ count, increasedByPercentage, title }: Props) => {
  return (
    <Card className={s.infoWidgetContainer}>
      <CardContent>
        <Typography sx={{ fontSize: 24, lineHeight: '2.4rem', margin: 0 }} color='text.primary' gutterBottom>
          {count}
        </Typography>
        <Typography
          variant='h5'
          component='div'
          color={+increasedByPercentage > 0 ? 'var(--color-green-6)' : 'var(--color-error-1)'}
          sx={{ marginBottom: '0.6rem' }}
        >
          {increasedByPercentage}%
        </Typography>
        <Typography sx={{ fontSize: 16, mb: 1 }} color='text.secondary'>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};
