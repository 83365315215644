import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GetBalanceResponseType,
  GetHistoryRequestType,
  GetHistoryResponseType,
  salesBalanceApi,
} from './salesBalanceApi';
import { thunkErrorHandler } from '../../../../common/utils/thunkErrorHandler';
import { setAppStatusAC } from '../../../../bll/reducers/appReducer';
import { RequestStatuses } from '../../../../common/enums/requestStatuses';

const slice = createSlice({
  name: 'sales/balance',
  initialState: {
    isBalanceFetching: false,
    isHistoryFetching: false,
    historyData: {
      items: [],
      page: 0,
      size: 0,
      pagesCount: 0,
      totalCount: 0,
    } as GetHistoryResponseType,
    balanceData: {
      balance: 0,
      canWithdraw: false,
    } as GetBalanceResponseType,
  },
  reducers: {
    setEmptyHistoryData: state => {
      state.historyData = {
        items: [],
        page: 0,
        size: 0,
        pagesCount: 0,
        totalCount: 0,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getHistory.pending, state => {
        state.isHistoryFetching = true;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.historyData = action.payload.historyData;
        state.isHistoryFetching = false;
      })
      .addCase(getHistory.rejected, state => {
        state.isHistoryFetching = false;
      })
      .addCase(getBalance.pending, state => {
        state.isBalanceFetching = true;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.balanceData = action.payload.balanceData;
        state.isBalanceFetching = false;
      })
      .addCase(getBalance.rejected, state => {
        state.isBalanceFetching = false;
      });
  },
});

const getHistory = createAsyncThunk<{ historyData: GetHistoryResponseType }, GetHistoryRequestType>(
  'sales/balance/getHistory',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await salesBalanceApi.getHistory(arg);
      return { historyData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);
const getBalance = createAsyncThunk<{ balanceData: GetBalanceResponseType }>(
  'sales/balance/getBalance',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await salesBalanceApi.getBalance();
      return { balanceData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);
const checkPassword = createAsyncThunk(
  'sales/balance/checkPassword',
  async (arg: string, { dispatch, rejectWithValue }) => {
    dispatch(setAppStatusAC({ status: RequestStatuses.loading }));
    try {
      const res = await salesBalanceApi.checkPassword(arg);
      return res.data;
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(e);
    } finally {
      dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));
    }
  }
);

export const salesBalanceReducer = slice.reducer;
export const salesBalanceActions = slice.actions;
export const salesBalanceThunks = { getHistory, getBalance, checkPassword };
