import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import {
  adminDrawsAPI,
  AdminRafflesType,
  EditRaffleParams,
  GetRafflesQueryParams,
  RaffleType,
} from './API/adminDrawsAPI';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { setAppSuccessMessage } from '../../bll/reducers/appReducer';

const slice = createSlice({
  name: 'admin/draws',
  initialState: {
    adminDraws: {
      totalCount: 0,
      pagesCount: 0,
      page: 0,
      size: 0,
      items: [] as RaffleType[],
    },
    isFetching: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminRaffles.fulfilled, (state, action) => {
        state.adminDraws = action.payload.adminRaffles;
      })
      .addMatcher(adminDrawsPending, state => {
        state.isFetching = true;
      })
      .addMatcher(adminDrawsFulfilled, state => {
        state.isFetching = false;
      })
      .addMatcher(adminDrawsRejected, state => {
        state.isFetching = false;
      });
  },
});

const getAdminRaffles = createAsyncThunk<{ adminRaffles: AdminRafflesType }, { params: GetRafflesQueryParams }>(
  'admin/getRaffles',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await adminDrawsAPI.getDraws(arg.params);
      return { adminRaffles: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const adminEditRaffle = createAsyncThunk<void, { params: EditRaffleParams; raffleId: number }>(
  'admin/editRaffle',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      await adminDrawsAPI.hideDraw(arg.params, arg.raffleId);
      dispatch(setAppSuccessMessage({ successMessage: 'Розыгрыш отредактирован.' }));
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const adminDrawsPending = isPending(getAdminRaffles, adminEditRaffle);
const adminDrawsFulfilled = isFulfilled(getAdminRaffles, adminEditRaffle);
const adminDrawsRejected = isRejected(getAdminRaffles, adminEditRaffle);
export const adminRafflesSlice = slice.reducer;
export const adminRafflesThunks = {
  getAdminRaffles,
  adminEditRaffle,
};
