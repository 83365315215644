import React, { FC, PropsWithChildren } from 'react';
import s from './CompletedTransfersTable.module.scss';
import { useWindowDimensions } from '../../../../../common/utils/useWindowDimensions';

export const CompletedTransfersTableLayout: FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowDimensions();
  const CHANGE_DATE_TITLE_WIDTH = 905;
  const CHANGE_SUBSCRIBERS_TITLE_WIDTH = 775;
  const isDateTitleChange = width <= CHANGE_DATE_TITLE_WIDTH;
  const isSubscribersTitleChange = width <= CHANGE_SUBSCRIBERS_TITLE_WIDTH;

  return (
    <div className={s.tableWrapper}>
      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.dateCol}>{isDateTitleChange ? 'Дата' : 'Дата транзакции'}</th>
            <th className={s.sumCol}>Сумма</th>
            <th className={s.idCol}>ID менеджера</th>
            <th className={s.telegramCountCol}>Телеграм</th>
            <th className={s.editCol}></th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
