import React from 'react';
import { Green, Typography } from '../../../../../../components/typography';
import { Section } from '../Section';

import Ellipse from '../../../../../../assets/image/sales/landing/cards/Ellipse.svg';
import s from './HowWork.module.scss';
import { Button } from '../../../../../../components/button';
import clsx from 'clsx';
import { GetCardDarkStatus } from '../../../../../../common/utils/getCardDarkStatus';
import { howWorkCards } from './howWorkCards';
import { screenSizes } from '../../../../../../common/constants/screenSizes';
import { Card } from '../../../../../../components/Card/Card';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../../../common/path/puth';
import { ScrollLink } from '../../../../../../common/components/scrollLink/scrollLink';

export const HowWork = () => {
  return (
    <Section dark className={s.section}>
      <div className={clsx('container', s.container)}>
        <Typography variant={'title_block'} align={'end'}>
          как это <Green>работает?</Green>
        </Typography>
        <div className={s.content}>
          <div className={s.bgcImg}>
            <img src={Ellipse} alt='' className={s.ellips} />
            <img src={Ellipse} alt='' className={s.ellips} />
          </div>
          <div className={s.cardsContainer}>
            {howWorkCards.map((card, index) => {
              return (
                <Card
                  cardName={'howWork'}
                  key={index}
                  title={card.title}
                  desc={card.desc}
                  dark={GetCardDarkStatus([1, 2], [1, 3], screenSizes.mediumSmall, index)}
                  img={card.img}
                />
              );
            })}
          </div>
          <div className={s.bgcImgBottom}>
            <img src={Ellipse} alt='' className={s.ellips} />
          </div>
        </div>
        <div className={s.btnContainer}>
          <Button variant={'primary'} as={NavLink} to={`/sales/${PATH.registration}`}>
            получить свой промо
          </Button>
          <ScrollLink to={'faq'}>
            <Button variant={'secondary'}>подробнее</Button>
          </ScrollLink>
        </div>
      </div>
    </Section>
  );
};
