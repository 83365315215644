import React from 'react';
import { Clients } from '../../../sales/common/components/clients/Clients';
import { useAppSelector } from '../../../../common/hooks/storeHooks';
import { getManagerClientsSelector } from '../../adminManagerSelectors';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from '../../../../common/path/puth';

export const AdminManagerClients = () => {
  const clients = useAppSelector(getManagerClientsSelector);
  const navigate = useNavigate();
  const { managerId } = useParams();

  const toAllClients = () => navigate(`${PATH.adminTeam}/${managerId}/${PATH.adminManagerClients}`);

  return <Clients clients={clients} variant={'admin'} btnAction={toAllClients} />;
};
