import s from './Section.module.scss';
import { FC, ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  dark?: boolean;
  children?: ReactNode;
  className?: string;
  id?: string;
};
export const Section: FC<Props> = ({ dark = false, children, className, id }) => {
  return (
    <div id={id} className={clsx(s.section, className, dark && s.dark)}>
      {children}
    </div>
  );
};
