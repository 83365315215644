import React, { useEffect, useState } from 'react';
import Header from '../../../../common/components/header/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PreLoader } from '../../../sales/common/components/preLoader/PreLoader';
import { CustomButton } from '../../../../common/components/customButton/CustomButton';
import { EmailConfirmStatus } from './EmailConfirmStatus';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/storeHooks';
import { customerEmailSelector } from '../../customerSelectors';
import { confirmEmailResending, customerAuthActions, registrationConfirmation } from '../../customerAuthSlice';
import { PATH } from '../../../../common/path/puth';
import { CUSTOMER_EMAIL, getEmailFromLocalStorage } from '../../common/utils/emailStorageManager';

enum CONFIRMATION_STATUSES {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

type ConfirmationStatus = CONFIRMATION_STATUSES.PENDING | CONFIRMATION_STATUSES.SUCCESS | CONFIRMATION_STATUSES.FAILED;

export const EmailConfirmPage = () => {
  const [confirmStatus, setConfirmStatus] = useState<ConfirmationStatus>(CONFIRMATION_STATUSES.PENDING);
  const customerEmail = useAppSelector(customerEmailSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (code) {
      dispatch(registrationConfirmation({ confirmationCode: code }))
        .unwrap()
        .then(() => setConfirmStatus(CONFIRMATION_STATUSES.SUCCESS))
        .catch(() => setConfirmStatus(CONFIRMATION_STATUSES.FAILED));
    }
  }, []);

  const toLoginPage = () => {
    dispatch(customerAuthActions.setReinitialized());
    navigate(`${PATH.customerBaseUrl}${PATH.login}`);
  };
  const confirmEmailMessageResend = () => {
    const notLoggedInCustomerEmail = getEmailFromLocalStorage(CUSTOMER_EMAIL) ?? '';
    if (customerEmail) {
      dispatch(confirmEmailResending({ email: customerEmail }));
    } else {
      dispatch(confirmEmailResending({ email: notLoggedInCustomerEmail }));
    }
  };

  return (
    <>
      <Header logoOnly />
      {confirmStatus === CONFIRMATION_STATUSES.PENDING && <PreLoader />}
      {confirmStatus === CONFIRMATION_STATUSES.SUCCESS && (
        <EmailConfirmStatus
          type={'success'}
          firstMessage={'Email успешно подтвержден!'}
          secondMessage={'Теперь ты можешь создать бота.'}
        >
          <CustomButton
            title={'Войти в кабинет'}
            width={'200'}
            textColor={'white'}
            font={'main'}
            callback={toLoginPage}
          />
        </EmailConfirmStatus>
      )}
      {confirmStatus === CONFIRMATION_STATUSES.FAILED && (
        <EmailConfirmStatus
          type={'failed'}
          firstMessage={'Упс! Что то пошло не так.'}
          secondMessage={'Пожалуйста, попробуй ещё раз.'}
        >
          <CustomButton
            title={'Выслать письмо ещё раз'}
            textColor={'white'}
            font={'main'}
            callback={confirmEmailMessageResend}
          />
        </EmailConfirmStatus>
      )}
    </>
  );
};
