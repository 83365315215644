import s from './WidgetsPanel.module.scss';
import { InfoWidget } from './infoWidget/InfoWidget';
import { InfoWidgets, StatisticWidgets } from '../mockData';
import { InfoWidgetsData, StatisticTypes } from '../types';
import { StatisticWidget } from './statisticWidget/StatisticWidget';

export const WidgetsPanel = () => {
  return (
    <div className={s.widgetsPanelContainer}>
      {Object.keys(InfoWidgets).map((key: string) => {
        const widgetKey = key as keyof InfoWidgetsData;
        const widgetData = InfoWidgets[widgetKey];
        return (
          <InfoWidget
            key={key}
            count={widgetData.count}
            increasedByPercentage={widgetData.increasedByPercentage}
            title={widgetData.title}
          />
        );
      })}
      {Object.keys(StatisticWidgets).map((key: string) => {
        const widgetKey = key as StatisticTypes;
        const widgetData = StatisticWidgets[widgetKey];
        return <StatisticWidget key={key} statistic={widgetKey} data={widgetData} />;
      })}
    </div>
  );
};
