import React from 'react';
import s from './AdminManagerInfo.module.scss';
import { ManagerInfo } from '../../../../sales/common/components/managerInfo/ManagerInfo';
import { AdminManagerContacts } from './AdminManagerContacts';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { isIncomeActivatedSelector, managerPromoCodeSelector } from '../../../adminManagerSelectors';
import { useParams } from 'react-router-dom';
import { adminManagerThunks } from '../../../adminManagerSlice';

export const AdminManagerInfo = () => {
  const dispatch = useAppDispatch();
  const managerPromoCode = useAppSelector(managerPromoCodeSelector);
  const isIncomeActivated = useAppSelector(isIncomeActivatedSelector);

  const { managerId } = useParams();

  const isIncomeActiveText = isIncomeActivated ? 'Активирован' : 'На паузе';

  const handleCheckboxChange = () => {
    if (managerId) dispatch(adminManagerThunks.sendManagerId({ managerId }));
  };

  return (
    <div className={s.managerInfo}>
      <ManagerInfo title={'Промокод'} cellInfo={managerPromoCode} withoutTips copyTextAllow />
      <div>
        <ManagerInfo title={'Доп заработок'} cellInfo={isIncomeActiveText} withoutTips />
        <div className={s.wrapperCheckBox}>
          <input
            id={'checkbox_id'}
            className={s.checkBoxIncome}
            type='checkbox'
            checked={isIncomeActivated}
            onChange={handleCheckboxChange}
          />
          <label className={s.labelIncome} htmlFor={'checkbox_id'}>
            всегда активный заработок
          </label>
        </div>
      </div>

      <AdminManagerContacts />
    </div>
  );
};
