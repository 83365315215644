import { ErrorUtilsDispatchType, handleServerNetworkError } from './error-utils';
import { AxiosError, isAxiosError } from 'axios';

export const thunkErrorHandler = (err: unknown, dispatch: ErrorUtilsDispatchType) => {
  const e = err as Error | AxiosError;
  if (isAxiosError(e)) {
    let errorMessage: string;
    if (e.response?.data) {
      if ((e.response.data as DataWithErrorDescription).errorDescription === null) {
        errorMessage = (e.response.data as DataWithErrorDescription).message;
        handleServerNetworkError(errorMessage, dispatch);
      } else if ((e.response.data as DataWithErrorDescription).errorDescription !== null) {
        errorMessage = (e.response.data as DataWithErrorDescription).errorDescription[0].message;
        handleServerNetworkError(errorMessage, dispatch);
      }
    } else if (!e.response) {
      handleServerNetworkError(e.message, dispatch);
    }
  } else {
    handleServerNetworkError('Some error!', dispatch);
  }
};

type DataWithErrorDescription = {
  errorDescription: { field: string; message: string }[];
  message: string;
};
