import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/storeHooks';
import { HistoryCategoryType } from '../../../sales/components/salesBalance/salesBalanceApi';
import { adminTransfersActions } from '../../adminTransfers/adminTransfersSlice';
import { adminManagerThunks } from '../../adminManagerSlice';
import { managerHistoryDataSelector } from '../../adminManagerSelectors';
import { ManagerHistoryTable } from '../../../sales/common/components/managerHistoryTable/ManagerHistoryTable';
import { HistoryButtonsType } from '../../../sales/common/types/commonTypes';
import { useCustomSearchParams } from '../../../../common/hooks/useCustomSearchParams';

type AdminManagerHistoryParams = 'page' | 'size';
export const AdminManagerHistory = () => {
  const dispatch = useAppDispatch();
  const historyData = useAppSelector(managerHistoryDataSelector);
  const { managerId } = useParams();

  const [historyCategory, setHistoryCategory] = useState<HistoryCategoryType>('mySales');
  const [searchParams, setSearchParams] = useCustomSearchParams<AdminManagerHistoryParams>();

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';

  const onPageChange = (page: string) => {
    setSearchParams({ page: page });
  };

  useEffect(() => {
    dispatch(adminTransfersActions.setEmptyHistoryData());
    if (managerId) {
      dispatch(
        adminManagerThunks.getManagerHistory({
          arg: { historyCategory: historyCategory, page: +currentPage, size: +pageSize },
          id: +managerId,
        })
      );
    }
  }, [dispatch, historyCategory, currentPage]);

  const historyButtons: HistoryButtonsType[] = [
    {
      title: 'Продажи',
      className: historyCategory === 'mySales' ? 'headerButtonsActive' : '',
      type: 'mySales',
    },
    {
      title: 'Продажи менеджеров',
      className: historyCategory === 'teamSales' ? 'headerButtonsActive' : '',
      type: 'teamSales',
    },
    {
      title: 'Заблокированные выплаты',
      className: historyCategory === 'withoutProfitSales' ? 'headerButtonsActive' : '',
      type: 'withoutProfitSales',
    },
    {
      title: 'Выплаты',
      className: historyCategory === 'withdrawal' ? 'headerButtonsActive' : '',
      type: 'withdrawal',
    },
  ];

  return (
    <ManagerHistoryTable
      historyCategory={historyCategory}
      onPageChange={onPageChange}
      currentPage={currentPage}
      historyData={historyData}
      historyButtons={historyButtons}
      setSearchParams={setSearchParams}
      setHistoryCategory={setHistoryCategory}
    />
  );
};
