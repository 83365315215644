import React from 'react';
import s from './AdminButtonGroup.module.scss';
import { NavLink } from 'react-router-dom';
import { MenuItemsType } from '../../sales/common/components/header/HeaderBurger';

type PropsType = {
  menuItems: MenuItemsType[];
};

export const AdminButtonGroup: React.FC<PropsType> = props => {
  const getClassName = ({ isActive }: { isActive: boolean }) => (isActive ? s.activeHeaderLink : s.headerLink);

  return (
    <div className={s.buttonGroup}>
      {props.menuItems.map((menuItem, index) => (
        <NavLink key={`${index}_${menuItem.buttonTitle}`} className={getClassName} to={menuItem.buttonLink}>
          {menuItem.buttonTitle}
        </NavLink>
      ))}
    </div>
  );
};
