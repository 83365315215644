import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import {
  adminManagerAPI,
  AdminManagerPublicType,
  GetManagersArgType,
  ManagerDataType,
  ManagerItemType,
} from './API/adminManagerAPI';
import { setAppSuccessMessage } from '../../bll/reducers/appReducer';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { ClientType, TeammateType } from '../sales/API/apiPublicManager';
import { GetHistoryResponseType } from '../sales/components/salesBalance/salesBalanceApi';
import { adminTransfersApi, GetHistoryArgsType } from './API/adminTransfersApi';
import axios from 'axios';

const slice = createSlice({
  name: 'admin/manager',
  initialState: {
    managerData: {
      manager: {},
      withdrawId: null as number | null,
      teamMembers: {},
      profit: {},
      managerClients: {},
    } as ManagerDataType,
    managerClients: {
      pagesCount: 0,
      totalCount: 0,
      items: [],
    } as AdminManagerPublicType<ClientType[]>,
    managerTeam: {
      pagesCount: 0,
      totalCount: 0,
      items: [],
    } as AdminManagerPublicType<TeammateType[]>,
    historyData: {
      items: [],
      page: 0,
      size: 0,
      pagesCount: 0,
      totalCount: 0,
    } as GetHistoryResponseType,
    isHistoryFetching: false,
    managersData: {
      totalCount: 0,
      pagesCount: 0,
      items: [],
    } as AdminManagerPublicType<ManagerItemType[]>,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getManagerById.fulfilled, (state, action) => {
        state.managerData = action.payload.managerData;
      })
      .addCase(getManagerClients.fulfilled, (state, action) => {
        state.managerClients = action.payload.clientsData;
      })
      .addCase(getManagerTeam.fulfilled, (state, action) => {
        state.managerTeam = action.payload.teamData;
      })
      .addCase(getManagers.fulfilled, (state, action) => {
        state.managersData = action.payload.managerData;
      })
      .addCase(getManagerHistory.pending, state => {
        state.isHistoryFetching = true;
      })
      .addCase(getManagerHistory.fulfilled, (state, action) => {
        state.historyData = action.payload.historyData;
        state.isHistoryFetching = false;
      })
      .addCase(getManagerHistory.rejected, state => {
        state.isHistoryFetching = false;
      })
      .addCase(sendManagerId.fulfilled, (state, action) => {
        state.managerData.manager.isActivated = true;
      })
      .addCase(sendManagerId.rejected, (state, action) => {
        if (action.payload === 'Manager is already activated') {
          state.managerData.manager.isActivated = true;
        } else {
          state.managerData.manager.isActivated = false;
        }
      });
  },
});

const getManagerById = createAsyncThunk<{ managerData: ManagerDataType }, { id: string }>(
  'admin/getManagerById',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await adminManagerAPI.getManagerById(arg.id);
      return { managerData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const getManagerClients = createAsyncThunk<
  { clientsData: AdminManagerPublicType<ClientType[]> },
  { id: string; page?: string; size?: string }
>('admin/getManagerClients', async (arg, { rejectWithValue, dispatch }) => {
  const params = {
    id: arg.id,
    size: arg.size,
    page: arg.page,
  };
  try {
    const res = await adminManagerAPI.getManagerClients(params);
    return { clientsData: res.data };
  } catch (e) {
    thunkErrorHandler(e, dispatch);
    return rejectWithValue(null);
  }
});
const getManagerTeam = createAsyncThunk<
  { teamData: AdminManagerPublicType<TeammateType[]> },
  { id: string; page?: string; size?: string }
>('admin/getManagerTeam', async (arg, { rejectWithValue, dispatch }) => {
  const params = {
    id: arg.id,
    size: arg.size,
    page: arg.page,
  };
  try {
    const res = await adminManagerAPI.getManagerTeam(params);
    return { teamData: res.data };
  } catch (e) {
    thunkErrorHandler(e, dispatch);
    return rejectWithValue(null);
  }
});
const getManagerHistory = createAsyncThunk<{ historyData: GetHistoryResponseType }, GetHistoryArgsType>(
  'admin/getManagerHistory',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await adminTransfersApi.getHistory(arg);
      return { historyData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const confirmTransfer = createAsyncThunk<void, { withdrawId: number }>(
  'admin/confirmTransfer',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      await adminManagerAPI.confirmTransfer(arg.withdrawId);
      dispatch(setAppSuccessMessage({ successMessage: 'Успешно! Перевод выполнен!' }));
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const sendManagerId = createAsyncThunk<void, { managerId: string }>(
  'admin/sendManagerId',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      await adminManagerAPI.sendManagerId(arg.managerId);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        thunkErrorHandler(e, dispatch);
        return rejectWithValue(e.response?.data.message);
      }
    }
  }
);

const getManagers = createAsyncThunk<{ managerData: AdminManagerPublicType<ManagerItemType[]> }, GetManagersArgType>(
  'admin/sales/team/getManagers',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await adminManagerAPI.getManagers(arg);
      return { managerData: res.data };
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

export const adminManagerPending = isPending(
  getManagerTeam,
  getManagerClients,
  getManagerById,
  confirmTransfer,
  getManagers
);
export const adminManagerFulfilled = isFulfilled(
  getManagerTeam,
  getManagerClients,
  getManagerById,
  confirmTransfer,
  getManagers
);
export const adminManagerRejected = isRejected(
  getManagerTeam,
  getManagerClients,
  getManagerById,
  confirmTransfer,
  getManagers
);

export const adminManagerReducer = slice.reducer;
export const adminManagerThunks = {
  getManagerById,
  getManagerClients,
  getManagerTeam,
  getManagerHistory,
  confirmTransfer,
  getManagers,
  sendManagerId,
};
