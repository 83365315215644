import React from 'react';
import { CustomForm } from '../../../../../common/components/customForm/CustomForm';
import { CommonInput } from '../../../../../common/components/commonInput/CommonInput';
import { PasswordInput } from '../../../../../common/components/passwordInput/PasswordInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormikValuesType } from '../../../common/types/commonTypes';
import { usePasswordVisible } from '../../../hooks/usePasswordVisible';
import { PATH } from '../../../../../common/path/puth';
import { SalesLoginParams } from '../../../API/apiSalesAuth';
import { useAppDispatch } from '../../../../../common/hooks/storeHooks';
import { salesAuthThunks } from '../../../salesAuthSlice';

export const SalesLogin = () => {
  const dispatch = useAppDispatch();
  const { visibility, toggleVisibility } = usePasswordVisible();

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Пожалуйста, введи Email'),
      password: Yup.string().required('Пожалуйста, введи пароль'),
    }),
    onSubmit: values => {
      if (values.email && values.password) {
        const params: SalesLoginParams = {
          email: values.email,
          password: values.password,
        };
        dispatch(salesAuthThunks.login(params));
        formik.resetForm();
      }
    },
  });

  return (
    <CustomForm
      helperText={'Нет аккаунта?'}
      linkText={'регистрация'}
      formTitle={'Вход'}
      btnColor={'primary'}
      btnTitle={'Авторизация'}
      handleSubmit={formik.handleSubmit}
      linkTo={`/sales/${PATH.salesRegistration}`}
    >
      <CommonInput name={'EMAIL'} placeholder={'Email'} formik={formik} inputName={'email'} />
      <PasswordInput
        name={'ПАРОЛЬ'}
        placeholder={'Пароль'}
        forgotPassLink={true}
        linkTo={`/sales/${PATH.salesRecoverPass}`}
        formik={formik}
        inputName={'password'}
        visibility={visibility}
        toggleVisibility={toggleVisibility}
      />
    </CustomForm>
  );
};
