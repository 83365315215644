import * as Yup from 'yup';
import { validatePhotoSize } from '../../../../../common/utils/validationUtils';
import { ButtonUsage } from '../../types';

export const adminMailingFormValidationSchema = Yup.object({
  photo: Yup.string().required('Добавь фото').test('test-photo', 'Фото больше 5 Mб', validatePhotoSize),
  description: Yup.string().required('Введи текст').max(900, 'Размер текста не должен превышать 900 символов'),
  buttonText: Yup.string()
    .max(30, 'Размер текста кнопки не должен превышать 30 символов')
    .when('buttonUsage', {
      is: (buttonUsage: string) => {
        return buttonUsage === ButtonUsage.YES;
      },
      then: Yup.string().required('Введи текст для кнопки'),
    }),
  buttonLink: Yup.string().when('buttonUsage', {
    is: (buttonUsage: string) => buttonUsage === ButtonUsage.YES,
    then: Yup.string().required('Введи ссылку для кнопки'),
  }),
});

export const IS_NUMBER_REGEXP = /^\d+$/s;
export const adminMailingPasswordFormValidationSchema = Yup.object({
  code: Yup.string()
    .nullable()
    .test('check-code', 'Code must be a correct number value', function (value) {
      if (value) {
        return IS_NUMBER_REGEXP.test(value);
      }
      return true;
    }),
});
