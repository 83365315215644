import React, { FC } from 'react';
import { StatsWrapper } from './StatsWrapper';
import { StatsItem } from './StatsItem';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { managersSelector, teamClientsSelector } from '../../../managerInfoSelectors';

type PropsType = {};
export const TeamCount: FC<PropsType> = () => {
  const teamClientsCount = useAppSelector(teamClientsSelector);
  const managerClientsCount = useAppSelector(managersSelector);

  return (
    <StatsWrapper>
      <StatsItem title={'Участников в команде'} value={managerClientsCount} headingTitle />
      <StatsItem title={'Клиентов у команды'} value={teamClientsCount} />
    </StatsWrapper>
  );
};
