import React, { FC, PropsWithChildren } from 'react';
import s from './ManagersTable.module.scss';
import sortAsc from '../../../../assets/svg/sort_asc.svg';
import sortDesc from '../../../../assets/svg/sort_desc.svg';
import { SortVariantType } from '../../commonAdminTypes';
import { SORT_DIRECTION } from '../../../../common/constants/enumConstants';

type PropsType = {
  sortVariant: SortVariantType;
  sortChangeHandler: () => void;
};
export const ManagersTableLayout: FC<PropsType & PropsWithChildren> = ({
  children,
  sortChangeHandler,
  sortVariant,
}) => {
  const sortIconVariant = sortVariant === SORT_DIRECTION.Asc ? sortAsc : sortDesc;
  return (
    <div className={s.tableWrapper}>
      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.dateCol}>Дата регистрации</th>
            <th className={s.idCol}>
              ID
              <img className={s.sort_icon} onClick={sortChangeHandler} src={sortIconVariant} alt={'sort'} />
            </th>
            <th className={s.emailCol}>Email</th>
            <th className={s.theamCol}>Команда</th>
            <th className={s.freeCol}>FREE</th>
            <th className={s.paidCol}>Платных</th>
            <th className={s.sumCol}>Сумма</th>
            <th className={s.moreCol}></th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
