import React from 'react';
import { ReactComponent as Location } from '../../../../../../assets/image/sales/landing/cards/location-tick.svg';
import { ReactComponent as Clock } from '../../../../../../assets/image/sales/landing/cards/clock.svg';
import { ReactComponent as Messages } from '../../../../../../assets/image/sales/landing/cards/messages-2.svg';
export const plusesCards = [
  {
    title: 'работай\n в любое время',
    desc: 'Нам не важно, в какое время ты работаешь и сколько времени ты на это тратишь.',
    img: <Clock />,
  },
  {
    title: 'работай\n в любом месте',
    desc: 'Ты можешь работать с любого устройства, главное иметь доступ к сети Интернет.',
    img: <Location />,
  },
  {
    title: 'общайся с командой',
    desc: 'Мы команда, а значит, мы можем общаться, учиться и развиваться вместе!',
    img: <Messages />,
  },
];
