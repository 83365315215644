import { RaffleType } from '../../../API/customersAPI';

export const raffles = (raffle: RaffleType) => {
  const dateNow = new Date();
  const completed: Array<Raffle> = [];
  let active;
  let planned;

  raffle.forEach(draw => {
    if (draw.end_timestamp)
      if (new Date(draw.end_timestamp) <= dateNow) {
        completed.push(draw);
      }
    if (draw.begin_timestamp && draw.end_timestamp) {
      if (new Date(draw.begin_timestamp) <= dateNow && new Date(draw.end_timestamp) > dateNow) {
        active = draw;
      } else if (new Date(draw.begin_timestamp) >= dateNow && new Date(draw.end_timestamp) > dateNow) {
        planned = draw;
      }
    }
  });
  if (completed.length > 0) {
    completed?.slice(completed.length - 4, completed.length);
  }

  return {
    completed,
    active,
    planned,
  };
};

export type Raffle = {
  fileName: string | null;
  id: number | null;
  customer_id: number | null;
  bot_id: number | null;
  prizes: string[] | null;
  description: string | null;
  sponsors: string[] | null;
  begin_timestamp: Date | null;
  end_timestamp: Date | null;
  header: string | null;
  countries: string[] | null;
};
