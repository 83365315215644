import React, { FC, PropsWithChildren } from 'react';
import s from '../../styles/CommonStyles.module.scss';

export const StatsWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={s.statsBlock}>
      <div className={s.statsWrapper}>{children}</div>
    </div>
  );
};
