import axios from 'axios';

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_TELEGIV_API}/raffle/admin/`,
});

instance.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  return config;
});

export const adminDrawsAPI = {
  getDraws(params: GetRafflesQueryParams) {
    return instance.get<AdminRafflesType>('raffles', { params });
  },
  hideDraw(params: EditRaffleParams, raffleId: number) {
    return instance.put(`${raffleId}`, params);
  },
};

export type EditRaffleParams = {
  editableDescription?: string;
  editableHeader?: string;
  isHidden?: boolean;
};
export type AdminRafflesType = {
  totalCount: number;
  pagesCount: number;
  page: number;
  size: number;
  items: RaffleType[];
};
export interface AdminRaffleWinner {
  place: number;
  prize: string;
  telegramName: string;
  timeEndRaffle: string;
}
export type RaffleType = {
  id: number;
  customer_id: number;
  bot_id: number;
  prizes: string[];
  description: string;
  sponsors: string[];
  begin_timestamp: string;
  end_timestamp: string;
  header: string;
  countries: string[];
  fileName: string;
  originalHeader: string;
  winners: Array<AdminRaffleWinner>;
};
export type GetRafflesQueryParams = {
  page?: number;
  pageSize?: number;
  countries?: string;
  sortData?: string;
  title?: string;
  sortDirection?: string;
};
