import React, { FC } from 'react';
import s from '../AdminDrawCard.module.scss';

type PropsType = {
  toEditMode: () => void;
  editMode: boolean;
  onChangeDrawHeader: () => void;
};
export const EditButton: FC<PropsType> = ({ toEditMode, editMode, onChangeDrawHeader }) => {
  return editMode ? (
    <button className={s.drawCardSaveBtn} onClick={onChangeDrawHeader}>
      Сохранить
    </button>
  ) : (
    <button className={s.drawCardEditBtn} onClick={toEditMode}>
      Редактировать
    </button>
  );
};
