import React from 'react';
import { Section } from '../Section';
import { Green, Typography } from '../../../../../../components/typography';
import s from './Faq.module.scss';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../../../components/accordion/Accordion';
import { faqItems } from './faqContent';

export const Faq = () => {
  return (
    <Section dark className={s.faq} id={'faq'}>
      <div className={'container'}>
        <Typography variant={'title_block'} align={'end'}>
          <Green>FAQ</Green>
        </Typography>
        <Typography variant={'title_primary_2'} align={'end'} className={s.subTitle}>
          Тут есть все и даже чуть-чуть больше!
        </Typography>
        <Accordion>
          {faqItems.map((el, index) => {
            return (
              <AccordionItem value={`item-${index + 1}`} key={index}>
                <AccordionTrigger number={index + 1}>{el.title}</AccordionTrigger>
                <AccordionContent button={el.button}>{el.content}</AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </Section>
  );
};
