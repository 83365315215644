import React, { FC, ReactNode, useState } from 'react';
import s from './PopUpSettings.module.scss';
import { Button } from '../../../../../components/button';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../../common/path/puth';
import { Switch } from '../../../../../components/switch/Switch';
import { Typography } from '../../../../../components/typography';
import clsx from 'clsx';
import { IsMediumSizeScreen } from '../../../../../common/utils/isMediumSizeScreen';
import { IsSmallSizeScreen } from '../../../../../common/utils/isSmallSizeScreen';
import { Icon } from '../../../../../ui-kit/components/icons/IconWrapper';
import { ICON } from '../../../../../ui-kit/components/icons/components/Types';

type Props = {
  onClose: () => void;
};
export const PopUpSettings: FC<Props> = ({ onClose }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  let cookieIconSize = 30;
  if (IsMediumSizeScreen()) cookieIconSize = 20;
  if (IsSmallSizeScreen()) cookieIconSize = 16;

  let cancelIconSize = 30;
  if (IsMediumSizeScreen()) cancelIconSize = 20;
  if (IsSmallSizeScreen()) cancelIconSize = 16;
  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.titleContainer}>
          <Icon iconId={ICON.COOKIES} color={'var(--color-violet-4)'} />
          <Typography variant={'title_secondary'} dark className={s.title}>
            настрой <span>Cookies</span>
          </Typography>
          <button onClick={onClose} className={s.closeBtn}>
            <Icon iconId={ICON.CANCEL} />
          </button>
        </div>
        <div className={s.textContainer}>
          <Typography variant={'typeface_main'} dark className={s.text}>
            Мы используем файлы Сookie, чтобы обеспечить вам максимальное удобство. Нажимая «Разрешить все», вы даёте
            нам своё согласие на размещение и использование Сookies в соответствии с&nbsp;
          </Typography>
          <Button as={NavLink} variant={'link'} to={PATH.salesCookie} className={s.cookieLink}>
            Политикой cookie
          </Button>
        </div>
      </div>
      <div className={s.body}>
        <div className={s.toggleField}>
          <Typography variant={'subheader_secondary'} dark bold className={s.subtitle}>
            Основные Cookies
          </Typography>
          <div className={s.line}></div>
          <Button as={NavLink} variant={'link'} to={'#'} className={s.mainLink}>
            Важное
          </Button>
        </div>
        <ToggleField onChange={() => setIsChecked(!isChecked)} checked={isChecked}>
          Маркетинговые Cookies
        </ToggleField>
        <ToggleField onChange={() => setIsChecked1(!isChecked1)} checked={isChecked1}>
          Cookies персонализации
        </ToggleField>
        <ToggleField onChange={() => setIsChecked2(!isChecked2)} checked={isChecked2}>
          Cookies аналитики
        </ToggleField>
      </div>
      <div className={s.btnContainer}>
        <Button variant={'primary'} as={NavLink} to={`/sales/${PATH.registration}`} dark>
          разрешить все
        </Button>
        <Button variant={'secondary'} dark>
          отклонить все
        </Button>
      </div>
    </div>
  );
};

type ToggleFieldProps = {
  onChange: (value: boolean) => void;
  children: ReactNode;
  checked?: boolean;
};

const ToggleField: FC<ToggleFieldProps> = ({ children, onChange, checked }) => {
  return (
    <div className={s.toggleField}>
      <Typography variant={'subheader_secondary'} dark className={clsx(s.subtitle, !checked && s.notActive)}>
        {children}
      </Typography>
      <div className={s.line}></div>
      <Switch onChecked={onChange} checked={checked} />
    </div>
  );
};
