import React, { lazy, Suspense, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PATH } from './common/path/puth';
import { useAppDispatch, useAppSelector } from './common/hooks/storeHooks';
import { LinearProgress } from '@mui/material';
import { EditUser } from './features/admin/editUser/EditUser';
import { PromoCode } from './features/admin/promoCode/PromoCode';
import { AdminLogin } from './features/admin/adminLogin/AdminLogin';
import { AdminUsers } from './features/admin/adminUsers/AdminUsers';
import { NotifySnackbar } from './common/components/errorSnackbar/NotifySnackbar';
import { PrivateRoutesAdmin } from './common/PrivateRoutes/PrivateRoutesAdmin';
import ScrollToTop from 'react-scroll-to-top';
import { arrowStyles } from './assets/styles/ScrollStyles';
import { useWindowDimensions } from './common/utils/useWindowDimensions';
import { getMeCustomer } from './features/customer/customerAuthSlice';
import { SalesRoot } from './features/sales/privateRoutes/SalesRoot';
import { AdminTransfers } from './features/admin/adminTransfers/AdminTransfers';
import { AdminTeam } from './features/admin/adminTeam/AdminTeam';
import { Transfer } from './features/admin/adminTransfers/Transfer/Transfer';
import { AdminManagerPage } from './features/admin/adminManagerPage/AdminManagerPage';
import { AdminManagerAllTeam } from './features/admin/adminManagerPage/adminManagerTeam/AdminManagerAllTeam';
import { AdminManagerAllClients } from './features/admin/adminManagerPage/adminManagerClients/AdminManagerAllClients';
import { useScrollToTop } from './common/hooks/useScrollToTop';
import { PreLoader } from './features/sales/common/components/preLoader/PreLoader';
import { isCustomerInitializedSelector } from './features/customer/customerSelectors';
import { isAppLoadingSelector } from './bll/reducers/appSelectors';
import { EmailConfirmPage } from './features/customer/components/emailConfirmPage/EmailConfirmPage';
import { SalesLanding } from './features/sales/components/salesLanding/SalesLanding';
import { AdminDraws } from './features/admin/adminDraws/AdminDraws';
import { DrawResults } from './features/drawResults/DrawResults';
import { StatLogin } from './features/stat/statLogin/StatLogin';
import { Stat } from './features/stat/Stat';
import { AdminMailingPage } from './features/admin/adminMailing/ui/AdminMailingPage';
import { ErrorFeedbackForm } from './features/feedback';
import * as Sentry from '@sentry/browser';

//dynamic defaults
const Main = lazy(() => import('./features/main/Main'));
const MainDraws = lazy(() => import('./features/mainDraws/MainDraws'));
const CustomerRoot = lazy(() => import('./features/customer/privateRoutes/CustomerRoot'));
const MainFinishDraws = lazy(() => import('./features/mainFinishDraws/MainFinishDraws'));

const WIDTH = 600;

Sentry.init({
  dsn: 'https://7e0ac94559e1011b8eb5f01fa89df929@o4507508980973568.ingest.de.sentry.io/4507508989558864',
  debug: true,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default function App() {
  useScrollToTop();
  const isLoading = useAppSelector(isAppLoadingSelector);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const isCustomerInitialized = useAppSelector(isCustomerInitializedSelector);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const resultWidth = width <= WIDTH;
  const opacityStyle = {
    opacity: 0.5,
  };

  useEffect(() => {
    if (!isCustomerInitialized) {
      dispatch(getMeCustomer())
        .unwrap()
        .then(() => {
          const isUserNotOnConfirmEmailPage = pathname !== PATH.confirmEmail;
          const isUserNotAtSalesPages = !pathname.includes(PATH.salesBaseUrl);
          if (isUserNotOnConfirmEmailPage && isUserNotAtSalesPages) {
            navigate(`${PATH.customerBaseUrl}${PATH.telegiv}`, { replace: true });
          }
        })
        .catch(error => {
          console.warn('Error in App getMeCustomer', error);
        });
    }
  }, [isCustomerInitialized]);

  if (!isCustomerInitialized) return <PreLoader />;

  return (
    <div className='App'>
      <div className='loaderContainer'>
        <LinearProgress
          color={'secondary'}
          sx={{
            visibility: isLoading ? 'visible' : 'hidden',
            display: isLoading ? 'block' : 'none',
            textAlign: 'center',
            position: 'sticky',
            top: 0,
          }}
        />
      </div>
      <Routes>
        <Route
          path={PATH.main}
          element={
            <Suspense fallback={<PreLoader />}>
              <Main />
            </Suspense>
          }
        />

        <Route
          path={PATH.mainDraws}
          element={
            <Suspense fallback={<PreLoader />}>
              <MainDraws />
            </Suspense>
          }
        />
        <Route
          path={PATH.mainFinishDraws}
          element={
            <Suspense fallback={<PreLoader />}>
              <MainFinishDraws />
            </Suspense>
          }
        />
        <Route
          path={PATH.customer}
          element={
            <Suspense fallback={<PreLoader />}>
              <CustomerRoot />
            </Suspense>
          }
        />

        <Route path={`${PATH.result}/:customer_id/:bot_id/:id`} element={<DrawResults />} />
        <Route path={PATH.confirmEmail} element={<EmailConfirmPage />} />
        <Route path={PATH.sales} element={<SalesRoot />} />
        <Route path={'sales/'} element={<SalesLanding />} />
        <Route element={<PrivateRoutesAdmin />}>
          <Route path={PATH.users} element={<AdminUsers />} />
          <Route path={PATH.adminDraws} element={<AdminDraws />} />
          <Route path={PATH.adminMailing} element={<AdminMailingPage />} />
          <Route path={`${PATH.editUser}/:userId`} element={<EditUser />} />
          <Route path={PATH.promoCode} element={<PromoCode />} />
          <Route path={PATH.transfers} element={<AdminTransfers />} />
          <Route path={`${PATH.transfers}/:managerId`} element={<Transfer />} />
          <Route path={PATH.adminTeam} element={<AdminTeam />} />
          <Route path={`${PATH.adminTeam}/:managerId`} element={<AdminManagerPage />} />
          <Route
            path={`${PATH.adminTeam}/:managerId/${PATH.adminManagerClients}`}
            element={<AdminManagerAllClients />}
          />
          <Route path={`${PATH.adminTeam}/:managerId/${PATH.adminManagerTeam}`} element={<AdminManagerAllTeam />} />
        </Route>
        <Route path={PATH.admin} element={<AdminLogin />} />
        <Route path={PATH.statLogin} element={<StatLogin />} />
        <Route path={PATH.stat} element={<Stat />} />
        <Route path={PATH.feedback} element={<ErrorFeedbackForm />} />
      </Routes>
      <NotifySnackbar />
      <ScrollToTop smooth svgPath='none' style={resultWidth ? { ...arrowStyles, ...opacityStyle } : arrowStyles} />
    </div>
  );
}
