import { useFormik } from 'formik';
import React from 'react';
import { TextField } from '@mui/material';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import { CustomButton } from '../../../common/components/customButton/CustomButton';
import { PATH } from '../../../common/path/puth';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../../common/utils/useWindowDimensions';
import s from './PromoCode.module.scss';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';

export const PromoCode = () => {
  const promoCode = useAppSelector(state => state.promo.promoCode);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const formik = useFormik({
    initialValues: {
      promo1k: promoCode[0].name,
      promo3k: promoCode[1].name,
      promo10k: promoCode[2].name,
      promo25k: promoCode[3].name,
      promo100k: promoCode[4].name,
      promoUnlimited: promoCode[5].name,
    },
    enableReinitialize: true,
    onSubmit: values => {},
  });

  const toUserPage = () => navigate(PATH.users);

  const promoCodesInputsList = [
    { label: 'Промо на 1k', formikData: { ...formik.getFieldProps('promo1k') } },
    { label: 'Промо на 3k', formikData: { ...formik.getFieldProps('promo3k') } },
    { label: 'Промо на 10k', formikData: { ...formik.getFieldProps('promo10k') } },
    { label: 'Промо на 25k', formikData: { ...formik.getFieldProps('promo25k') } },
    { label: 'Промо на 100k', formikData: { ...formik.getFieldProps('promo100k') } },
    { label: 'Промо на Безлимит', formikData: { ...formik.getFieldProps('promoUnlimited') } },
  ];
  const mappedPromoCodesInputs = promoCodesInputsList.map(({ label, formikData }) => (
    <TextField
      InputLabelProps={{ style: { fontSize: '15px', transform: 'translate(10px, 5px)' } }}
      key={label}
      fullWidth
      size='small'
      label={label}
      type='text'
      {...formikData}
    />
  ));

  return (
    <>
      <BlockTitle title={'Промокоды'} />
      <form onSubmit={formik.handleSubmit} className={s.promoCodesForm}>
        {mappedPromoCodesInputs}
        <div className={s.buttonContainer}>
          <CustomButton
            title={'Назад'}
            textColor={'white'}
            color={'info'}
            width={width > 585 ? '160' : '290'}
            callback={toUserPage}
          />
          <CustomButton title={'Сохранить'} textColor={'white'} color={'success'} width={width > 585 ? '360' : '290'} />
        </div>
      </form>
    </>
  );
};
