import React, { FC } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { FormikProps } from 'formik';
import { FormikValuesType } from '../../../features/sales/common/types/commonTypes';

type PropsType = {
  name: string;
  placeholder?: string;
  forgotPassLink?: boolean;
  formik: FormikProps<FormikValuesType>;
  inputName: 'password' | 'confirmPassword' | 'oldPassword';
  visibility: 'password' | 'text';
  toggleVisibility: () => void;
  linkTo?: string;
};
export const PasswordInput: FC<PropsType> = ({
  name,
  placeholder,
  forgotPassLink,
  formik,
  inputName,
  visibility,
  toggleVisibility,
  linkTo,
}) => {
  const titleStyle = {
    fontFamily: 'Mulish, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.3px',
    color: '#9FA2B4',
    alignSelf: 'flex-start',
    marginBottom: '6px',
    textDecoration: 'none',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography component={'span'} sx={titleStyle}>
          {name}
        </Typography>
        {forgotPassLink && (
          <Link to={linkTo ? linkTo : '#'} style={titleStyle}>
            Забыли пароль?
          </Link>
        )}
      </Box>
      <TextField
        inputProps={{ style: { fontSize: '16px' } }}
        sx={{ height: '64px', mb: '10px' }}
        size='small'
        type={visibility}
        variant='outlined'
        placeholder={placeholder}
        margin='none'
        {...formik.getFieldProps(inputName)}
        value={formik.values[inputName] ?? ''}
        error={!!(formik.touched[inputName] && formik.errors[inputName])}
        helperText={!!formik.touched[inputName] && formik.errors[inputName]}
        FormHelperTextProps={{ sx: { fontSize: '12px' } }}
        InputProps={{
          endAdornment: (
            <InputAdornment onClick={toggleVisibility} position='end' sx={{ cursor: 'pointer' }}>
              {visibility === 'password' ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
