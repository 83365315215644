import React from 'react';
import styles from './BotMessage.module.scss';
import { TEST_MESSAGE_BOT_LINK, TG_DOT_NAME } from '../../../lib';
export const BotMessage = () => {
  return (
    <>
      <h3>
        Тестовое сообщение было отправлено{' '}
        <a href={TEST_MESSAGE_BOT_LINK} target={'_blank'} className={styles.botLink} rel='noreferrer'>
          {TG_DOT_NAME}
        </a>
      </h3>
      <p>Данное сообщение может быть отправлено реальным пользователям</p>
      <p className={styles.bold}>Ты уверен, что хочешь сделать это?</p>
    </>
  );
};
