import React, { FC } from 'react';
import s from './PendingTransfersTable.module.scss';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../../common/path/puth';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { adminTransfersThunks } from '../../adminTransfersSlice';
import { isSendTransferFetchingSelector } from '../../adminTransfersSelectors';
import { setAppSuccessMessage } from '../../../../../bll/reducers/appReducer';

type PropsType = {
  id: number;
  managerId: number;
  transactionDate: string;
  sum: number;
  tgUsername: string;
  email: string;
  phoneNumber: string;
  teamMembers: number;
  paidClients: number;
};

const TableCell: FC<{ text: string | number }> = ({ text }) => {
  return (
    <div className={s.commonCell}>
      <span>{text}</span>
    </div>
  );
};

export const PendingTransfersTableRow: FC<PropsType> = ({
  id,
  managerId,
  sum,
  tgUsername,
  email,
  phoneNumber,
  teamMembers,
  paidClients,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSendTransferFetching = useAppSelector(isSendTransferFetchingSelector);
  const editUserHandler = () => {
    navigate(`${PATH.transfers}/${managerId}`);
  };

  const cashHandler = async () => {
    await dispatch(adminTransfersThunks.sendTransfer(id))
      .unwrap()
      .then(() => {
        dispatch(setAppSuccessMessage({ successMessage: 'Деньги отправлены' }));
      });
    dispatch(adminTransfersThunks.getTransfers({ status: 'pending', page: '1', size: '10' }));
  };

  return (
    <tr onClick={editUserHandler}>
      <td className={s.id}>
        <TableCell text={managerId} />
      </td>
      <td className={s.email}>
        <TableCell text={email} />
      </td>
      <td className={s.phone}>
        <TableCell text={phoneNumber} />
      </td>
      <td className={s.team}>
        <TableCell text={teamMembers} />
      </td>
      <td className={s.paid}>
        <TableCell text={paidClients} />
      </td>
      <td className={s.sum}>
        <TableCell text={sum} />
      </td>
      <td className={s.telegram} onClick={e => e.stopPropagation()}>
        <div className={s.commonCell}>
          <span>
            <a target={'_blank'} rel={'noreferrer'} href={`https://t.me/${tgUsername}`}>
              {tgUsername}
            </a>
          </span>
        </div>
      </td>
      <td className={s.more}>
        <div>
          <CustomButton
            title={'Подробнее'}
            width={'150'}
            textColor={'white'}
            color={'secondary'}
            callback={editUserHandler}
          />
        </div>
      </td>
      <td className={s.pay} onClick={e => e.stopPropagation()}>
        <div>
          <Button color={'warning'} variant={'contained'} onClick={cashHandler} disabled={isSendTransferFetching}>
            $$
          </Button>
        </div>
      </td>
    </tr>
  );
};
