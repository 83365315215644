import React, { FC } from 'react';
import s from './AdminDrawsCategoryButtons.module.scss';
import { DrawsCategoryType, RAFFLES_STATUSES } from '../../AdminDraws';

type HiddenCategoryButtonType = {
  title: string;
  activeStyle: string;
  category: DrawsCategoryType;
};

type PropsType = {
  drawsCategory: DrawsCategoryType;
  onRaffleCategoryChange: (category: DrawsCategoryType) => void;
};
export const HiddenDrawsCategory: FC<PropsType> = ({ drawsCategory, onRaffleCategoryChange }) => {
  const hiddenDrawsButtons: HiddenCategoryButtonType[] = [
    {
      title: 'Активные скрытые',
      activeStyle: `${drawsCategory === RAFFLES_STATUSES.HiddenActive ? s.activeButton : ''}`,
      category: RAFFLES_STATUSES.HiddenActive,
    },
    {
      title: 'Завершенные скрытые',
      activeStyle: `${drawsCategory === RAFFLES_STATUSES.HiddenEnd ? s.activeButton : ''}`,
      category: RAFFLES_STATUSES.HiddenEnd,
    },
  ];

  const mappedHiddenDrawsButtons = hiddenDrawsButtons.map(({ title, activeStyle, category }, index) => {
    const onClickHandler = () => {
      onRaffleCategoryChange(category);
    };

    return (
      <button key={index} className={`${s.drawsCategoryBtn} ${activeStyle}`} onClick={onClickHandler}>
        {title}
      </button>
    );
  });

  return <div className={s.buttonContainer}>{mappedHiddenDrawsButtons}</div>;
};
