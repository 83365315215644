import { useState } from 'react';

export const usePasswordVisible = () => {
  const [visibility, setVisibility] = useState<'password' | 'text'>('password');
  const toggleVisibility = (): void => {
    setVisibility(visibility === 'password' ? 'text' : 'password');
  };

  return { visibility, toggleVisibility };
};
