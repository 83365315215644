import React, { FC } from 'react';
import { ManagersTableLayout } from './ManagersTableLayout';
import { ManagersTableRow } from './ManagersTableRow';
import { ManagerItemType } from '../../API/adminManagerAPI';
import { SortVariantType } from '../../commonAdminTypes';

type PropsType = {
  managersData: ManagerItemType[];
  sortVariant: SortVariantType;
  sortChangeHandler: () => void;
};

export const ManagersTable: FC<PropsType> = ({ managersData, sortVariant, sortChangeHandler }) => {
  const mappedUsersTableRows = managersData.map(
    ({ id, email, balance, createdAt, team, unpaidClients, paidClients }) => {
      return (
        <ManagersTableRow
          key={id}
          id={id}
          email={email}
          balance={balance}
          createdAt={createdAt}
          team={team}
          unpaidClients={unpaidClients}
          paidClients={paidClients}
        />
      );
    }
  );
  return (
    <ManagersTableLayout sortChangeHandler={sortChangeHandler} sortVariant={sortVariant}>
      {mappedUsersTableRows}
    </ManagersTableLayout>
  );
};
