import s from './StatHeader.module.scss';
import { PATH } from '../../../common/path/puth';
import { MainLogo } from '../../sales/common/components/mainLogo/MainLogo';
import { SelectorBar } from './selectorBar/SelectorBar';

export const StatHeader = () => {
  return (
    <header className={s.headerContainer}>
      <div className={s.headerWrapper}>
        <MainLogo navigateLink={PATH.stat} toggleLogoWidth={768} isDarkTheme={true} />
        <SelectorBar />
      </div>
    </header>
  );
};
