import React from 'react';
import { FormikValues } from 'formik';
import TextField from '@mui/material/TextField';
import styles from './ButtonSettings.module.scss';

type ButtonSettingsPropsModel = {
  formik: FormikValues;
};

export const ButtonSettings = React.memo(({ formik }: ButtonSettingsPropsModel) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonSettingsContainer}>
        <div className={styles.linkInputWithTitleBlock}>
          <div>Ссылка</div>
          <TextField
            id='buttonLink'
            name='buttonLink'
            InputProps={{ sx: { fontSize: '16px' } }}
            FormHelperTextProps={{ sx: { fontSize: '12px' } }}
            {...formik.getFieldProps('buttonLink')}
            error={!!formik.errors.buttonLink && formik.touched.buttonLink}
            helperText={formik.touched.buttonLink && formik.errors.buttonLink}
          />
        </div>
        <div className={styles.buttonTextInputWithTitleBlock}>
          <div>Надпись</div>
          <TextField
            id='buttonText'
            name='buttonText'
            InputProps={{ sx: { fontSize: '16px' } }}
            FormHelperTextProps={{ sx: { fontSize: '12px' } }}
            {...formik.getFieldProps('buttonText')}
            error={!!formik.errors.buttonText && formik.touched.buttonText}
            helperText={formik.touched.buttonText && formik.errors.buttonText}
          />
        </div>
      </div>
    </div>
  );
});
