import React from 'react';
import { StatsItem } from '../../../sales/common/components/managerStats/StatsItem';
import { StatsWrapper } from '../../../sales/common/components/managerStats/StatsWrapper';
import { useAppSelector } from '../../../../common/hooks/storeHooks';
import { managerClientsStatsSelector } from '../../adminManagerSelectors';

export const AdminManagerClientsCount = () => {
  const managerClientsStats = useAppSelector(managerClientsStatsSelector);

  return (
    <StatsWrapper>
      <StatsItem title={'Клиенты'} value={managerClientsStats.totalClients} headingTitle />
      <StatsItem title={'Платные'} value={managerClientsStats.paidClients} />
      <StatsItem title={'Бесплатные'} value={managerClientsStats.freeClients} />
    </StatsWrapper>
  );
};
