import st from './SmallPopUpCookie.module.scss';
import { IconClose } from '../icon-close';
import { ButtonUiKit } from '../../button/ButtonUiKit';
import { TypographyUiKit } from '../../typography';
import {
  TEXT_BUTTON_SMALL_POPUP_COOKIE,
  TEXT_lINK_SMALL_POPUP_COOKIE,
  TEXT_SMALL_POPUP_COOKIE,
} from '../constantsElementsCookies';
import { PATH } from '../../../../common/path/puth';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const SmallPopUpCookie = () => {
  const navigate = useNavigate();

  const [cookieConsentValue, setCookieConsentValue] = useState(false);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    const storedConsentValue = localStorage.getItem('cookieConsentTelegiv2');
    setCookieConsentValue(storedConsentValue === 'true');
  }, []);

  const onClickIconClose = () => {
    setIsPopUpOpen(true);
  };

  const handleOnClickPageChange = () => {
    navigate(`/${PATH.salesBaseUrl}/${PATH.salesCookie}`);
  };

  const handleOnClickButton = () => {
    setCookieConsentValue(true);
    localStorage.setItem('cookieConsentTelegiv2', 'true');
  };

  if (cookieConsentValue) {
    return null;
  }

  if (isPopUpOpen) {
    return null;
  }

  return (
    <div className={st.main}>
      <div className={st.common}>
        <div className={st.blockText}>
          <TypographyUiKit colorText={'var(--color-text-black)'} variant={'typefaceMain'}>
            {TEXT_SMALL_POPUP_COOKIE}
          </TypographyUiKit>
          <TypographyUiKit
            colorText={'var(--color-violet-4)'}
            variant={'buttonS'}
            onClick={handleOnClickPageChange}
            className={st.linkCookei}
          >
            {TEXT_lINK_SMALL_POPUP_COOKIE}
          </TypographyUiKit>
        </div>
        <div className={st.blockButtonAndIcon}>
          <ButtonUiKit onClick={handleOnClickButton} className={st.buttonUiKit}>
            <p className={st.buttonText}>{TEXT_BUTTON_SMALL_POPUP_COOKIE}</p>
          </ButtonUiKit>
          <div className={st.iconClose}>
            <IconClose handleOnClickIconClose={onClickIconClose} sizeIconClose='big' />
          </div>
        </div>
      </div>
    </div>
  );
};
