import s from './Header.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Button, VariantType } from '../button';
import React, { ComponentProps, FC } from 'react';
import { IsSmallSizeScreen } from '../../common/utils/isSmallSizeScreen';
import { PATH } from '../../common/path/puth';
import { IsMediumSizeScreen } from '../../common/utils/isMediumSizeScreen';
import { Notifications, UserControl } from './UserControl/UserControl';
import { LogoAnimation } from '../../ui-kit/components/logo-animation';

type HeaderProps = {
  dark?: boolean;
  isNav?: boolean;
  isAuth?: boolean;
};

export const Header: FC<HeaderProps> = ({ dark, isNav = false, isAuth = false }) => {
  const navigate = useNavigate();

  const onClickLogoAnimation = () => {
    navigate('/sales');
  };

  return (
    <div className={s.root}>
      <div className={clsx(s.headerContainer, 'container')}>
        <LogoAnimation onClickLogoAnimation={onClickLogoAnimation} dark={!!dark} />
        <div className={s.btnContainer}>
          {!isNav && <SalesManagerControls />}
          {isNav && <>{isAuth ? <AuthUserControls dark={dark} /> : <NotAuthUserControls dark={dark} />}</>}
        </div>
      </div>
    </div>
  );
};

type AuthUserControlsType = {
  dark?: boolean;
};
const AuthUserControls: FC<AuthUserControlsType> = ({ dark }) => {
  return (
    <>
      {!IsMediumSizeScreen() && <UserControl name={'Nick'} dark={dark} />}
      <Notifications isActive={true} dark={dark} />
    </>
  );
};

const NotAuthUserControls: FC<AuthUserControlsType> = ({ dark }) => {
  return (
    <>
      {!IsSmallSizeScreen() && <SignUpButton dark={dark} />}
      <LoginButton dark={dark} isNav />
    </>
  );
};

const SalesManagerControls: FC<AuthUserControlsType> = ({ dark }) => {
  return (
    <>
      {!IsSmallSizeScreen() && <SignUpButton dark={dark} />}
      <LoginButton dark={dark} />
    </>
  );
};

type ButtonProps = {
  dark?: boolean;
  isNav?: boolean;
  isOpen?: boolean;
} & ComponentProps<'button'>;

export const SignUpButton: FC<ButtonProps> = ({ dark }) => {
  return (
    <Button dark={dark} size={'medium'} nonAdapt as={NavLink} to={`/sales/${PATH.registration}`}>
      регистрация
    </Button>
  );
};

export const LoginButton: FC<ButtonProps> = ({ dark, isNav = false }) => {
  let variant = 'secondary' as VariantType;
  if (!isNav && IsSmallSizeScreen()) variant = 'primary';
  if (isNav && IsMediumSizeScreen()) variant = 'secondary';

  return (
    <Button variant={variant} dark={dark} size={'medium'} nonAdapt as={NavLink} to={PATH.salesLogin}>
      войти
    </Button>
  );
};
