import React from 'react';
import { Team } from '../../../sales/common/components/team/Team';
import { useAppSelector } from '../../../../common/hooks/storeHooks';
import { getManagerTeamSelector } from '../../adminManagerSelectors';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from '../../../../common/path/puth';

export const AdminManagerTeam = () => {
  const { managerId } = useParams();
  const team = useAppSelector(getManagerTeamSelector);
  const navigate = useNavigate();
  const toAllTeamPage = () => navigate(`${PATH.adminTeam}/${managerId}/${PATH.adminManagerTeam}`);

  return <Team team={team} btnAction={toAllTeamPage} variant={'admin'} />;
};
