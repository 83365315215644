import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalysisCommonState, AnalysisItem, GetFiltrationResponse, SortData } from './common/types/types';
import { apiAnalysis } from './API/analysisAPI';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { getRaffleByIdCommon } from '../customer/drawSliceCommon';
import { RaffleTypeById } from '../customer/API/customersAPI';

const getChannelsTotal = createAsyncThunk<AnalysisCommonState, GetFiltrationResponse>(
  'analysis/getChannelsTotal',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const queryParams = Object.fromEntries(Object.entries(arg).filter(([, value]) => value && value !== '0'));
      const res = await apiAnalysis.getTotalChannels(queryParams);
      return res.data;
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

const initialState: AnalysisCommonState = {
  totalCount: 0,
  pagesCount: 0,
  page: 1,
  size: 0,
  items: [] as AnalysisItem[],
  loading: false,
  sortData: {
    field: '',
    sortDir: '',
  },
  countries: '',
  priceBefore: '',
  cpaAt: '',
  priceAt: '',
  dataForPopUp: {
    fileName: null,
    id: null,
    customer_id: null,
    bot_id: null,
    header: null,
    description: null,
    prizes: null,
    sponsors: null,
    begin_timestamp: null,
    end_timestamp: null,
    countries: [],
  } as RaffleTypeById,
};

export const paidChannelsSlice = createSlice({
  name: 'analysis/commonData',
  initialState: initialState,
  reducers: {
    setSortData: (state, action: PayloadAction<SortData>) => {
      state.sortData = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getChannelsTotal.pending, state => {
        state.loading = true;
      })
      .addCase(getChannelsTotal.fulfilled, (state, action) => {
        const { totalCount, pagesCount, page, size, items, dataForPopUp } = action.payload;
        state.totalCount = totalCount;
        state.pagesCount = pagesCount;
        state.page = page;
        state.size = size;
        state.items = items;
        state.loading = false;
        state.dataForPopUp = dataForPopUp;
      })
      .addCase(getRaffleByIdCommon.fulfilled, (state, action) => {
        state.dataForPopUp = action.payload;
      });
  },
});

export const { setSortData } = paidChannelsSlice.actions;

export const commonDataThunk = { getChannelsTotal, getDataForPopUp: getRaffleByIdCommon };
