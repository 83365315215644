export const SCREEN_SIZE_SMALL = 360;
export const SCREEN_SIZE_AVERAGE = 768;
export const SCREEN_SIZE_BIG = 1440;

export const ICON_SIZE_SIXTEEN = '16';
export const ICON_SIZE_SEVENTEEN = '17';
export const ICON_SIZE_EIGHTEEN = '18';
export const ICON_SIZE_TWENTY = '20';
export const ICON_SIZE_TWENTY_FOUR = '24';
export const ICON_SIZE_TWENTY_SIX = '26';
export const ICON_SIZE_TWENTY_EIGHT = '28';
export const MIN_LETTER_LENGTH = 0;
export const MAX_LETTER_LENGTH = 500;
export const MAX_FILE_SIZE_FIVE_MB = 5 * 1024 * 1024;
