import React, { FC } from 'react';
import * as S from '@radix-ui/react-switch';
import s from './Switch.module.scss';

type Props = {
  onChecked: (value: boolean) => void;
  checked?: boolean;
};
export const Switch: FC<Props> = ({ onChecked, checked }) => (
  <form>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <S.Root className={s.root} id='airplane-mode' onCheckedChange={onChecked} checked={checked}>
        <S.Thumb className={s.thumb} />
      </S.Root>
    </div>
  </form>
);
