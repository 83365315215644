import React, { FC } from 'react';
import s from './CompletedTransfersTable.module.scss';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../../common/path/puth';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';

type PropsType = {
  managerId: number;
  transactionDate: string;
  sum: number;
  tgUsername: string;
};

const TableCell: FC<{ text: string | number }> = ({ text }) => {
  return (
    <div className={s.commonCell}>
      <span>{text}</span>
    </div>
  );
};

export const CompletedTransfersTableRow: FC<PropsType> = ({ managerId, transactionDate, sum, tgUsername }) => {
  const navigate = useNavigate();
  const editUserHandler = () => {
    navigate(`${PATH.transfers}/${managerId}`);
  };

  return (
    <tr onClick={editUserHandler}>
      <td className={s.date}>
        <TableCell text={transactionDate} />
      </td>
      <td className={s.sum}>
        <TableCell text={sum} />
      </td>
      <td className={s.id}>
        <TableCell text={managerId} />
      </td>
      <td className={s.telegram} onClick={e => e.stopPropagation()}>
        <div className={s.commonCell}>
          <span>
            <a target={'_blank'} rel={'noreferrer'} href={`https://t.me/${tgUsername}`}>
              {tgUsername}
            </a>
          </span>
        </div>
      </td>
      <td className={s.edit}>
        <div>
          <CustomButton
            title={'Подробнее'}
            width={'150'}
            textColor={'white'}
            color={'secondary'}
            callback={editUserHandler}
          />
        </div>
      </td>
    </tr>
  );
};
