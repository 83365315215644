import React, { useState } from 'react';
import s from './AdminManagerBalance.module.scss';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { managerBalanceSelector, withdrawIdSelector } from '../../../adminManagerSelectors';
import { TransferConfirm } from './TransferConfirm';
import { BlockTitle } from '../../../../sales/common/components/blockTitle/BlockTitle';

export const AdminManagerBalance = () => {
  const managerBalance = useAppSelector(managerBalanceSelector);
  const [transferSteps, setTransferSteps] = useState<TransferSteps>('cancel');
  const withdrawId = useAppSelector(withdrawIdSelector);
  const toConfirmStep = () => setTransferSteps('confirm');

  return (
    <div className={s.balanceCard}>
      <BlockTitle title={'Баланс'} />
      <span className={s.balance}>{managerBalance}$</span>
      {transferSteps === 'cancel' ? (
        <CustomButton
          title={'Обнулить баланс'}
          textColor={'white'}
          color={'primary'}
          width={'196'}
          callback={toConfirmStep}
          disable={!withdrawId}
        />
      ) : (
        <TransferConfirm setTransferSteps={setTransferSteps} />
      )}
    </div>
  );
};

export type TransferSteps = 'confirm' | 'cancel';
