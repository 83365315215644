import React, { useEffect } from 'react';
import { SalesPagination } from '../../../common/components/pagination/Pagination';
import { TeamTableLayout } from './teamTable/TeamTableLayout';
import { TeamTableRow } from './teamTable/TeamTableRow';
import { serverDateFormat } from '../../../common/utils/serverDateFormat';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { getTeamSelector, isFetchingSelector, teamPagesCountSelector } from '../../../managerPublicSelectors';
import { publicManagerThunks } from '../../../publicManagerSlice';
import { PreLoader } from '../../../common/components/preLoader/PreLoader';
import { useCustomSearchParams } from '../../../../../common/hooks/useCustomSearchParams';
import { BlockTitle } from '../../../common/components/blockTitle/BlockTitle';

type SalesAllTeamParams = 'page' | 'size';
export const AllTeam = () => {
  const dispatch = useAppDispatch();
  const pagesCount = useAppSelector(teamPagesCountSelector);
  const team = useAppSelector(getTeamSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const [searchParams, setSearchParams] = useCustomSearchParams<SalesAllTeamParams>();

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';

  useEffect(() => {
    dispatch(publicManagerThunks.getTeam({ page: currentPage, size: pageSize }));
  }, [currentPage]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const teamTableRows = team.map(({ id, profit, createdAt, email, clientsCount, tg_userName }) => {
    const regDate = serverDateFormat(createdAt);
    return (
      <TeamTableRow
        key={id}
        clients={clientsCount}
        email={email}
        telegram={tg_userName}
        income={profit}
        regDate={regDate}
      />
    );
  });
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <BlockTitle title={'Команда'} />
      {isFetching ? (
        <PreLoader />
      ) : (
        <>
          <TeamTableLayout>{teamTableRows}</TeamTableLayout>
          <SalesPagination page={currentPage} onChange={onChangePage} pagesCount={pagesCount} disabled={isFetching} />
        </>
      )}
    </div>
  );
};
