import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SalesButtonGroup } from './SalesButtonGroup';
import { InOutButton } from '../../../../../common/components/inOutButton/InOutButton';
import { salesAuthThunks } from '../../../salesAuthSlice';
import { useAppDispatch } from '../../../../../common/hooks/storeHooks';
import { HeaderBurger } from './HeaderBurger';
import { useWindowDimensions } from '../../../../../common/utils/useWindowDimensions';
import { salesHeaderMenuItems } from './schemas';

export const HeaderMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const SHOW_BURGER_WIDTH = 500;
  const isBurgerShow = width <= SHOW_BURGER_WIDTH;
  const handleLogout = () => dispatch(salesAuthThunks.logout());

  return (
    <div style={{ display: 'flex', gap: '40px' }}>
      {isBurgerShow ? (
        <HeaderBurger handleLogout={handleLogout} menuItems={salesHeaderMenuItems} />
      ) : (
        <>
          <SalesButtonGroup menuItems={salesHeaderMenuItems} />
          <InOutButton callback={handleLogout} btnTitle={'Выход'} />
        </>
      )}
    </div>
  );
};
