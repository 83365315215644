import React from 'react';
import { FormikValues } from 'formik';
import TextField from '@mui/material/TextField';
import styles from './PasswordForm.module.scss';

type PasswordFormPropsModel = {
  formik: FormikValues;
};

export const AdminMailingPasswordForm = React.memo(({ formik }: PasswordFormPropsModel) => {
  return (
    <div className={styles.container}>
      <div className={styles.passwordInputWithTitleBlock}>
        <h3 className={styles.formTitle}>Пароль</h3>
        <TextField
          type={'number'}
          sx={{
            '& fieldset': { border: 'none' },
          }}
          className={styles.formInput}
          id='code'
          name='code'
          InputProps={{ sx: { fontSize: '16px' } }}
          FormHelperTextProps={{ sx: { fontSize: '12px' } }}
          {...formik.getFieldProps('code')}
          error={!!formik.errors.code && formik.touched.code}
          helperText={formik.touched.code && formik.errors.code}
        />
      </div>
    </div>
  );
});
