import React from 'react';
import { MainLogo } from '../mainLogo/MainLogo';
import s from './SalesHeader.module.scss';
import { PATH } from '../../../../../common/path/puth';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { isAuthorizedSelector } from '../../../salesAuthSelectors';
import { HeaderMenu } from './HeaderMenu';

export const SalesHeader = () => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);

  return (
    <div className={s.headerBackground}>
      <div className={s.headerContainer}>
        <div className={s.headerWrapper}>
          {isAuthorized ? (
            <>
              <MainLogo navigateLink={PATH.salesHome} toggleLogoWidth={775} />
              <HeaderMenu />
            </>
          ) : (
            <MainLogo navigateLink={`/${PATH.salesBaseUrl}${PATH.main}`} toggleLogoWidth={775} />
          )}
        </div>
      </div>
    </div>
  );
};
