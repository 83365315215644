import s from './../SelectorBar.module.scss';
import Menu from '@mui/material/Menu';
import { Button, Card, CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { Icon } from '../../../../../ui-kit/components/icons/IconWrapper';
import { Typography } from '../../../../../components/typography';
import { FieldDate } from './fieldDate/FieldDate';
import { Dayjs } from 'dayjs';
import { RangeCalendar } from './rangeCalendar/RangeCalendar';
import { MarkerDate, VariantCalendar } from '../../../types';
import { fillDateFields } from '../../../utils/fillDateFields';

export const PeriodMenu = () => {
  const defaultDiff = 86400000 * 3; // 3 дня
  const [marker, setMarker] = useState(MarkerDate.startFirst);
  const [startDayFirst, setStartDayFirst] = useState<null | Dayjs>(null);
  const [endDayFirst, setEndDayFirst] = useState<null | Dayjs>(null);
  const [startDaySecond, setStartDaySecond] = useState<null | Dayjs>(null);
  const [endDaySecond, setEndDaySecond] = useState<null | Dayjs>(null);
  const [diff, setDiff] = useState(defaultDiff);
  const [toggleBlur, setToggleBlur] = useState(false);

  const [isCalendarFirst, setIsCalendarFirst] = useState(false);
  const [isCalendarSecond, setIsCalendarSecond] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const cls = {
    card: {
      background: open ? 'var(--color-violet-3-stat)' : 'var(--color-black-11)',
      borderRadius: '2rem',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--color-black-12)',
      },
    },
    cardContent: {
      width: '7.2rem',
      padding: 0,
      height: '100%',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    clock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: 0,
    },
    menu: { '& .MuiMenu-paper': { borderRadius: '2rem', background: 'transparent' } },
    menuList: {
      padding: 0,
      borderRadius: 0,
      background: 'var(--color-black-15)',
    },
    menuDateContainer: {
      fontSize: 'var(--font-size-m)',
      padding: '0.2rem',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '2.4rem',
      color: 'var(--color-white-3)',
      background: 'transparent',
    },
    menuDateContent: {
      height: '100%',
      '&:last-child': { padding: '1.2rem 0' },
    },
    button: {
      color: 'var(--color-violet-1-stat)',
      fontSize: 'var(--font-size-s)',
      fontWeight: 'var(--font-weight-medium)',
      padding: '0.8rem 1.15rem',
      zIndex: 10000,
      textTransform: 'none',
      '&:hover': { background: 'none', transform: 'scale(1.15)' },
    },
  };

  useEffect(() => {
    const dateFields = fillDateFields({
      startDayFirst,
      endDayFirst,
      startDaySecond,
      endDaySecond,
      diff,
      marker,
    });
    const { startFirst, endFirst, startSecond, endSecond, diffPeriod } = dateFields;

    setStartDayFirst(startFirst);
    setEndDayFirst(endFirst);
    setStartDaySecond(startSecond);
    setEndDaySecond(endSecond);
    setDiff(diffPeriod);
  }, [toggleBlur]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenCalendar = (variant: VariantCalendar) => {
    if (variant === VariantCalendar.first) setIsCalendarFirst(true);
    else setIsCalendarSecond(true);
  };

  const handleBackBtn = () => {
    if (isCalendarFirst) {
      setIsCalendarFirst(false);
    } else if (isCalendarSecond) {
      setIsCalendarSecond(false);
    } else setAnchorEl(null);
    setToggleBlur(!toggleBlur);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={cls.card}>
      <CardContent sx={cls.cardContent}>
        <div style={cls.clock} onClick={handleClickListItem}>
          <Icon iconId='clock' color='var(--color-white-3)' />
        </div>
        <Menu
          id='lock-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={cls.menu}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
            sx: cls.menuList,
          }}
        >
          <Card className={s.menuDateContainer} sx={cls.menuDateContainer}>
            <CardContent sx={cls.menuDateContent}>
              <div className={s.fieldsContainer}>
                <Typography variant={'small_text'} mb={'2rem'} mx={'0.6rem'} mt={'0.8rem'}>
                  Выбор дат для сравнения
                </Typography>

                <div className={s.periodContainer}>
                  <div className={s.titleAndCalendar}>
                    <Typography variant={'subheader_primary'} mb={'1.8rem'}>
                      Период 1
                    </Typography>
                    <Icon
                      iconId='calendar-stat-page'
                      color='var(--color-white-3)'
                      onClick={() => handleOpenCalendar(VariantCalendar.first)}
                    />
                  </div>

                  <div className={s.dateFields}>
                    <FieldDate
                      label='Начало'
                      name={MarkerDate.startFirst}
                      value={startDayFirst}
                      setValue={setStartDayFirst}
                      setMarker={setMarker}
                      setToggleBlur={setToggleBlur}
                      toggleBlur={toggleBlur}
                    />
                    <FieldDate
                      label='Конец'
                      name={MarkerDate.endFirst}
                      value={endDayFirst}
                      setValue={setEndDayFirst}
                      setMarker={setMarker}
                      setToggleBlur={setToggleBlur}
                      toggleBlur={toggleBlur}
                    />
                  </div>
                </div>

                <div className={`${s.periodContainer} ${s.secondPeriod}`}>
                  <div className={s.titleAndCalendar}>
                    <Typography variant={'subheader_primary'} mb={'1.8rem'}>
                      Период 2
                    </Typography>
                    <Icon
                      iconId='calendar-stat-page'
                      color='var(--color-white-3)'
                      onClick={() => handleOpenCalendar(VariantCalendar.second)}
                    />
                  </div>
                  <div className={s.dateFields}>
                    <FieldDate
                      label='Начало'
                      name={MarkerDate.startSecond}
                      value={startDaySecond}
                      setValue={setStartDaySecond}
                      setMarker={setMarker}
                      setToggleBlur={setToggleBlur}
                      toggleBlur={toggleBlur}
                    />
                    <FieldDate
                      label='Конец'
                      name={MarkerDate.endSecond}
                      value={endDaySecond}
                      setValue={setEndDaySecond}
                      setMarker={setMarker}
                      setToggleBlur={setToggleBlur}
                      toggleBlur={toggleBlur}
                    />
                  </div>
                </div>
              </div>

              <RangeCalendar
                isActive={isCalendarFirst}
                startDateValue={startDayFirst}
                endDateValue={endDayFirst}
                setStartDay={setStartDayFirst}
                setEndDay={setEndDayFirst}
                setDiff={setDiff}
              />

              <RangeCalendar
                isActive={isCalendarSecond}
                startDateValue={startDaySecond}
                endDateValue={endDaySecond}
                setStartDay={setStartDaySecond}
                setEndDay={setEndDaySecond}
                setDiff={setDiff}
              />

              <div className={s.buttonsContent}>
                <Button sx={cls.button} onClick={handleBackBtn}>
                  Назад
                </Button>
                <Button sx={cls.button}>Далее</Button>
              </div>
            </CardContent>
          </Card>
        </Menu>
      </CardContent>
    </Card>
  );
};
