import { Data, InfoWidgetsData, StatisticWidgetsData } from './types';

export const rows: Data[] = [
  { id: 1, ignored: 11, blocked: 4, returned: 6, reached: 1 },
  { id: 2, ignored: 108, blocked: 22, returned: 56, reached: 14 },
  { id: 3, ignored: 187, blocked: 102, returned: 37, reached: 11 },
  { id: 4, ignored: 122, blocked: 44, returned: 23, reached: 67 },
  { id: 5, ignored: 18, blocked: 2, returned: 1, reached: 5 },
  { id: 6, ignored: 8, blocked: 6, returned: 2, reached: 2 },
  { id: 7, ignored: 76, blocked: 29, returned: 12, reached: 19 },
  { id: 8, ignored: 101, blocked: 49, returned: 41, reached: 31 },
  { id: 9, ignored: 122, blocked: 44, returned: 23, reached: 67 },
  { id: 10, ignored: 18, blocked: 2, returned: 1, reached: 5 },
  { id: 11, ignored: 8, blocked: 6, returned: 2, reached: 2 },
  { id: 12, ignored: 76, blocked: 29, returned: 12, reached: 19 },
  { id: 13, ignored: 101, blocked: 49, returned: 41, reached: 31 },
  { id: 14, ignored: 122, blocked: 44, returned: 23, reached: 67 },
  { id: 15, ignored: 18, blocked: 2, returned: 1, reached: 5 },
  { id: 16, ignored: 8, blocked: 6, returned: 2, reached: 2 },
  { id: 17, ignored: 76, blocked: 29, returned: 12, reached: 19 },
  { id: 18, ignored: 154, blocked: 49, returned: 41, reached: 31 },
  { id: 19, ignored: 456, blocked: 139, returned: 81, reached: 39 },
  { id: 20, ignored: 234, blocked: 149, returned: 23, reached: 22 },
  { id: 21, ignored: 155, blocked: 69, returned: 49, reached: 11 },
];

export const InfoWidgets: InfoWidgetsData = {
  totalUsers: {
    count: '40.839',
    increasedByPercentage: '+70',
    title: 'Пользователи',
  },
  activeUsers: {
    count: '29.330',
    increasedByPercentage: '-20',
    title: 'Активных',
  },
  blockedUsers: {
    count: '12.654',
    increasedByPercentage: '+20.6',
    title: 'Заблокировали',
  },
  nonSubscribersUsers: {
    count: '2.654',
    increasedByPercentage: '-20',
    title: 'Без подписки',
  },
  nonRegisteredUsers: {
    count: '2.654',
    increasedByPercentage: '-20',
    title: 'Без регистрации',
  },
  virality: {
    count: '760',
    increasedByPercentage: '+170',
    title: 'Виральность',
  },
};

export const StatisticWidgetsZERO = {
  belarus: '40.00',
  rus: '3.00',
  ukr: '33.00',
  kz: '1.00',
  uzb: '14.00',
  other_country: '9.00',
  male: '86',
  female: '14',
  age_18: '6.00',
  age_18_24: '45.00',
  age_25_34: '33.00',
  age_35_44: '23.00',
  age_45: '7.00',
};

export const StatisticWidgets: StatisticWidgetsData = {
  country: {
    belarus: '40.00',
    rus: '3.00',
    ukr: '33.00',
    kz: '1.00',
    uzb: '14.00',
    other_country: '9.00',
  },
  gender: {
    male: '86',
    female: '14',
  },
  age: {
    age_18: '6.00',
    age_18_24: '45.00',
    age_25_34: '33.00',
    age_35_44: '23.00',
    age_45: '7.00',
  },
};
export const StatisticWidgetsTWO = {
  country: [
    { belarus: '40.00' },
    { rus: '3.00' },
    { ukr: '33.00' },
    { kz: '1.00' },
    { uzb: '14.00' },
    { other_country: '9.00' },
  ],
  gender: [{ male: '86' }, { female: '14' }],
  age: [{ age_18: '6.00' }, { age_18_24: '45.00' }, { age_25_34: '33.00' }, { age_35_44: '23.00' }, { age_45: '7.00' }],
};
export const StatisticWidgetsTHREE = {
  country: [
    { belarus: { title: 'Беларусь', value: '25.00' } },
    { rus: { title: 'Россия', value: '10.00' } },
    { ukr: { title: 'Украина', value: '30.00' } },
    { kz: { title: 'Казахстан', value: '15.00' } },
    { uzb: { title: 'Узбекистан', value: '8.00' } },
    { other_country: { title: 'Другие', value: '12.00' } },
  ],
  gender: [{ male: '86' }, { female: '14' }],
  age: [
    { age_18: { title: 'до 18', value: '6.00' } },
    { age_18_24: { title: '18-24', value: '45.00' } },
    { age_25_34: { title: '25-34', value: '33.00' } },
    { age_35_44: { title: '35-44', value: '23.00' } },
    { age_45: { title: 'Беларусь', value: '7.00' } },
  ],
};

export const StatisticItemsTitle: StatisticWidgetsData = {
  age: {
    age_18: 'до 18',
    age_18_24: '18-24',
    age_25_34: '25-34',
    age_35_44: '35-44',
    age_45: '45+',
  },
  gender: {
    male: 'Мужчины',
    female: 'Женщины',
  },

  country: {
    belarus: 'Беларусь',
    rus: 'Россия',
    ukr: 'Украина',
    kz: 'Казахстан',
    uzb: 'Узбекистан',
    other_country: 'Другие',
  },
};
