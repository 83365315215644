import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { useParams } from 'react-router-dom';
import { HistoryCategoryType } from '../../../../sales/components/salesBalance/salesBalanceApi';
import { adminTransfersActions, adminTransfersThunks } from '../../adminTransfersSlice';
import { adminHistoryDataSelector } from '../../adminTransfersSelectors';
import { HistoryButtonsType } from '../../../../sales/common/types/commonTypes';
import { ManagerHistoryTable } from '../../../../sales/common/components/managerHistoryTable/ManagerHistoryTable';
import { useCustomSearchParams } from '../../../../../common/hooks/useCustomSearchParams';

type AdminTransferHistoryParams = 'page' | 'size';

export const AdminTransferHistory = () => {
  const dispatch = useAppDispatch();
  const historyData = useAppSelector(adminHistoryDataSelector);

  const [historyCategory, setHistoryCategory] = useState<HistoryCategoryType>('mySales');
  const { managerId } = useParams();

  const [searchParams, setSearchParams] = useCustomSearchParams<AdminTransferHistoryParams>();

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';

  const onPageChange = (page: string) => {
    setSearchParams({ page: page });
  };

  useEffect(() => {
    dispatch(adminTransfersActions.setEmptyHistoryData());
    if (managerId) {
      dispatch(
        adminTransfersThunks.getHistory({
          arg: { historyCategory: historyCategory, page: +currentPage, size: +pageSize },
          id: +managerId,
        })
      );
    }
  }, [dispatch, historyCategory, currentPage]);

  const historyButtons: HistoryButtonsType[] = [
    {
      title: 'Продажи',
      className: historyCategory === 'mySales' ? 'headerButtonsActive' : '',
      type: 'mySales',
    },
    {
      title: 'Продажи менеджеров',
      className: historyCategory === 'teamSales' ? 'headerButtonsActive' : '',
      type: 'teamSales',
    },
    {
      title: 'Заблокированные выплаты',
      className: historyCategory === 'withoutProfitSales' ? 'headerButtonsActive' : '',
      type: 'withoutProfitSales',
    },
    {
      title: 'Выплаты',
      className: historyCategory === 'withdrawal' ? 'headerButtonsActive' : '',
      type: 'withdrawal',
    },
  ];

  return (
    <ManagerHistoryTable
      historyCategory={historyCategory}
      onPageChange={onPageChange}
      currentPage={currentPage}
      historyData={historyData}
      historyButtons={historyButtons}
      setSearchParams={setSearchParams}
      setHistoryCategory={setHistoryCategory}
    />
  );
};
