import s from './Footer.module.scss';
import clsx from 'clsx';
import { Typography } from '../typography';
import React, { FC, useState } from 'react';
import { Button } from '../button';
import { NavLink } from 'react-router-dom';
import { IsMediumSizeScreen } from '../../common/utils/isMediumSizeScreen';
import { IsSmallSizeScreen } from '../../common/utils/isSmallSizeScreen';
import { PopUpSettings } from '../../features/sales/components/salesLanding/popUpSettings/PopUpSettings';
import { Modal } from '../modal/Modal';
import { PATH } from '../../common/path/puth';
import { Icon } from '../../ui-kit/components/icons/IconWrapper';
import { ICON } from '../../ui-kit/components/icons/components/Types';

export const Footer: FC = () => {
  const iconSize = IsMediumSizeScreen() ? 32 : 28;
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  return (
    <>
      <Modal isOpen={isPopUpOpen}>
        <PopUpSettings onClose={() => setIsPopUpOpen(false)} />
      </Modal>
      <div className={s.footer}>
        <div className={clsx('container', s.container)}>
          <div className={s.top}>
            <Typography variant={'footer_m'} dark>
              Остались вопросы?
            </Typography>
            <Button as={'a'} variant={'link'} target={'_blank'} href={'https://t.me/telegivcom'}>
              контакты и комьюнити
            </Button>
          </div>
          <div className={s.bottom}>
            <div className={s.social}>
              {IsSmallSizeScreen() && (
                <Typography variant={'footer_m'} dark>
                  Соц.сети
                </Typography>
              )}
              <div className={s.socialBlock}>
                <a target={'_blank'} href={'https://t.me/telegiv_com'} className={s.item} rel='noreferrer'>
                  <Icon iconId={ICON.TELEGRAM} />
                </a>
                <a
                  target={'_blank'}
                  href={'https://www.instagram.com/telegiv_com/'}
                  className={s.item}
                  rel='noreferrer'
                >
                  <Icon iconId={ICON.INSTAGRAM} />
                </a>
              </div>
            </div>
            <div className={s.right}>
              <div className={s.linksBlock}>
                {!IsSmallSizeScreen() && <Copy />}
                <Separator />
                {IsSmallSizeScreen() && (
                  <Typography variant={'footer_m'} dark>
                    важное
                  </Typography>
                )}
                <Button as={NavLink} variant={'link'} to={PATH.salesPrivacyPolicy} dark={!IsSmallSizeScreen()}>
                  Политика конфеденциальности
                </Button>
                <Separator />
                <Button variant={'link'} onClick={() => setIsPopUpOpen(true)} dark={!IsSmallSizeScreen()}>
                  <span className={s.longText}>Согласие на использование файлов</span> Сookie
                </Button>
              </div>
              <div className={s.authors}>
                {IsSmallSizeScreen() && <Copy />}
                <Button as={NavLink} variant={'link'} to={'#'} size={'medium'}>
                  работали над проектом
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Copy = () => {
  return (
    <Button as={'div'} variant={'link'} dark className={s.copy}>
      ©️ 2023 Telegiv
    </Button>
  );
};

const Separator = () => {
  return IsSmallSizeScreen() ? null : <span>•</span>;
};
