import { getNearestDate } from './transformDate';

export const currentTime = () => {
  const { currentDate, nearestStartMinutes } = getNearestDate();
  currentDate.setMinutes(nearestStartMinutes);

  return {
    day: currentDate.getDate(),
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
    hours: currentDate.getHours(),
    minutes: currentDate.getMinutes(),
  };
};
export const currentEndTime = () => {
  const { currentDate, nearestStartMinutes } = getNearestDate();
  currentDate.setMinutes(nearestStartMinutes);

  currentDate.setDate(currentDate.getDate() + 5);

  return {
    day: currentDate.getDate(),
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
  };
};
export const timeAdapter = (date1: Date) => {
  const date = new Date(date1);
  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};
