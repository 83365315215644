import React, { FC, PropsWithChildren } from 'react';
import s from './EmailConfirmStatus.module.scss';
import email_confirmed from '../../../../assets/svg/email_confirmed.svg';
import email_confirm_failed from '../../../../assets/svg/email_confirm_failed.svg';

type PropsType = {
  type: 'success' | 'failed';
  firstMessage: string;
  secondMessage: string;
};

export const EmailConfirmStatus: FC<PropsType & PropsWithChildren> = ({
  children,
  firstMessage,
  secondMessage,
  type,
}) => {
  const iconVariant = type === 'success' ? email_confirmed : email_confirm_failed;
  return (
    <div className={s.successMessageContainer}>
      <img className={s.successIcon} src={iconVariant} alt='email_confirm_icon' />
      <span className={s.successMessage}>{firstMessage}</span>
      <span className={s.successMessage}>{secondMessage}</span>
      {children}
    </div>
  );
};
