import React from 'react';
import s from './Main.module.scss';

import { Green, Typography } from '../../../../../../components/typography';
import { Button } from '../../../../../../components/button';
import BgcImg from '../../../../../../assets/image/sales/landing/mainPageBgc.png';
import { Section } from '../Section';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../../../../common/path/puth';
import { ScrollLink } from '../../../../../../common/components/scrollLink/scrollLink';

export const Main = () => {
  return (
    <Section dark className={s.mainSection}>
      <div className={clsx('container', s.container)}>
        <div className={s.right}>
          <Typography variant={'title_block'} align={'start'}>
            Сервис розыгрыша призов <br />в <Green>Telegram</Green>
          </Typography>
          <div className={s.btnContainer}>
            <Button variant={'primary'} as={NavLink} to={`/sales/${PATH.registration}`}>
              присоединиться
            </Button>
            <ScrollLink to={'faq'}>
              <Button variant={'secondary'}>подробнее</Button>
            </ScrollLink>
          </div>
        </div>
      </div>
      <div className={s.bgc}>
        <img src={BgcImg} alt='' />
        <iframe
          scrolling='no'
          height='500px'
          width='500px'
          src='https://rive.app/community/5308-11093-cute-interactive-robot/embed'
          allowFullScreen
          className={s.frame}
        ></iframe>
      </div>
    </Section>
  );
};
