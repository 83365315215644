import React, { FC } from 'react';
import { DrawsCategoryType, RAFFLES_STATUSES } from '../../AdminDraws';
import { GeneralDrawsCategory } from './GeneralDrawsCategory';
import { HiddenDrawsCategory } from './HiddenDrawsCategory';

type PropsType = {
  drawsCategory: DrawsCategoryType;
  setDrawsCategory: (category: DrawsCategoryType) => void;
  onRaffleCategoryChange: (category: DrawsCategoryType) => void;
};

export const AdminDrawsCategoryButtons: FC<PropsType> = ({
  drawsCategory,
  setDrawsCategory,
  onRaffleCategoryChange,
}) => {
  const isHiddenCategoryButtonsShouldDisplay =
    drawsCategory === RAFFLES_STATUSES.HiddenActive || drawsCategory === RAFFLES_STATUSES.HiddenEnd;

  return (
    <div>
      <GeneralDrawsCategory
        drawsCategory={drawsCategory}
        setDrawsCategory={setDrawsCategory}
        onRaffleCategoryChange={onRaffleCategoryChange}
      />
      {isHiddenCategoryButtonsShouldDisplay && (
        <HiddenDrawsCategory drawsCategory={drawsCategory} onRaffleCategoryChange={onRaffleCategoryChange} />
      )}
    </div>
  );
};
