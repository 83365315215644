import React, { ElementRef, forwardRef, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { ButtonPropsModel, ButtonVariant, StyledButtonPropsModel } from '../types';

const StyledButton = styled.button<StyledButtonPropsModel>`
  box-sizing: border-box;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: ${(props: StyledButtonPropsModel) => props.$width || '100%'};
  min-height: ${(props: StyledButtonPropsModel) => props.$height || '58px'};
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  user-select: none;
  text-decoration: none;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */

  ${props =>
    props.$variant === ButtonVariant.PRIMARY &&
    css<ButtonPropsModel>`
      background-color: #3478f6;
      border: 1px solid #3478f6;
      border-radius: 17px;
    `}
  ${props =>
    props.$variant === ButtonVariant.SECONDARY &&
    css<ButtonPropsModel>`
      width: 100%;
      background-color: #151515;
      border: 1px solid #151515;
      border-radius: 17px;
    `}
    ${props =>
    props.$variant === ButtonVariant.ICON &&
    css<ButtonPropsModel>`
      all: unset;
      cursor: pointer;
      width: fit-content;
      background-color: transparent;
      &:active {
        opacity: 0.8;
      }
    `}
    ${props =>
    props.$variant === ButtonVariant.BOX &&
    css<ButtonPropsModel>`
      all: unset;
      cursor: pointer;
      background-color: transparent;
      width: 100%;
      padding: 0;
      &:active {
        opacity: 0.8;
      }
    `}
    ${props =>
    props.$variant === ButtonVariant.TRANSPARENT &&
    css<ButtonPropsModel>`
      background-color: transparent;
      border: 1px solid #ffffff;
      color: #ffffff;
      border-radius: 17px;
    `}
    
    opacity: ${props => (props.disabled && props.opacity ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:hover {
    transition-duration: 0.1s;
  }
`;

export const Button = React.memo(
  forwardRef<ElementRef<'button'>, ButtonPropsModel>(
    (
      {
        variant = ButtonVariant.PRIMARY,
        children,
        width,
        height,
        onNavigateToPage,
        onClick,
        path,
        gap,
        disabled,
        opacity,
        ...restProps
      },
      ref
    ) => {
      const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
        if (path && onNavigateToPage) onNavigateToPage(path);
        onClick && onClick(event);
      };
      return (
        <StyledButton
          {...restProps}
          ref={ref}
          style={{ width, height }}
          disabled={disabled}
          $variant={variant}
          $gap={gap}
          opacity={opacity}
          onClick={onClickHandler}
        >
          {children}
        </StyledButton>
      );
    }
  )
);
