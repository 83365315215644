import React, { FC, ReactNode } from 'react';
import { Section } from '../Section';
import s from './SectionBigTitle.module.scss';
import { Typography } from '../../../../../../components/typography';
import clsx from 'clsx';
type Props = {
  children: ReactNode;
  className?: string;
  largePadding?: boolean;
};
export const SectionBigTitle: FC<Props> = ({ children, className, largePadding = false }) => {
  return (
    <Section className={clsx(s.section, largePadding && s.largePadding, className)}>
      <Typography variant={'title_block'} dark>
        {children}
      </Typography>
    </Section>
  );
};
