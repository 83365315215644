export enum ICON {
  ADD = 'add',
  ARROW_DOWN = 'arrow-down',
  ARROW_DOWN2 = 'arrow-down2',
  ARROW_LEFT = 'arrow-left',
  ARROW_LEFT2 = 'arrow-left2',
  ARROW_RIGHT = 'arrow-right',
  ARROW_RIGHT2 = 'arrow-right2',
  ARROW_UP = 'arrow-up',
  ARROW_UP2 = 'arrow-up2',
  TELEGRAM = 'Telegram',
  INSTAGRAM = 'Instagram',
  NOTIFICATION = 'notification',
  USER = 'user',
  CANCEL = 'cancel',
  COOKIES = 'cookies',
  AGE = '18',
  APPLE = 'apple',
  BEETLE = 'beetle',
  BITCOIN = 'bitcoin',
  BRAIN = 'brain',
  CALENDAR = 'calendar',
  CALENDAR_SEARCH = 'calendar-search',
  CARD = 'card',
  CARD_RF = 'cardRF',
  CARD_SNG = 'cardSNG',
  CHART = 'chart',
  CHECK = 'check',
  CLOCK = 'clock',
  CLOSE = 'close',
  CLOSE2 = 'close2',
  COINS = 'coins',
  COPY = 'copy',
  CROWN = 'crown',
  CUP = 'cup',
  DEAL = 'deal',
  DEAL_BORDER_WHITE = 'deal-border-white',
  DEAL_BORDER_GRAY = 'deal-border-grey',
  DOCUMENT_TEXT = 'document-text',
  EARTH = 'earth',
  EDIT = 'edit',
  EYE = 'eye',
  EYE_SLASH = 'eye-slash',
  FLOWER = 'flower',
  GALLERY = 'gallery',
  GALLERY_ADD = 'gallery-add',
  GALLERY_EDIT = 'gallery-edit',
  GIFT = 'gift',
  GOOGLE = 'google',
  GUN = 'gun',
  HAMMER = 'hammer',
  HANDCUFFS = 'handcuffs',
  INFO_CIRCLE = 'info-circle',
  JACKDAW = 'jackdaw',
  LOCATION_TICK = 'location-tick',
  LOGOUT = 'logout',
  MENU = 'menu',
  MESSAGES = 'messages-2',
  MINUS = 'minus',
  MONEY_SEND = 'money-send',
  MORE_CIRCLE = 'more-circle',
  PAPERCLIP = 'paperclip-3',
  PEOPLE = 'people',
  PIZZA = 'pizza',
  PLATE = 'plate',
  PROMOTION = 'promotion',
  PAYMENT_SNG = 'payment-sng',
  PAYMENT_RF = 'payment-rf',
  PAYMENT_UMONEY = 'payment-u-money',
  RANKING = 'ranking',
  SEARCH_NORMAL = 'search-normal',
  SEAND = 'send-2',
  SETTING2 = 'setting-2',
  SETTING4 = 'setting-4',
  SMOKING_AREA = 'smoking-area',
  SMS_TRACKING = 'sms-tracking',
  STAR = 'star',
  TICKET_STAR = 'ticket-star',
  TIMER = 'timer',
  TOILET = 'toilet',
  TRASH = 'trash',
  UNDO = 'undo',
  USER_ADD = 'user-add',
  USER_FULL_BODY = 'user-full-body',
  USER_FULL_BODY2 = 'user-full-body2',
  WALLET3 = 'wallet-3',
  WALLET_MONEY = 'wallet-money',
  WINE_BOTTLE = 'wine-bottle',
}
