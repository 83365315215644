import React, { FC } from 'react';
import s from './AdminManagerBalance.module.scss';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';
import { adminManagerThunks } from '../../../adminManagerSlice';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { TransferSteps } from './AdminManagerBalance';
import { withdrawIdSelector } from '../../../adminManagerSelectors';
import { useParams } from 'react-router-dom';

type PropsType = {
  setTransferSteps: (stepName: TransferSteps) => void;
};

export const TransferConfirm: FC<PropsType> = ({ setTransferSteps }) => {
  const dispatch = useAppDispatch();
  const withdrawId = useAppSelector(withdrawIdSelector);
  const { managerId } = useParams();
  const confirmTransfer = () => {
    setTransferSteps('cancel');
    dispatch(adminManagerThunks.confirmTransfer({ withdrawId }))
      .unwrap()
      .then(() => {
        if (managerId) dispatch(adminManagerThunks.getManagerById({ id: managerId }));
      });
  };
  const cancelTransfer = () => setTransferSteps('cancel');

  return (
    <>
      <span className={s.confirmTransferTitle}>Подтверди перевод</span>
      <div className={s.confirmButtonWrapper}>
        <CustomButton title={'Отмена'} textColor={'white'} color={'warning'} callback={cancelTransfer} />
        <CustomButton title={'Подтвердить'} textColor={'white'} color={'success'} callback={confirmTransfer} />
      </div>
    </>
  );
};
