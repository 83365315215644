import React, { FC, useState } from 'react';
import s from './ManagerInfo.module.scss';
import info from '../../../../../assets/svg/question.svg';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Fade } from '@mui/material';
import { CopiedToClipboard } from '../copiedToClipboard/CopiedToClipboard';

type PropsType = {
  title: string;
  cellInfo: string;
  withoutTips?: true;
  tooltipText?: string;
  copiedText?: string;
  copyTextAllow?: boolean;
};
export const ManagerInfo: FC<PropsType> = ({
  title,
  cellInfo,
  tooltipText,
  copiedText,
  copyTextAllow,
  withoutTips,
}) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      fontFamily: 'Mulish',
      padding: '15px',
      color: '#000000',
      borderRadius: '8px',
      maxWidth: 420,
      boxShadow: '0 2px 24px rgba(0, 0, 0, 0.3)',
      fontSize: theme.typography.pxToRem(24),
    },
  }));

  const copyText = () => {
    navigator.clipboard.writeText(cellInfo).then(() => {
      setShowCopyMessage(true);
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 1500);
    });
  };

  const blockInfoStyle = `${s.blockInfo} ${copyTextAllow ? s.cursorPointer : ''}`;

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockHeader}>
        {withoutTips ? (
          <span className={s.blockTitle}>{title}</span>
        ) : (
          <HtmlTooltip
            title={tooltipText}
            placement={'bottom-end'}
            enterTouchDelay={0}
            leaveTouchDelay={10000}
            TransitionComponent={Fade}
          >
            <div className={s.titleWithTipWrapper}>
              <span className={s.blockTitleWithTip}>{title}</span>
              <img className={s.blockTitleIcon} src={info} alt='info' />
            </div>
          </HtmlTooltip>
        )}
      </div>
      <div className={blockInfoStyle} onClick={copyText}>
        {!showCopyMessage && cellInfo}
        {showCopyMessage && <CopiedToClipboard copiedText={copiedText || 'Скопировано'} />}
      </div>
    </div>
  );
};
