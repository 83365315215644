import React, { FC } from 'react';
import Pagination from '@mui/material/Pagination';

type PropsType = {
  disabled?: boolean;
  page: string;
  pagesCount: number;
  onChange: (page: string) => void;
};
export const SalesPagination: FC<PropsType> = ({ page, pagesCount, onChange, disabled }) => {
  const styleForContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: '20px',
    margin: '20px 0 40px 0',
  };
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    onChange(page.toString());
  };

  return (
    <div style={styleForContainer}>
      <Pagination
        disabled={disabled}
        count={pagesCount}
        page={+page || 1}
        color='primary'
        size={'small'}
        shape='rounded'
        onChange={handlePaginationChange}
      />
    </div>
  );
};
