import React, { useEffect, useState } from 'react';
import { UsersTable } from './userTable/UsersTable';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { AdminFindUser } from './adminFindUser/AdminFindUser';
import s from './AdminUsers.module.scss';
import { adminUsersThunks } from '../adminUsersSlice';
import { SalesPagination } from '../../sales/common/components/pagination/Pagination';
import { getUsersPagesCountSelector } from '../adminSelectors';
import { isAppLoadingSelector } from '../../../bll/reducers/appSelectors';
import { PreLoader } from '../../sales/common/components/preLoader/PreLoader';
import { useCustomSearchParams } from '../../../common/hooks/useCustomSearchParams';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';
import { SortFieldsType, SortVariantType } from '../commonAdminTypes';
import { FIELD_SORTING, SORT_DIRECTION } from '../../../common/constants/enumConstants';

type AdminUsersParamsType = 'page' | 'size' | 'sortBy' | 'sortDirection';

export const AdminUsers = () => {
  const pagesCount = useAppSelector(getUsersPagesCountSelector);
  const isAppLoading = useAppSelector(isAppLoadingSelector);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useCustomSearchParams<AdminUsersParamsType>();

  const [sortField, setSortField] = useState<SortFieldsType>(FIELD_SORTING.Id);
  const [sortDir, setSortDir] = useState<SortVariantType>(SORT_DIRECTION.Asc);

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';
  const sortBy = searchParams.sortBy || FIELD_SORTING.Id;
  const sortDirection = searchParams.sortDirection || SORT_DIRECTION.Asc;

  const getSortingDirection = sortDir === SORT_DIRECTION.Asc ? SORT_DIRECTION.Desc : SORT_DIRECTION.Asc;

  useEffect(() => {
    dispatch(adminUsersThunks.getUsers({ page: currentPage, size: pageSize, sortBy, sortDirection }));
  }, [currentPage, sortDirection, sortBy]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const sortChangeHandler = (field: SortFieldsType) => {
    const newSortDir = sortField === field ? getSortingDirection : SORT_DIRECTION.Asc;
    setSortField(field);
    setSortDir(newSortDir);
    setSearchParams({ ...searchParams, sortBy: field, sortDirection: newSortDir, page: currentPage });
  };

  return (
    <>
      <div className={s.tableHeader}>
        <div className={s.tableTitleWrapper}>
          <BlockTitle title={'Пользователи'} />
        </div>
        <div className={s.searchWrapper}>
          <AdminFindUser disabled={isAppLoading} />
        </div>
      </div>
      {isAppLoading ? (
        <PreLoader />
      ) : (
        <>
          <UsersTable sortField={sortField} sortDir={sortDir} sortChangeHandler={sortChangeHandler} />
          <SalesPagination page={currentPage} pagesCount={pagesCount} onChange={onChangePage} />
        </>
      )}
    </>
  );
};
