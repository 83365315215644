import React, { FC } from 'react';
import s from '../WithdrawalsAction.module.scss';
import { WithdrawalsPasswordInput } from '../WithdrawalsPasswordInput';
import { Button, ThemeProvider } from '@mui/material';
import { muiTheme } from '../../../../../constants/customTheme';
import { ActiveWindowType } from '../WithdrawalsAction';
import { FormikProps } from 'formik';
import { FormikValuesType } from '../../../../../common/types/commonTypes';

type Props = {
  formik: FormikProps<FormikValuesType>;
  visibility: 'password' | 'text';
  toggleVisibility: () => void;
  setActiveWindow: (activeWindow: ActiveWindowType) => void;
};

export const PasswordBlock: FC<Props> = ({ formik, visibility, toggleVisibility, setActiveWindow }) => {
  const onCancelHandler = () => {
    setActiveWindow('withdrawals');
    formik.setFieldError('password', '');
  };

  return (
    <div className={s.withdrawalsActionBlock}>
      <div className={s.password}>Проверка безопасности</div>
      <div className={s.inputHeader}>Введи пароль от учетной записи Telegiv</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={s.input}>
          <WithdrawalsPasswordInput
            placeholder={'Пароль'}
            formik={formik}
            inputName={'password'}
            visibility={visibility}
            toggleVisibility={toggleVisibility}
          />
        </div>
        <div className={s.passwordButtons}>
          <ThemeProvider theme={muiTheme}>
            <Button
              disabled={false}
              variant={'contained'}
              prefix={'main'}
              color={'secondary'}
              className={s.button}
              type={'submit'}
            >
              Получить перевод
            </Button>
            <Button
              disabled={false}
              variant={'contained'}
              prefix={'main'}
              color={'error'}
              className={s.button}
              onClick={onCancelHandler}
            >
              Отмена
            </Button>
          </ThemeProvider>
        </div>
      </form>
    </div>
  );
};
