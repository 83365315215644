import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { thunkErrorHandler } from '../../../../common/utils/thunkErrorHandler';
import { ChangeUserDataArgs, salesSettingsApi } from './salesSettingsApi';
import { setAppSuccessMessage } from '../../../../bll/reducers/appReducer';

const slice = createSlice({
  name: 'sales/settings',
  initialState: {
    isChangeUserDataLoading: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(changeUserData.pending, (state, action) => {
        state.isChangeUserDataLoading = true;
      })
      .addCase(changeUserData.fulfilled, (state, action) => {
        state.isChangeUserDataLoading = false;
      })
      .addCase(changeUserData.rejected, (state, action) => {
        state.isChangeUserDataLoading = false;
      });
  },
});

const changeUserData = createAsyncThunk<any, ChangeUserDataArgs>(
  'sales/settings/changeData',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const res = await salesSettingsApi.changeUserData(arg);
      dispatch(setAppSuccessMessage({ successMessage: 'Ваши персональные данные успешно обновлены' }));
      return res.data;
    } catch (e) {
      thunkErrorHandler(e, dispatch);
      return rejectWithValue(null);
    }
  }
);

export const salesSettingsReducer = slice.reducer;
export const salesSettingsActions = slice.actions;
export const salesSettingsThunks = { changeUserData };
