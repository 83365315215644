import { useState } from 'react';

export const useValidImage = () => {
  const [isImage, setIsImage] = useState<boolean>(false);

  const checkImage = async (url: string): Promise<void> => {
    try {
      const result = await new Promise<boolean>((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = () => resolve(true);
        image.onerror = () => reject(false);
      });

      setIsImage(result);
    } catch (error) {
      console.error('Error checking image:', error);
      setIsImage(false);
    }
  };
  return { isImage, checkImage };
};
