import React, { ChangeEvent, FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import s from '../../AdminDraws.module.scss';
import { SetURLSearchParams } from '../../../../../common/hooks/useCustomSearchParams';

type PropsType = {
  setSearchParams: SetURLSearchParams;
  searchParams: { [key: string]: string };
  disabled: boolean;
};

export const AdminDrawsSearch: FC<PropsType> = ({ setSearchParams, searchParams, disabled }) => {
  const [searchedDrawTitle, setSearchedDrawTitle] = useState('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const debouncedSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const draw = e.currentTarget.value;
    setSearchedDrawTitle(draw);

    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        if (!draw) {
          const updatedSearchParams = { ...searchParams };
          delete updatedSearchParams.title;
          setSearchParams(updatedSearchParams);
        } else {
          setSearchParams({ ...searchParams, title: draw });
        }
      }, 1000)
    );
  };

  return (
    <div className={s.drawsSearch}>
      <TextField
        fullWidth
        onChange={debouncedSearch}
        placeholder={'Поиск'}
        value={searchedDrawTitle}
        size={'small'}
        disabled={disabled}
      />
    </div>
  );
};
