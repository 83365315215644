import React, { FC } from 'react';
import s from './mySalesTable.module.scss';
import { HistoryItemResponseType } from '../../salesBalanceApi';
import { serverDateFormat } from '../../../../common/utils/serverDateFormat';

type Props = {
  historyItem: HistoryItemResponseType;
};
export const MySalesTableRow: FC<Props> = ({ historyItem }) => {
  const date = serverDateFormat(historyItem.purchaseDate?.toString());

  return (
    <tr>
      <td className={s.dateRow}>
        <div className={s.tableCell}>
          <span>{date}</span>
        </div>
      </td>
      <td className={s.actionRow}>
        <div className={s.tableCell}>
          <span>
            {historyItem.customerEmail} оплатил {historyItem.costPlan}$
          </span>
        </div>
      </td>
      <td className={s.percentRow}>
        <div className={s.tableCellShortCount}>
          <span>{historyItem.myProfitPercent}%</span>
        </div>
      </td>
      <td className={s.incomeRow}>
        <div className={s.tableCell}>
          <span>{historyItem.myProfit}$</span>
        </div>
      </td>
    </tr>
  );
};
