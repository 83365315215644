import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from './components/Alert';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { setAppErrorAC, setAppSuccessMessage } from '../../../bll/reducers/appReducer';

export const NotifySnackbar = () => {
  const error = useAppSelector(state => state.app.error);
  const successMessage = useAppSelector(state => state.app.successMessage);

  const dispatch = useAppDispatch();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setAppErrorAC({ error: null }));
    dispatch(setAppSuccessMessage({ successMessage: null }));
  };

  const getSeverity = successMessage ? 'success' : 'error';

  return (
    <Snackbar open={!!(error || successMessage)} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={getSeverity} sx={{ width: '100%', fontSize: '15px' }}>
        {error || successMessage}
      </Alert>
    </Snackbar>
  );
};
