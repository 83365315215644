import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';

const DEFAULT_WIDTH = 900;
const DEFAULT_QUERY_MATCH_WIDTH = 999;

type BasicModalPropsType = {
  open: boolean;
  setClose: () => void;
  children: any;
  bg?: string;
  basicWidth?: number | string;
  queryMatchWidth?: number;
};

export const BasicModal = (props: BasicModalPropsType) => {
  const matches = useMediaQuery(`(min-width:${props.queryMatchWidth || DEFAULT_QUERY_MATCH_WIDTH}px)`);
  const defaultWidth = props.basicWidth || DEFAULT_WIDTH;
  const onClickHandler = () => {
    props.setClose();
  };

  const bgcolor = props.bg ? props.bg : 'background.paper';

  const style = useMemo(() => {
    return {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: matches ? defaultWidth : '87%',
      maxHeight: '95%',
      bgcolor: bgcolor,
      overflow: 'auto',
      border: 'none',
      boxShadow: 24,
      borderRadius: 3,
      p: 4,
    };
  }, [matches, bgcolor]);

  const modalStyles: React.CSSProperties = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }, []);

  return (
    <Modal
      style={modalStyles}
      onClose={onClickHandler}
      open={props.open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>{props.children}</Box>
    </Modal>
  );
};
