import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import s from './EditUser.module.scss';
import { CommonInput } from '../../../common/components/commonInput/CommonInput';
import { FormikValuesType } from '../../sales/common/types/commonTypes';
import { getSelectedUser } from '../adminSelectors';
import { adminUsersThunks, EditedUserModel } from '../adminUsersSlice';
import { CustomButton } from '../../../common/components/customButton/CustomButton';
import { useWindowDimensions } from '../../../common/utils/useWindowDimensions';
import { PATH } from '../../../common/path/puth';
import { EditRatePlan } from './EditRatePlan';
import { isAppLoadingSelector } from '../../../bll/reducers/appSelectors';
import { PreLoader } from '../../sales/common/components/preLoader/PreLoader';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';

export const EditUser = () => {
  const selectedUser = useAppSelector(getSelectedUser);
  const isAppLoading = useAppSelector(isAppLoadingSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (userId) dispatch(adminUsersThunks.getUserById(userId));
  }, [userId]);

  const toUserPage = () => navigate(PATH.users);

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      email: selectedUser.email_address,
      phone: selectedUser.usr_phone_number ?? '',
      tg_username: selectedUser.usr_tg ?? '',
      ratePlanId: selectedUser.rate_plan_id,
    },

    enableReinitialize: true,
    onSubmit: values => {
      if (values.email) {
        const preparedParams: EditedUserModel = {
          email_address: values.email,
          usr_phone_number: values.phone,
          usr_tg: values.tg_username,
          rate_plan_id: values.ratePlanId,
        };
        dispatch(adminUsersThunks.editUser(preparedParams))
          .unwrap()
          .then(() => navigate(PATH.users));
      }
    },
  });

  return (
    <>
      <BlockTitle title={'Редактирование пользователя'} />
      {isAppLoading ? (
        <PreLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className={s.userEditForm}>
          <CommonInput
            name={'EMAIL'}
            placeholder={selectedUser.email_address}
            formik={formik}
            inputName={'email'}
            disable
          />
          <CommonInput name={'ТЕЛЕФОН'} formik={formik} inputName={'phone'} />
          <CommonInput name={'TELEGRAM'} formik={formik} inputName={'tg_username'} />
          <EditRatePlan formik={formik} />
          <div className={s.buttonContainer}>
            <CustomButton
              title={'Назад'}
              textColor={'white'}
              color={'info'}
              width={width > 585 ? '160' : '290'}
              callback={toUserPage}
            />
            <CustomButton
              title={'Сохранить'}
              textColor={'white'}
              color={'success'}
              width={width > 585 ? '360' : '290'}
            />
          </div>
        </form>
      )}
    </>
  );
};
