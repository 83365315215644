import { configureStore } from '@reduxjs/toolkit';
import { salesAuthReducer } from '../../features/sales/salesAuthSlice';
import { salesBalanceReducer } from '../../features/sales/components/salesBalance/salesBalanceSlice';
import thunk from 'redux-thunk';
import { slice as adminLoginReducer } from '../../features/admin/adminAuthSlice';
import { slice as drawReducer } from '../../features/customer/drawSlice';
import { slice as promoCodeReducer } from '../reducers/promoCodeReducer';
import { slice as appReducer } from '../reducers/appReducer';
import { slice as drawCommon } from '../../features/customer/drawSliceCommon';
import { slice as customerLogin } from '../../features/customer/customerAuthSlice';
import { slice as createBotReducer } from '../../features/customer/createBotSlice';
import { slice as raffleReducer } from '../../features/customer/raffleSlice';
import { slice as mainFinishReducer } from '../reducers/mainFinishReducer';
import { slice as infoReducer } from '../reducers/infoReducer';
import { salesPublicManagerReducer } from '../../features/sales/publicManagerSlice';
import { adminUsersReducer } from '../../features/admin/adminUsersSlice';
import { adminTransfersReducer } from '../../features/admin/adminTransfers/adminTransfersSlice';
import { salesSettingsReducer } from '../../features/sales/components/salesSettings/salesSettingsSlice';
import { adminManagerReducer } from '../../features/admin/adminManagerSlice';
import { adminRafflesSlice } from '../../features/admin/adminDrawsSlice';
import { drawResultsReducer } from '../../features/drawResults/drawResultsSlice';
import { analysisSlice } from '../../features/advertisingAnalysis/analysisSlice';
import { paidChannelsSlice } from '../../features/advertisingAnalysis/paidChannelsSlice';
import { adminMailingReducer } from '../../features/admin/adminMailing/bll';

export const store = configureStore({
  reducer: {
    app: appReducer.reducer,
    admin: adminLoginReducer.reducer,
    adminTransfers: adminTransfersReducer,
    draw: drawReducer.reducer,
    promo: promoCodeReducer.reducer,
    customerLogin: customerLogin.reducer,
    createBot: createBotReducer.reducer,
    raffle: raffleReducer.reducer,
    mainFinishDraws: mainFinishReducer.reducer,
    info: infoReducer.reducer,
    salesAuth: salesAuthReducer,
    salesPublicManager: salesPublicManagerReducer,
    salesBalance: salesBalanceReducer,
    adminUsers: adminUsersReducer,
    salesSettings: salesSettingsReducer,
    adminManager: adminManagerReducer,
    adminMailing: adminMailingReducer,
    adminDraws: adminRafflesSlice,
    drawResults: drawResultsReducer,
    drawCommon: drawCommon.reducer,
    analysisUser: analysisSlice.reducer,
    analysisAll: paidChannelsSlice.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).prepend(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// @ts-ignore
window.store = store.getState();
