import React, { FC } from 'react';
import s from './MyPaymentsTable.module.scss';
import { SalesPagination } from '../../../../common/components/pagination/Pagination';
import { MyPaymentsTableLayout } from './MyPaymentsTableLayout';
import { MyPaymentsTableRow } from './MyPaymentsTableRow';
import { GetHistoryResponseType, HistoryItemResponseType } from '../../salesBalanceApi';
import { useAppSelector } from '../../../../../../common/hooks/storeHooks';
import { CircularProgress } from '@mui/material';
import { isSalesBalanceHistoryFetchingSelector } from '../../salesBalanceSelectors';
import { isAdminManagerHistoryFetchingSelector } from '../../../../../admin/adminManagerSelectors';
import { isAdminTransferHistoryFetchingSelector } from '../../../../../admin/adminTransfers/adminTransfersSelectors';

type Props = {
  historyData: GetHistoryResponseType;
  currentPage: string;
  onChangePage: (page: string) => void;
};

const historyItems: HistoryItemResponseType[] = [
  {
    id: 1,
    managerId: 1,
    purchaseDate: new Date(),
    customerId: 100,
    customerEmail: 'customer1@example.com',
    costPlan: 500,
    myProfit: 100,
    myProfitPercent: 20,
  },
];

export const MyPaymentsTable: FC<Props> = ({ historyData, currentPage, onChangePage }) => {
  const isAdminManagerHistoryFetching = useAppSelector(isAdminManagerHistoryFetchingSelector);
  const isAdminTransferHistoryFetching = useAppSelector(isAdminTransferHistoryFetchingSelector);
  const isSalesBalanceHistoryFetching = useAppSelector(isSalesBalanceHistoryFetchingSelector);

  const isFetching = isAdminManagerHistoryFetching || isSalesBalanceHistoryFetching || isAdminTransferHistoryFetching;

  return (
    <div className={s.noHistoryTextWrapper}>
      {isFetching && (
        <div className={s.circularProgress}>
          <CircularProgress />
        </div>
      )}
      {!isFetching && historyData.items.length === 0 ? (
        <>
          <div className={s.noHistoryText}>Нет истории транзакций</div>
          <div className={s.emptyTable}>
            <MyPaymentsTableLayout>
              {historyItems.map(i => {
                return <MyPaymentsTableRow key={i.id} historyItem={i} />;
              })}
            </MyPaymentsTableLayout>
          </div>
        </>
      ) : (
        <div>
          <MyPaymentsTableLayout>
            {historyData.items?.map(i => {
              return <MyPaymentsTableRow key={i.id} historyItem={i} />;
            })}
          </MyPaymentsTableLayout>
          <SalesPagination page={currentPage} pagesCount={historyData.pagesCount} onChange={onChangePage} />
        </div>
      )}
    </div>
  );
};
