import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiAdmin, PromoCodeType } from '../../api/apiAdmin';
import { RootState } from '../store/store';
import { setAppStatusAC } from './appReducer';
import { isAxiosError } from 'axios';
import { handleServerNetworkError } from '../../common/utils/error-utils';
import { RequestStatuses } from '../../common/enums/requestStatuses';

export const getPromoCode = createAsyncThunk('promo/getPromoCode', async (params, thunkApi) => {
  thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.loading }));

  try {
    const state = thunkApi.getState() as RootState;

    const token = state.admin.isLoggedInToken;

    const res = await apiAdmin.getPromoCode(token);

    thunkApi.dispatch(setAppStatusAC({ status: RequestStatuses.succeeded }));

    return res.data;
  } catch (err) {
    if (isAxiosError(err)) {
      handleServerNetworkError(err.message, thunkApi.dispatch);

      return thunkApi.rejectWithValue(null);
    }
    return thunkApi.rejectWithValue(null);
  }
});

export const slice = createSlice({
  name: 'promo',

  initialState: {
    promoCode: [{ name: '' }, { name: '' }, { name: '' }, { name: '' }, { name: '' }, { name: '' }] as PromoCodeType[],
  },

  reducers: {},

  extraReducers: builder => {
    builder.addCase(getPromoCode.fulfilled, (state, action) => {
      state.promoCode.splice(0, -1, ...action.payload);
    });
  },
});
