import React from 'react';
import { Section } from '../Section';
import clsx from 'clsx';
import s from './AboutUs.module.scss';
import { Typography } from '../../../../../../components/typography';
import { aboutUsCards } from './aboutUsCards';
import { GetCardDarkStatus } from '../../../../../../common/utils/getCardDarkStatus';
import { screenSizes } from '../../../../../../common/constants/screenSizes';
import { Card } from '../../../../../../components/Card/Card';

export const AboutUs = () => {
  return (
    <Section className={s.section}>
      <div className={clsx('container', s.container)}>
        <Typography variant={'title_block_768'} align={'end'} dark>
          <p className={s.longTitle}>
            Немного о нас
            <br /> и наших розыгрышах
          </p>
          <p className={s.shortTitle}>
            о нас и наших <br /> розыгрышах
          </p>
        </Typography>
        <div className={s.cardsContainer}>
          {aboutUsCards.map((card, index) => {
            return (
              <Card
                cardName={'aboutUs'}
                key={index}
                title={card.title}
                desc={card.desc}
                dark={GetCardDarkStatus([0], [0], screenSizes.mediumSmall, index)}
                img={card.img}
              ></Card>
            );
          })}
        </div>
      </div>
    </Section>
  );
};
