import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../common/hooks/storeHooks';
import { HeaderMain } from '../main/headerMain/HeaderMain';
import style from './DrawResults.module.css';
import { getWinnersT } from './drawResultsSlice';
import { ResultInfo } from './resultInfo/ResultInfo';
import { Winners } from './winners/Winners';
import { CardItem } from '../main/mainResults/cardItem/CardItem';
import { getActiveRafflesByCountry } from '../../bll/reducers/mainFinishReducer';

export const DrawResults = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  // const winners = useAppSelector(winnersSelector);
  const activeRaffles = useAppSelector(state => state.mainFinishDraws.draws.active);

  useEffect(() => {
    dispatch(getActiveRafflesByCountry()).catch(error => console.error('Error in setStatistic:', error));
  }, []);

  useEffect(() => {
    dispatch(getWinnersT({ raffleId: params.id }));
  }, [dispatch]);

  return (
    <div className={style.container}>
      <HeaderMain result main />
      <div className={style.drawContainer}>
        <Winners winnerId={params.id} />
      </div>
      <div className={style.drawContainer}>
        <ResultInfo />
      </div>

      {/*пока комментируем*/}

      {/*{winners.length !== 0 ? (*/}
      {/*  <div className={style.drawContainer}>*/}
      {/*    <HistoryDraw winners={winners} />*/}
      {/*  </div>*/}
      {/*) : null}*/}

      <div className={style.activeRafflesContainer}>
        {activeRaffles.length > 0 && (
          <div className={style.titleOfBlockActiveRaffles}>Розыгрыши, в которых ты можешь поучаствовать</div>
        )}
        <div className={style.blockActiveRaffles}>
          {activeRaffles.length > 0
            ? activeRaffles.map(raffle => (
                <CardItem key={raffle.id} image={raffle.fileName} title={raffle.header} item={raffle} />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
