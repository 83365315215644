import * as React from 'react';
import s from './TableStat.module.scss';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Data } from '../types';
import { rows } from '../mockData';
import { TablePaginationActions } from './tablePaginationActions/TablePaginationActions';
import { TableHeadStat } from './tableHeadStat/TableHeadStat';
import { getComparator } from '../utils/sortingDataTable';
import { SORT_DIRECTION } from '../../../common/constants/enumConstants';

export const TableStat = () => {
  const [order, setOrder] = React.useState<SORT_DIRECTION>(SORT_DIRECTION.Asc);
  const [orderBy, setOrderBy] = React.useState<keyof Data>('ignored');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === SORT_DIRECTION.Asc;
    setOrder(isAsc ? SORT_DIRECTION.Desc : SORT_DIRECTION.Asc);
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      rowsPerPage > 0
        ? rows.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows.sort(getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer className={s.tableContainer}>
        <Table className={s.table} sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
          <TableHeadStat order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />{' '}
          <TableBody>
            {visibleRows.map(row => (
              <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                <TableCell align='left' color={'var(--color-white-0)'}>
                  {row.id}
                </TableCell>
                <TableCell align='left'>{row.ignored}</TableCell>
                <TableCell align='left'>{row.blocked}</TableCell>
                <TableCell align='left'>{row.returned}</TableCell>
                <TableCell align='left'>{row.reached}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
              colSpan={4}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={'Строк на страницу'}
              labelDisplayedRows={() => ''}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </TableContainer>

      {/* </Paper> */}
    </Box>
  );
};
