import { Green, Typography } from '../../../../../../components/typography';
import React from 'react';
import s from '../Terms.module.scss';
import { TermsContainer } from '../TermsContainer';
import { Desc, Points, Point, Section } from '../TermsComponents';

export const CookiePage = () => {
  return (
    <TermsContainer>
      <div className={s.main}>
        <Typography variant={'title_block'} align={'start'} dark>
          политика <Green>cookie</Green>
        </Typography>
        <div className={s.main_point}>
          <Typography variant={'typeface_main'} className={s.desc} dark align={'start'}>
            Настоящий документ информирует пользователей о технологиях, которые помогают Telegiv достигнуть
            нижеописанных целей. Эти технологии позволяют владельцу получать доступ к информации (например, с
            использованием файлов cookie) или использовать ресурсы (например, с помощью выполнения сценария) на
            устройстве пользователя при взаимодействии с Telegiv.
          </Typography>
          <Typography variant={'typeface_main'} className={s.desc} dark align={'start'}>
            Обработка персональных данных осуществляется с целью улучшения работы сайта, совершенствования продуктов и
            услуг Telegiv, определения предпочтений пользователя, предоставления целевой информации по продуктам и
            услугам Общества и Партнеров Общества.
          </Typography>
          <Typography variant={'typeface_main'} className={s.desc} dark align={'start'}>
            Настоящее согласие действует с момента его предоставления и в течение всего периода использования веб-сайта.
          </Typography>
          <Typography variant={'typeface_main'} className={s.desc} dark align={'start'}>
            В случае отказа от обработки персональных данных метрическими программами я проинформирован о необходимости
            прекратить использование сайта или отключить файлы cookie в настройках браузера.
          </Typography>
        </div>
      </div>
      <div className={s.body}>
        <Section number={'1'} title={'действия, строго необходимые для работы Telegiv и предоставление услуг'}>
          <Desc>
            Telegiv использует так называемые «технические» файлы cookie и другие подобные трекеры для выполнения
            действий, которые строго необходимы для работы или предоставления Сервиса.'
            <Points>
              <Point number={'1.1'} title={'Услуги платформы и хостинг'} />
              <Desc>
                Эти услуги имеют целью предоставление хостинга и запуск ключевых компонентов Telegiv, что позволяет
                единой платформе обеспечивать функционирование Telegiv. Такие платформы предоставляют владельцу
                множество инструментов, таких как аналитика, регистрация пользователей, комментирование, управление
                базами данных, электронная коммерция, обработка платежей, которые предполагают сбор и обработку
                персональных данных.Некоторые из этих услуг работают с использованием географически распределенных
                серверов, что затрудняет определение фактического местоположения хранения персональных данных.
              </Desc>
              <Point number={'1.2'} title={'Обработка платежей'} />
              <Desc>
                Если не указано иное, Telegiv обрабатывает любые платежи с использованием кредитных карт, банковских
                переводов или других способов через внешних поставщиков платежных услуг. В общем случае и если не
                указано иное, Пользователям предлагается предоставить свои данные платежа и личную информацию напрямую
                таким поставщикам платежных услуг. Telegiv не участвует в сборе и обработке такой информации: вместо
                этого, оно только получает уведомление от соответствующего поставщика платежных услуг о том, был ли
                платеж успешно завершен.
                <Points>
                  <Point number={'1.2.1'} title={'Android Pay (Google Ireland Limited)'} />
                  <Desc>
                    Android Pay - это платежная услуга, предоставляемая компанией Google Ireland Limited, которая
                    позволяет пользователям совершать платежи с помощью мобильных телефонов.
                  </Desc>
                  <Desc>
                    Обрабатываемые персональные данные: адрес для выставления счетов, адрес электронной почты, имя,
                    фамилия, информация о платеже, номер телефона, история покупок, адрес доставки, трекеры, данные об
                    использовании и другие типы данных, указанные в политике конфиденциальности услуги.
                  </Desc>
                  <Point number={'1.2.2'} title={'Apple Pay (Apple Inc.)'} />
                  <Desc>
                    Apple Pay - это платежная услуга, предоставляемая компанией Apple Inc., которая позволяет
                    пользователям совершать платежи с помощью их мобильных телефонов.
                  </Desc>
                  <Desc>
                    Обрабатываемые персональные данные: адрес для выставления счетов, адрес электронной почты, имя,
                    фамилия, информация о платеже, номер телефона, история покупок, адрес доставки, трекеры, данные об
                    использовании и другие типы данных, указанные в политике конфиденциальности услуги.
                  </Desc>
                  <Point number={'1.2.3'} title={'Google Pay (Google Ireland Limited)'} />
                  <Desc>
                    Google Pay - это платежная услуга, предоставляемая компанией Google Ireland Limited, которая
                    позволяет пользователям совершать онлайн-платежи с использованием своих учетных данных Google.
                  </Desc>
                  <Desc>
                    Обрабатываемые персональные данные: адрес для выставления счетов, адрес электронной почты, имя,
                    фамилия, информация о платеже, номер телефона, история покупок, адрес доставки, трекеры, данные об
                    использовании и другие типы данных, указанные в политике конфиденциальности услуги.
                  </Desc>
                </Points>
              </Desc>
              <Point number={'1.3'} title={'Управление тегами'} />
              <Desc>
                Такой тип услуги помогает Владельцу централизованно управлять тегами или сценариями, необходимыми на
                Telegiv. Это приводит к тому, что данные пользователей проходят через эти услуги, что потенциально может
                привести к их сохранению.
                <Points>
                  <Point number={'1.3.1'} title={'Google Tag Manager (Google Ireland Limited'} />
                  <Desc>
                    Google Tag Manager - это сервис управления тегами, предоставляемый компанией Google Ireland Limited.
                  </Desc>
                  <Desc>Обрабатываемые персональные данные: трекеры и данные об использовании.</Desc>
                </Points>
              </Desc>
              <Point number={'1.4'} title={'Защита от спама'} />
              <Desc>
                Такой тип услуги анализирует трафик Telegiv, который потенциально содержит персональные данные
                пользователей, с целью фильтрации нежелательного трафика, сообщений и контента, который признается как
                спам.
                <Points>
                  <Point number={'1.4.1'} title={'Google reCAPTCHA (Google Ireland Limited)'} />
                  <Desc>
                    Google reCAPTCHA - это сервис защиты от спама, предоставляемый компанией Google Ireland Limited.
                    Использование reCAPTCHA регулируется политикой конфиденциальности и условиями использования Google.
                  </Desc>
                  <Desc>
                    Обрабатываемые персональные данные: ответы на вопросы, клики, нажатия клавиш, события сенсора
                    движения, движения мыши, позиция прокрутки, события сенсорного ввода, трекеры и данные об
                    использовании.
                  </Desc>
                </Points>
              </Desc>
            </Points>
          </Desc>
        </Section>
        <Section number={'2'} title={'Другие виды деятельности, связанные с использованием трекеров.'}>
          <Desc>
            Telegiv использует трекеры для обеспечения основных взаимодействий и функциональностей, позволяя
            пользователям получить доступ к выбранным функциям Сервиса и облегчая коммуникацию пользователя с
            владельцем.
            <Points>
              <Point number={'2.1'}>
                Связь с пользователем
                <Points>
                  <Point number={'2.1.1'}>Форма обратной связи</Point>
                  <Desc>
                    При заполнении формы обратной связи своими данными, пользователь разрешает Telegiv использовать эти
                    сведения для ответа на запросы информации, цены или любые другие запросы, указанные в заголовке
                    формы.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: город, название компании, страна, область, дата рождения, адрес
                    электронной почты, номер факса, сфера деятельности, имя, пол, фамилия, количество сотрудников, номер
                    телефона, физический адрес, профессия, штат, налоговый идентификационный номер, трекеры, данные
                    использования, идентификатор пользователя, различные типы данных, номер НДС, веб-сайт и почтовый
                    индекс.
                  </Desc>
                  <Point number={'2.1.2'}>Подписка на рассылку или новостную рассылку </Point>
                  <Desc>
                    При регистрации в рассылке или подписке на новостную рассылку, адрес электронной почты пользователя
                    будет добавлен в список контактов тех, кто может получать электронные сообщения, содержащие
                    информацию коммерческого или рекламного характера о Telegiv. Ваш адрес электронной почты также может
                    быть добавлен в этот список в результате регистрации на Telegiv или после совершения покупки.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: город, название компании, страна, область, дата рождения, адрес
                    электронной почты, имя, пол, фамилия, номер телефона, физический адрес, профессия, штат, трекеры,
                    данные использования, веб-сайт и почтовый индекс.
                  </Desc>
                </Points>
              </Point>
              <Point number={'2.2'} title={' Деятельность, связанная с повышением производительности.'} />
              <Desc>
                Этот тип услуг помогает владельцу управлять задачами, сотрудничеством и, в общем, деятельностью,
                связанной с повышением производительности. При использовании этого типа услуг обрабатываются данные
                пользователей и они могут быть сохранены в зависимости от цели соответствующей деятельности. Эти услуги
                могут быть интегрированы с широким спектром сторонних сервисов, о которых сообщается в настоящей
                политике конфиденциальности, чтобы владелец мог импортировать или экспортировать данные, необходимые для
                соответствующей деятельности.
                <Points>
                  <Point number={'2.2.1'} title={'Gmail'} />
                  <Desc>
                    Gmail - это сервис, управляющий электронной перепиской, предоставляемый компанией Google LLC или
                    Google Ireland Limited, в зависимости от того, как владелец управляет обработкой данных. Для
                    рекламных целей Google не сканирует такую электронную переписку. Кроме того, Google не собирает и не
                    использует данные, передаваемые через этот сервис, для рекламных целей никаким другим способом.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: город, название компании, страна, данные, передаваемые при
                    использовании сервиса, дата рождения, адрес электронной почты, имя, пол, географическое положение,
                    фамилия, пароль, номер телефона, физический адрес, профессия, фотография профиля, снимки экрана,
                    трекеры, данные использования, имя пользователя, номер НДС и место работы.
                  </Desc>
                </Points>
              </Desc>
            </Points>
          </Desc>
        </Section>
        <Section number={'3'} title={'Повышение качества взаимодействия или опыта использования.'}>
          <Desc>
            Telegiv использует трекеры для обеспечения персонализированного пользовательского опыта, путем улучшения
            качества опций управления предпочтениями и позволяет взаимодействовать с внешними сетями и платформами.
            <Points>
              <Point number={'3.1'}> Доступ к учетным записям третьих сторон. </Point>
              <Desc>
                Этот тип услуг позволяет Telegiv получать доступ к данным вашей учетной записи на стороннем сервисе и
                выполнять с ними действия. Эти услуги не активируются автоматически, а требуют явного разрешения со
                стороны пользователя.
                <Points>
                  <Point number={'3.1.1'}> Доступ к учетной записи Gmail (Google Ireland Limited) </Point>
                  <Desc>
                    Эта услуга позволяет Telegiv подключиться к учетной записи пользователя в Gmail, предоставляемую
                    Google Ireland Limited.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: данные, передаваемые при использовании сервиса, информация об
                    устройстве, адрес электронной почты, имя, фамилия, пароль, трекеры, данные использования и имя
                    пользователя.
                  </Desc>
                  <Point number={'3.1.2'}> Доступ к учетной записи Facebook (Meta Ireland Limited) </Point>
                  <Desc>
                    Эта услуга позволяет Telegiv подключиться к учетной записи пользователя в Facebook, предоставляемую
                    Meta Platforms Ireland Limited, Inc.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: данные, передаваемые при использовании сервиса, информация об
                    устройстве, адрес электронной почты, имя, фамилия, пароль, трекеры, данные использования и имя
                    пользователя.
                  </Desc>
                  <Point number={'3.1.3'}> Доступ к учетной записи Telegram </Point>
                  <Desc>
                    Эта услуга позволяет Telegiv подключиться к учетной записи пользователя в Telegram, предоставляемую
                    Telegram FZ-LLC.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: данные, передаваемые при использовании сервиса, информация об
                    устройстве, адрес электронной почты, имя, фамилия, пароль, трекеры, данные использования и имя
                    пользователя.
                  </Desc>
                </Points>
              </Desc>
              <Point number={'3.2'}> Доступ к учетным записям третьих сторон.</Point>
              <Desc>
                Этот тип услуг позволяет просматривать контент, размещенный на внешних платформах, непосредственно со
                страниц Telegiv и взаимодействовать с ним. Даже если пользователи не используют этот тип услуг, он может
                по-прежнему собирать данные о посещаемости веб-страниц, на которых установлен сервис.
                <Points>
                  <Point number={'3.2.1'}>Google Fonts (Google Ireland Limited)</Point>
                  <Desc>
                    Google Fonts - это сервис визуализации шрифтов, предоставляемый Google Ireland Limited, который
                    позволяет Telegiv внедрять такой контент на своих страницах.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                  <Point number={'3.2.2'}>YouTube IFrame Player (Google Ireland Limited)</Point>
                  <Desc>
                    YouTube IFrame Player - это сервис визуализации видеоконтента, предоставляемый Google Ireland
                    Limited, который позволяет Telegiv внедрять такой контент на своих страницах.{' '}
                  </Desc>
                  <Desc>
                    Через эту услугу Telegiv может собирать данные непосредственно или косвенно с устройств
                    пользователей, включая использование трекеров. Пользователи могут ограничить доступ к своим данным
                    через страницу настроек безопасности, предоставляемую Google. Пользователи могут в любое время
                    обратиться к владельцу за дополнительной информацией о настройках конфиденциальности через
                    контактные данные, указанные в этом документе.
                  </Desc>
                  <Desc>
                    Данные, собранные через Сервис, также могут использоваться третьими сторонами для предоставления
                    рекламы, основанной на интересах пользователей. Пользователи могут отказаться от рекламы, основанной
                    на интересах третьих сторон, через настройки устройства или посетив страницу отказа от рекламы,
                    предоставленную Network Advertising Initiative.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: данные, передаваемые для использования Сервиса, данные, передаваемые
                    при использовании Сервиса, информация об устройстве, пароль, трекеры, данные использования,
                    идентификатор пользователя и имя пользователя.
                  </Desc>
                  <Point number={'3.2.3'}>Виджет видео YouTube (Google Ireland Limited)</Point>
                  <Desc>
                    YouTube - это сервис визуализации видеоконтента, предоставляемый Google Ireland Limited, который
                    позволяет Telegiv внедрять такой контент на своих страницах.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                  <Point number={'3.2.4'}>Виджет Google Календаря (Google Ireland Limited)</Point>
                  <Desc>
                    Виджет Google Календаря - это сервис визуализации контента календаря, предоставляемый Google Ireland
                    Limited, который позволяет Telegiv внедрять такой контент на своих страницах.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                </Points>
              </Desc>
              <Point number={'3.3'}>Взаимодействие с внешними социальными сетями и платформами.</Point>
              <Desc>
                Этот тип услуг позволяет взаимодействовать с социальными сетями или другими внешними платформами
                непосредственно со страниц Telegiv. Взаимодействие и полученная информация через Telegiv всегда зависят
                от настроек конфиденциальности пользователя для каждой социальной сети. Даже если пользователи не
                используют этот тип услуг, он может по-прежнему собирать данные о трафике на страницах, где установлен
                сервис. Рекомендуется выходить из соответствующих служб, чтобы убедиться, что обрабатываемые данные на
                Telegiv не связываются с профилем пользователя.
              </Desc>
            </Points>
          </Desc>
        </Section>
        <Section number={'4'} title={'аналитика'}>
          <Desc>
            Telegiv использует трекеры для измерения трафика и анализа поведения пользователей с целью улучшения
            Сервиса.
            <Points>
              <Point number={'4.1'}>Аналитика</Point>
              <Desc>
                Telegiv использует трекеры для измерения трафика и анализа поведения пользователей с целью улучшения
                Сервиса.
                <Points>
                  <Point number={'4.1.1'}>Google Analytics (Google Ireland Limited)</Point>
                  <Desc>
                    Google Analytics - это сервис веб-аналитики, предоставляемый Google Ireland Limited («Google»).
                    Google использует собранные данные для отслеживания и анализа использования Telegiv, составления
                    отчетов об активностях и их предоставления другим сервисам Google. Google может использовать
                    собранные данные для контекстуализации и персонализации рекламы своей собственной рекламной сети.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                  <Point number={'4.1.2'}>Google Analytics 4 (Google Ireland Limited)</Point>
                  <Desc>
                    Google Analytics 4 - это служба веб-аналитики, предоставляемая Google Ireland Limited («Google»).
                    Google использует собранные данные для отслеживания и анализа использования Telegiv, составления
                    отчетов о его активности и их передачи другим сервисам Google. Google может использовать собранные
                    данные для контекстуализации и персонализации рекламы своей собственной рекламной сети.
                  </Desc>
                  <Desc>
                    В Google Analytics 4, IP-адреса используются только во время сбора данных, а затем удаляются перед
                    записью данных в любой центр обработки данных или сервер. Пользователи могут узнать больше,
                    ознакомившись с официальной документацией Google.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: информация о браузере, город, информация об устройстве, широта
                    (города), долгота (города), количество пользователей, статистика сессий, трекеры и данные
                    использования.
                  </Desc>
                  <Point number={'4.1.3'}>Google Ads conversion tracking (Google Ireland Limited)</Point>
                  <Desc>
                    Отслеживание конверсий Google Ads - это сервис аналитики, предоставляемый Google Ireland Limited,
                    который связывает данные из рекламной сети Google Ads с действиями, выполненными на Telegiv.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                </Points>
              </Desc>
              <Point number={'4.2'}>Услуги аналитики, управляемые непосредственно</Point>
              <Desc>
                Услуги, представленные в данном разделе, позволяют Владельцу собирать и управлять аналитикой с
                использованием собственных трекеров.
                <Points>
                  <Point number={'4.2.1'}>Аналитика, собираемая непосредственно</Point>
                  <Desc>Telegiv использует внутреннюю систему аналитики, не связанную с третьими сторонами. </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                </Points>
              </Desc>
              <Point number={'4.3'}>Анонимные услуги аналитики.</Point>
              <Desc>
                Услуги, представленные в данном разделе, позволяют Владельцу собирать и управлять аналитикой в
                анонимизированной форме с использованием третьих сторонных трекеров.
                <Points>
                  <Point number={'4.3.1'}>Google Analytics with anonymized IP (Google Ireland Limited)</Point>
                  <Desc>
                    Google Analytics - это сервис веб-аналитики, предоставляемый Google Ireland Limited («Google»).
                    Google использует собранные данные для отслеживания и анализа использования Telegiv, составления
                    отчетов о его активности и их передачи другим сервисам Google. Google может использовать собранные
                    данные для контекстуализации и персонализации рекламы своей собственной рекламной сети.
                  </Desc>
                  <Desc>
                    Эта интеграция Google Analytics анонимизирует ваш IP-адрес. Она работает путем сокращения IP-адресов
                    пользователей в государствах-членах Европейского союза или в других странах-участниках Соглашения о
                    Европейском экономическом пространстве. Только в исключительных случаях полный IP-адрес будет
                    отправлен на сервер Google и сокращен внутри США.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                </Points>
              </Desc>
              <Point number={'4.4'}>
                Тестирование производительности контента и функциональности (A/B-тестирование){' '}
              </Point>
              <Desc>
                Услуги, представленные в данном разделе, позволяют владельцу отслеживать и анализировать реакцию
                пользователей на изменения в структуре, тексте или любых других компонентах Telegiv в отношении
                веб-трафика или поведения.
                <Points>
                  <Point number={'4.4.1'}>Google Optimize 360 (Google Ireland Limited)</Point>
                  <Desc>
                    Google Optimize 360 - это сервис A/B-тестирования, предоставляемый Google Ireland Limited
                    («Google»). Этот сервис позволяет владельцу нацеливать группы пользователей на основе их предыдущего
                    использования Telegiv. Пользователи, отключившие отслеживание в Google Analytics, не будут
                    участвовать в экспериментах, созданных в Google Optimize 360. Google может использовать личные
                    данные для контекстуализации и персонализации рекламы в своей собственной рекламной сети.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                </Points>
              </Desc>
              <Point number={'4.5'}>Управление сбором данных и онлайн-опросами.</Point>
              <Desc>
                Этот тип услуг позволяет Telegiv управлять созданием, развертыванием, администрированием,
                распространением и анализом онлайн-форм и опросов с целью сбора, сохранения и повторного использования
                данных от отвечающих пользователей. Личные данные, собранные, зависят от информации, запрошенной и
                предоставленной пользователями в соответствующей онлайн-форме. Эти услуги могут быть интегрированы с
                различными сторонними сервисами, чтобы владелец мог предпринять последующие шаги с обрабатываемыми
                данными - например, управление контактами, отправка сообщений, аналитика, реклама и обработка платежей.
                <Points>
                  <Point number={'4.5.1'}>
                    Данные, предоставленные через онлайн-формы, управляемые непосредственно
                  </Point>
                  <Desc>
                    Telegiv использует онлайн-формы, которые могут быть использованы для сбора данных о пользователях.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: ответы на вопросы, платежный адрес, город, название компании,
                    контактная информация, страна, дата рождения, адрес электронной почты, имя, пол, язык, фамилия,
                    семейное положение, имя, информация о платеже, номер телефона, физический адрес, профессия,
                    фотография профиля, аккаунты в социальных сетях, трекеры, данные использования, контент пользователя
                    и место работы.
                  </Desc>
                  <Point number={'4.5.2'}>Google Forms</Point>
                  <Desc>
                    Google Forms - это платформа для создания форм и сбора данных, предоставляемая компанией Google LLC
                    или Google Ireland Limited, в зависимости от способа обработки данных, выбранного Владельцем.
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: ответы на вопросы, город, название компании, контактная информация,
                    страна, данные, передаваемые при использовании сервиса, дата рождения, адрес электронной почты, имя,
                    пол, язык, фамилия, пароль, номер телефона, физический адрес, профессия, штат, трекеры, данные
                    использования, имя пользователя, номер НДС, место работы и почтовый индекс.
                  </Desc>
                </Points>
              </Desc>
            </Points>
          </Desc>
        </Section>
        <Section number={'5'} title={'Маркетинг '}>
          <Desc>
            Telegiv использует трекеры для предоставления персонализированного маркетингового контента на основе
            поведения пользователя, а также для работы, обслуживания и отслеживания рекламы.
            <Points>
              <Point number={'5.1'}>Реклама</Point>
              <Desc>
                Этот тип услуг позволяет использовать пользовательские данные для целей рекламной коммуникации. Такие
                коммуникации отображаются в виде баннеров и других рекламных объявлений на Telegiv, возможно, на основе
                интересов пользователя. Это не означает, что все личные данные используются в этой цели. Информация и
                условия использования приведены ниже. Некоторые из перечисленных ниже услуг могут использовать трекеры
                для идентификации пользователей или использовать метод поведенческого ретаргетинга, то есть показывать
                рекламу, соответствующую интересам и поведению пользователя, в том числе за пределами Telegiv. Для
                получения дополнительной информации ознакомьтесь с политиками конфиденциальности соответствующих услуг.
                Обычно такие услуги предлагают возможность отказаться от такого отслеживания. В дополнение к любой
                функции отказа, предлагаемой на одной из указанных ниже услуг, пользователи могут узнать больше о том,
                как в целом отказаться от рекламы, основанной на интересах, в разделе «Как отказаться от рекламы,
                основанной на интересах» в настоящем документе.
                <Points>
                  <Point number={'5.1.1'}>Google Ad Manager (Google Ireland Limited)</Point>
                  <Desc>
                    Google Ad Manager является сервисом рекламы, предоставляемым Google Ireland Limited, который
                    позволяет владельцу проводить рекламные кампании совместно с внешними рекламными сетями, с которыми
                    владелец, если не указано иное в настоящем документе, не имеет прямых отношений. Чтобы понять, как
                    Google использует данные, ознакомьтесь с политикой партнеров Google. В этом сервисе используется
                    файл «DoubleClick» Cookie, который отслеживает использование Telegiv и поведение пользователей в
                    отношении рекламы, продуктов и услуг. Пользователи могут отключить файлы «DoubleClick» Cookie,
                    перейдя по ссылке: Google Ad Settings.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                </Points>
              </Desc>
              <Point number={'5.2'}>Управление контактами и отправка сообщений</Point>
              <Desc>
                Этот тип услуг позволяет управлять базой данных контактов по электронной почте, телефонными контактами
                или любой другой контактной информацией для общения с пользователем. Эти услуги могут также собирать
                данные о дате и времени просмотра сообщения пользователем, а также о его взаимодействии с ним, например,
                кликах по ссылкам, включенным в сообщение.
                <Points>
                  <Point number={'5.2.1'}>Управление контактами и отправка сообщений</Point>
                  <Desc>
                    MailerLite - это сервис управления адресами электронной почты и отправки сообщений, предоставляемый
                    UAB «Mailerlite»
                  </Desc>
                  <Desc>
                    Обрабатываемые личные данные: название компании, страна, дата рождения, адрес электронной почты,
                    имя, пол, фамилия, номер телефона, физический адрес, трекеры, данные использования, имя пользователя
                    и различные типы данных.
                  </Desc>
                </Points>
              </Desc>
              <Point number={'5.3'}>Ремаркетинг и поведенческий таргетинг</Point>
              <Desc>
                Этот тип услуг позволяет Telegiv и ее партнерам информировать, оптимизировать и показывать рекламу,
                основанную на предыдущем использовании Telegiv пользователем. Эта деятельность обеспечивается путем
                отслеживания данных использования и использования трекеров для сбора информации, которая затем
                передается партнерам, которые управляют деятельностью ремаркетинга и поведенческого таргетинга.
                Некоторые услуги предлагают возможность ремаркетинга на основе списков адресов электронной почты. Обычно
                такие услуги предлагают возможность отказаться от такого отслеживания. В дополнение к любой функции
                отказа, предлагаемой на одной из указанных ниже услуг, пользователи могут узнать больше о том, как в
                целом отказаться от рекламы, основанной на интересах, в разделе «Как отказаться от рекламы, основанной
                на интересах» в настоящем документе.
                <Points>
                  <Point number={'5.3.1'}>Google Ads Remarketing (Google Ireland Limited)</Point>
                  <Desc>
                    Google Ads Remarketing - это сервис ремаркетинга и поведенческого таргетинга, предоставляемый Google
                    Ireland Limited, который связывает активность Telegiv с рекламной сетью Google Ads и файлом
                    «DoubleClick» Cookie. Чтобы понять, как Google использует данные, ознакомьтесь с политикой партнеров
                    Google. Пользователи могут отказаться от использования трекеров Google для персонализации рекламы,
                    посетив страницу Google Ads Settings.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                  <Point number={'5.3.2'}>Facebook Custom Audience (Meta Platforms Ireland Limited)</Point>
                  <Desc>
                    Facebook Custom Audience - это сервис ремаркетинга и поведенческого таргетинга, предоставляемый Meta
                    Platforms Ireland Limited, который связывает активность Telegiv с рекламной сетью Facebook.
                    Пользователи могут отказаться от использования трекеров Facebook для персонализации рекламы, посетив
                    эту страницу отказа.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: адрес электронной почты и трекеры.</Desc>
                  <Point number={'5.3.3'}>Facebook Remarketing (Meta Platforms Ireland Limited)</Point>
                  <Desc>
                    Facebook Remarketing - это сервис ремаркетинга и поведенческого таргетинга, предоставляемый Meta
                    Platforms Ireland Limited, который связывает активность Telegiv с рекламной сетью Facebook.
                  </Desc>
                  <Desc>Обрабатываемые личные данные: трекеры и данные использования.</Desc>
                </Points>
              </Desc>
            </Points>
          </Desc>
        </Section>
      </div>
    </TermsContainer>
  );
};
