import { GetHistoryRequestType } from '../../sales/components/salesBalance/salesBalanceApi';
import { ADMIN_SALES_INSTANCE_PREFIX, instance } from './instance';

export const adminTransfersApi = {
  getTransfers(args: GetTransfersArgsType) {
    return instance.get(`${ADMIN_SALES_INSTANCE_PREFIX}/transfers`, { params: args });
  },
  sendTransfer(id: number) {
    return instance.post(`${ADMIN_SALES_INSTANCE_PREFIX}/transfers/${id}/confirm`, {});
  },
  getHistory(arg: GetHistoryArgsType) {
    return instance.get(`${ADMIN_SALES_INSTANCE_PREFIX}/managers/${arg.id}/history`, { params: arg.arg });
  },
  getManagerInfo(id: string) {
    return instance.get(`${ADMIN_SALES_INSTANCE_PREFIX}/managers/${id}`);
  },
};

export type GetTransfersArgsType = {
  status: GetTransfersStatusType;
  page: string;
  size?: string;
};
export type GetTransfersStatusType = 'finished' | 'pending';
export type GetTransfersResponseType = {
  totalCount: number;
  pagesCount: number;
  page: number;
  size: number;
  items: ItemsTransfersType[];
};
export type ItemsTransfersType = {
  id: number;
  managerId: number;
  tgUsername: string;
  phoneNumber?: string;
  email: string;
  sum: number;
  status: string;
  dateTransaction?: string;
  teamMembers: number;
  paidClients: number;
};

export type GetHistoryArgsType = {
  arg: GetHistoryRequestType;
  id: number;
};

export type GetManagerResponseType = {
  manager: ManagerType;
  teamMembers: TeamMembersType;
  withdrawId: number;
  profit: ProfitType;
  managerClients: ManagerClientsType;
};
type ManagerType = {
  id: number;
  email: string;
  inviterId?: number;
  tgUsername: string;
  balance: number;
  createdAt: string;
  phoneNumber?: string;
};
type TeamMembersType = {
  managers: number;
  teamClients: number;
};
type ProfitType = {
  total: number;
  incomeFromClients: number;
  incomeFromTeam: number;
};
type ManagerClientsType = {
  paidClients: number;
  freeClients: number;
  totalClients: number;
};
