import React, { FC } from 'react';
import s from './ManagersTable.module.scss';

type PropsType = {
  text: string | number;
  variant?: 'subscription' | 'subscribers' | 'subscribersWarning';
};

export const ManagersTableCell: FC<PropsType> = ({ text, variant }) => {
  const getCellStyle = () => {
    switch (variant) {
      case 'subscribers':
        return s.subscribersCountCell;
      case 'subscription':
        return s.subscriptionCell;
      case 'subscribersWarning':
        return s.subscribersCountWarningCell;
      default:
        return s.commonCell;
    }
  };

  const cellStyle = getCellStyle();
  return (
    <div className={cellStyle}>
      <span>{text}</span>
    </div>
  );
};
