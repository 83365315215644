import { Navigate, Outlet } from 'react-router-dom';
import { PATH } from '../path/puth';
import { AdminHeader } from '../../features/admin/adminHeader/AdminHeader';
import { Container } from '../../features/sales/common/components/container/Container';

export const PrivateRoutesAdmin = () => {
  const token = sessionStorage.getItem('token');

  return token ? (
    <>
      <AdminHeader />
      <Container>
        <Outlet />
      </Container>
    </>
  ) : (
    <Navigate to={PATH.admin} />
  );
};
