import React, { FC, PropsWithChildren } from 'react';
import s from './CustomForm.module.css';
import { CustomButton } from '../customButton/CustomButton';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';

type PropsType = {
  helperText?: string;
  linkText?: string;
  formTitle: string;
  btnColor: 'primary' | 'secondary' | 'warning' | 'info' | 'success';
  btnTitle: string;
  handleSubmit: () => void;
  linkTo?: string;
};
export const CustomForm: FC<PropsType & PropsWithChildren> = ({
  children,
  helperText,
  linkText,
  formTitle,
  btnColor,
  btnTitle,
  handleSubmit,
  linkTo,
}) => {
  return (
    <div className={s.formContainer}>
      <div className={s.formWrapper}>
        <h2 className={s.formTitle}>{formTitle}</h2>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            {children}
            <CustomButton
              title={btnTitle}
              callback={() => {}}
              color={btnColor}
              textColor={'white'}
              margin={'40px 0 0'}
            />
          </FormControl>
        </form>
        <div className={s.helperTextWrapper}>
          <span className={s.helperText}>{helperText}</span>
          <Link className={s.helperLink} to={linkTo ? linkTo : '#'}>
            <p>{linkText}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
