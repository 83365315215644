import axios from 'axios';
import { TOKENS } from '../../sales/common/utils/tokenHelpers';

export const analysisInstance = axios.create({
  baseURL: process.env.REACT_APP_TELEGIV_API,
});

analysisInstance.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem(TOKENS.CUSTOMER)}`;
  config.withCredentials = true;
  return config;
});
