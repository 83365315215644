import { instance } from '../../API/instance';

export const salesBalanceApi = {
  getHistory(arg: GetHistoryRequestType = { historyCategory: 'mySales', page: 1, size: 10 }) {
    return instance.get('history', { params: arg });
  },
  getBalance() {
    return instance.get('balance');
  },
  checkPassword(password: string) {
    return instance.post('balance/withdraw', { password });
  },
};

export type HistoryCategoryType = 'mySales' | 'teamSales' | 'withoutProfitSales' | 'withdrawal';
export type GetHistoryRequestType = {
  historyCategory: HistoryCategoryType;
  page: number;
  size: number;
};
export type GetHistoryResponseType = {
  totalCount: number;
  pagesCount: number;
  page: number;
  size: number;
  items: HistoryItemResponseType[];
};
export type HistoryItemResponseType = {
  id: number;
  managerId: number;
  purchaseDate: Date;
  customerId: number;
  costPlan: number;
  myProfitPercent: number;
  myProfit: number;
  customerEmail: string;
  status?: string;
  sum?: number;
  dateTransaction?: string;
};
export type GetBalanceResponseType = {
  balance: number;
  canWithdraw: boolean;
};
