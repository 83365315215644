import React from 'react';
import { MyClientsCount } from '../../../common/components/managerStats/MyClientsCount';
import { Income } from '../../../common/components/managerStats/Income';
import { TeamCount } from '../../../common/components/managerStats/TeamCount';
import s from './ManagerStatsBlock.module.scss';

export const ManagerStatsBlock = () => {
  return (
    <div className={s.statsBlockWrapper}>
      <MyClientsCount />
      <Income />
      <TeamCount />
    </div>
  );
};
