import React from 'react';

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SMALL = 'small',
  ICON = 'icon',
  TRANSPARENT = 'transparent',
  BOX = 'box',
}

type ButtonExtraTypes = {
  variant?: ButtonVariant;
  width?: string;
  height?: string;
  path?: string;
  gap?: string;
  opacity?: boolean;
  onNavigateToPage?: (path: string) => void;
};

export type ButtonPropsModel = Omit<React.JSX.IntrinsicElements['button'], 'ref'> & ButtonExtraTypes;

export type StyledButtonPropsModel = Omit<ButtonPropsModel, keyof ButtonExtraTypes> & {
  $variant?: ButtonVariant;
  $width?: string;
  $height?: string;
  $path?: string;
  $gap?: string;
  opacity?: boolean;
  onPageChange?: (path: string) => void;
};
