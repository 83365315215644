import React from 'react';
import s from './AdminHeader.module.scss';
import { PATH } from '../../../common/path/puth';
import { MainLogo } from '../../sales/common/components/mainLogo/MainLogo';
import { AdminHeaderMenu } from './AdminHeaderMenu';

type AdminHeaderPropsType = {
  onlyLogo?: boolean;
};

export const AdminHeader = (props: AdminHeaderPropsType) => {
  return (
    <div className={s.headerBackground}>
      <div className={s.headerContainer}>
        <div className={s.headerWrapper}>
          <MainLogo navigateLink={PATH.users} toggleLogoWidth={1120} />
          {!props.onlyLogo && <AdminHeaderMenu />}
        </div>
      </div>
    </div>
  );
};
