import React from 'react';
import style from './CardItem.module.scss';
import gift from '../../../../assets/svg/gift.svg';
import { ItemType } from '../../../../api/apiMain';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../common/path/puth';
import { apiCustomer } from '../../../customer/API/customersAPI';
import { PictureRaffle } from '../../../../common/components/PictureRaffle';

type CardItemPropsType = {
  draw?: boolean;
  result?: boolean;
  image: string;
  title: string | null;
  winner?: string;
  item?: ItemType;
};

export const CardItem = ({ result, title, image, item }: CardItemPropsType) => {
  const navigate = useNavigate();

  const checkResult = () => {
    navigate(`${PATH.result}/${item?.customer_id}/${item?.bot_id}/${item?.id}`);
  };

  const onBotLink = () => {
    apiCustomer.getBotLink(item!.customer_id).then(res => {
      setTimeout(() => {
        /**
         * window.open не работает в асинхронном вызове в сафари.
         * пока что временной заглушкой обернул его в таймаут
         * */
        window.open(`${res.data.botLink}`, '_blank', 'noreferrer');
      });
    });
  };

  return (
    <div className={style.container}>
      <PictureRaffle src={image} className={style.image} />
      <p className={style.text}>{title}</p>
      {!result ? (
        <button className={style.btn} onClick={onBotLink}>
          <span> Выиграть</span>
          <img src={gift} alt='' />
        </button>
      ) : (
        <p className={style.winner}>
          <button className={style.btn} onClick={checkResult}>
            <span>Результат</span>
          </button>
        </p>
      )}
    </div>
  );
};
