import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { drawResultsApi, GetWinnersResponseData, WinnerResponseType } from './API/apiDrawResults';
import { handleServerNetworkError } from '../../common/utils/error-utils';
import { isAxiosError } from 'axios';

type DrawResultsInitialState = GetWinnersResponseData;

const initialState: DrawResultsInitialState = {
  winners: [],
};

const slice = createSlice({
  name: 'draw/results',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWinnersT.fulfilled, (state, action: PayloadAction<WinnerResponseType[]>) => {
      state.winners = action.payload;
    });
  },
});

export const getWinnersT = createAsyncThunk<WinnerResponseType[], { raffleId: string | undefined }>(
  'draw/results/getWinners',
  async (arg, thunkApi) => {
    try {
      const res = await drawResultsApi.getWinners(arg.raffleId);
      /**
       * С бэка winners возвращаются либо пустым массивом, либо объектом со свойством winners.
       * В стейт полетит либо массив winners, либо пустой.
       * */
      return res.data.winners || [];
    } catch (err) {
      if (isAxiosError(err)) {
        handleServerNetworkError(err.message, thunkApi.dispatch);

        return thunkApi.rejectWithValue(null);
      }
      return thunkApi.rejectWithValue(null);
    }
  }
);

export const drawResultsReducer = slice.reducer;
