import { useNavigate } from 'react-router-dom';
import { IsSmallSizeScreen } from '../../../../../common/utils/isSmallSizeScreen';
import s from './Terms.module.scss';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import { Icon } from '../../../../../ui-kit/components/icons/IconWrapper';
import { ICON } from '../../../../../ui-kit/components/icons/components/Types';

type Props = {
  children: ReactNode;
};
export const TermsContainer: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  let backIconSize = 64;
  if (IsSmallSizeScreen()) backIconSize = 32;
  return (
    <div className={s.root}>
      <div className={clsx('container', s.cookieContainer)}>
        <button className={s.backBtn} onClick={() => navigate(-1)}>
          <Icon iconId={ICON.ARROW_LEFT} width={'64px'} height={'64px'} />
        </button>
        {children}
      </div>
    </div>
  );
};
