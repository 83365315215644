import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'usehooks-ts';
import * as Yup from 'yup';
import { CommonInput } from '../../../../../common/components/commonInput/CommonInput';
import { PasswordInput } from '../../../../../common/components/passwordInput/PasswordInput';
import { CustomForm } from '../../../../../common/components/customForm/CustomForm';
import { FormikValuesType } from '../../../common/types/commonTypes';
import { usePasswordVisible } from '../../../hooks/usePasswordVisible';
import { PATH } from '../../../../../common/path/puth';
import { SalesRegistrationParams } from '../../../API/apiSalesAuth';
import { useAppDispatch } from '../../../../../common/hooks/storeHooks';
import { salesAuthThunks } from '../../../salesAuthSlice';
import { TG_FIELD_REGEXP, TG_PREFIX_REPLACE_REGEXP } from '../../../common/constants';
import { TG_FIELD_DEFAULT_VALUE } from '../../../../../common/constants/links';
export const SalesRegistration = () => {
  const { visibility, toggleVisibility } = usePasswordVisible();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      tg_username: TG_FIELD_DEFAULT_VALUE,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Пожалуйста, введите Email').email('Некорректный Email'),
      password: Yup.string().required('Пожалуйста, введите пароль').min(8, 'Пароль должен быть больше 7 символов'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Пароли не совпадают')
        .required('Поле обязательное'),
      tg_username: Yup.string()
        .url('Username должен быть валидным значением')
        .test('tg_username', 'Нужно ввести свой username', value => {
          if (!value) return true;
          const matches = value.match(TG_FIELD_REGEXP);
          return !!matches;
        })
        .test('tg_username', 'Нужно ввести свой username  без "@"', value => {
          if (!value) return true;
          const isTgUsernameContainsAtSymbol = value.includes('@');
          return !isTgUsernameContainsAtSymbol;
        })
        .required('Нужно ввести свой username'),
    }),
    onSubmit: async values => {
      if (values.password && values.email && values.tg_username) {
        const replacedTgUsername = values.tg_username.replace(TG_PREFIX_REPLACE_REGEXP, '');
        const params: SalesRegistrationParams = {
          email: values.email,
          password: values.password,
          tg_username: replacedTgUsername,
        };
        dispatch(salesAuthThunks.registration(params))
          .unwrap()
          .then(() => {
            formik.resetForm();
            navigate(`/sales/${PATH.salesLogin}`);
          });
      }
    },
  });

  useUpdateEffect(() => {
    const tgUsernameField = formik.getFieldProps('tg_username');
    const isFullPrefix = tgUsernameField.value.match(TG_PREFIX_REPLACE_REGEXP);
    if (!isFullPrefix) {
      formik.setFieldValue('tg_username', TG_FIELD_DEFAULT_VALUE);
    }
  }, [formik]);

  return (
    <CustomForm
      handleSubmit={formik.handleSubmit}
      helperText={'У Вас уже есть аккаунт?'}
      linkText={'Вход'}
      formTitle={'Регистрация'}
      btnColor={'success'}
      btnTitle={'Регистрация'}
      linkTo={`/sales/${PATH.salesLogin}`}
    >
      <CommonInput name={'EMAIL'} placeholder={'Email'} formik={formik} inputName={'email'} />
      <PasswordInput
        name={'ПАРОЛЬ'}
        placeholder={'Пароль'}
        formik={formik}
        inputName={'password'}
        visibility={visibility}
        toggleVisibility={toggleVisibility}
      />
      <PasswordInput
        name={'ПОВТОРНЫЙ ВВОД ПАРОЛЯ'}
        placeholder={'Введите пароль повторно'}
        formik={formik}
        inputName={'confirmPassword'}
        visibility={visibility}
        toggleVisibility={toggleVisibility}
      />
      <CommonInput
        name={'USERNAME В TELEGRAM'}
        placeholder={'Введите свой Username в Telegram'}
        formik={formik}
        inputName={'tg_username'}
      />
    </CustomForm>
  );
};
