import List from '@mui/material/List';
import React, { FC, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import burgerIcon from '../../../../../assets/svg/burger.svg';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontSize: Number('20'),
  },
});

export type MenuItemsType = {
  buttonTitle: string;
  buttonLink: string;
};

type PropsType = {
  handleLogout: () => void;
  menuItems: MenuItemsType[];
};

export const HeaderBurger: FC<PropsType> = ({ handleLogout, menuItems }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  const mappedMenuItems = menuItems.map(item => (
    <ListItem disablePadding key={item.buttonTitle}>
      <ListItemButton onClick={() => navigate(item.buttonLink)}>
        <ListItemText primary={item.buttonTitle} />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <>
      <Button onClick={toggleDrawer(true)}>
        <img style={{ width: '40px' }} src={burgerIcon} alt='burgerIcon' />
      </Button>
      <ThemeProvider theme={theme}>
        <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
          <Box sx={{ width: 200 }} role='presentation' onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
              {mappedMenuItems}
              <Divider sx={{ mt: '50px' }} />
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemText primary={'Выход'} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </ThemeProvider>
    </>
  );
};
