import axios from 'axios';

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_TELEGIV_API}`,
});

instance.interceptors.request.use(config => {
  config.headers!.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  return config;
});
export const ADMIN_SALES_INSTANCE_PREFIX = '/admin/sales';
