import { useState, useRef, useEffect } from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const throttleTimeout = useRef<null | NodeJS.Timer>(null);
  const handleSize = () => {
    if (!throttleTimeout.current) {
      throttleTimeout.current = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        throttleTimeout.current = null;
      }, 100);
    }
  };

  useEffect(() => {
    handleSize();

    window.addEventListener('resize', handleSize);

    return () => {
      window.removeEventListener('resize', handleSize);
      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current);
      }
    };
  }, []);

  return windowSize;
};
