import React, { FC } from 'react';
import s from './ConfirmPage.module.scss';
import Button from '@mui/material/Button';
import { ActiveWindowType } from '../SalesSettings';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { salesAuthThunks } from '../../../salesAuthSlice';

type Props = {
  setActiveWindow: (activeWindow: ActiveWindowType) => void;
};

export const ConfirmPage: FC<Props> = ({ setActiveWindow }) => {
  const dispatch = useAppDispatch();
  const email = useAppSelector(state => state.salesAuth.managerProfile.email);

  const resetPasswordHandler = async () => {
    await dispatch(salesAuthThunks.resetPassword({ email }));
    setActiveWindow('checkEmail');
  };

  return (
    <div className={s.ConfirmPageBlock}>
      <h2>Хотите изменить пароль?</h2>
      <div className={s.buttons}>
        <Button
          disabled={false}
          variant={'contained'}
          prefix={'main'}
          color={'success'}
          className={s.button}
          onClick={() => setActiveWindow('settings')}
        >
          Назад
        </Button>
        <Button
          disabled={false}
          variant={'contained'}
          prefix={'main'}
          color={'error'}
          className={s.button}
          onClick={resetPasswordHandler}
        >
          Изменить пароль
        </Button>
      </div>
    </div>
  );
};
