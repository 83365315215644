import React from 'react';
import { StatsItem } from '../../../sales/common/components/managerStats/StatsItem';
import { StatsWrapper } from '../../../sales/common/components/managerStats/StatsWrapper';
import { useAppSelector } from '../../../../common/hooks/storeHooks';
import { managerProfitSelector } from '../../adminManagerSelectors';

export const AdminManagerIncome = () => {
  const managerProfit = useAppSelector(managerProfitSelector);

  return (
    <StatsWrapper>
      <StatsItem title={'Заработок'} value={`${managerProfit.total}$`} cashValue headingTitle />
      <StatsItem title={'Клиенты'} value={`${managerProfit.incomeFromClients}$`} cashValue />
      <StatsItem title={'Команда'} value={`${managerProfit.incomeFromTeam}$`} cashValue />
    </StatsWrapper>
  );
};
