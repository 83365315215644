import { ClientType, TeammateType } from '../../sales/API/apiPublicManager';
import { ADMIN_SALES_INSTANCE_PREFIX, instance } from './instance';

export const adminManagerAPI = {
  getManagerById(id: string) {
    return instance.get<ManagerDataType>(`${ADMIN_SALES_INSTANCE_PREFIX}/managers/${id}`);
  },
  getManagerClients(params: AdminManagerGetParams) {
    return instance.get<AdminManagerPublicType<ClientType[]>>(
      `${ADMIN_SALES_INSTANCE_PREFIX}/managers/${params.id}/clients`,
      {
        params: { size: params.size, page: params.page },
      }
    );
  },
  getManagerTeam(params: AdminManagerGetParams) {
    return instance.get<AdminManagerPublicType<TeammateType[]>>(
      `${ADMIN_SALES_INSTANCE_PREFIX}/managers/${params.id}/teams`,
      {
        params: { size: params.size, page: params.page },
      }
    );
  },
  confirmTransfer(withdrawId: number) {
    return instance.post(`${ADMIN_SALES_INSTANCE_PREFIX}/transfers/${withdrawId}/confirm`);
  },
  getManagers(arg: GetManagersArgType = { page: '1', size: '10' }) {
    return instance.get(`${ADMIN_SALES_INSTANCE_PREFIX}/managers`, { params: arg });
  },
  sendManagerId(id: string) {
    return instance.post<ManagerDataType>(`${ADMIN_SALES_INSTANCE_PREFIX}/manager/activated/${id}`);
  },
};

export type AdminManagerPublicType<T> = {
  totalCount: number;
  pagesCount: number;
  items: T;
};
export type AdminManagerGetParams = {
  id: string;
  size?: string;
  page?: string;
};
export type ManagerDataType = {
  manager: ManagerData;
  withdrawId: number;
  teamMembers: ManagerTeamMembers;
  profit: ManagerProfit;
  managerClients: ManagerClients;
};
export type ManagerData = {
  id: number;
  email: string;
  inviterId: number;
  tgUsername: string;
  promo: string;
  balance: number;
  phoneNumber: string;
  isActivated: boolean;
  createdAt: string;
};
export type ManagerTeamMembers = {
  managers: number;
  teamClients: number;
};
export type ManagerProfit = {
  total: number;
  incomeFromClients: number;
  incomeFromTeam: number;
};
export type ManagerClients = {
  paidClients: number;
  freeClients: number;
  totalClients: number;
};
export type ManagerItemType = {
  id: number;
  email: string;
  inviterId?: number;
  tgUsername: string;
  balance: number;
  createdAt: string;
  phoneNumber?: string;
  promo: string;
  isActivated: boolean;
  team: string;
  paidClients: string;
  unpaidClients: string;
};
export type GetManagersArgType = {
  page?: string;
  size?: string;
  sortBy?: string;
  sortDirection?: string;
};
