import React, { FC } from 'react';
import emailImg from '../../../../../assets/image/sales/email.svg';
import s from './CheckEmail.module.css';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  email: string;
  text: string;
  pathToLoginPage: string;
};
export const CheckEmail: FC<PropsType> = ({ email, text, pathToLoginPage }) => {
  const navigate = useNavigate();
  const toLoginPage = () => navigate(pathToLoginPage);
  const displayingMessage = `${text} ${email}`;

  return (
    <div className={s.checkEmailContainer}>
      <div className={s.checkEmailWrapper}>
        <img className={s.checkEmailImg} src={emailImg} alt='check-email' />
        <span className={s.infoMessage}>{displayingMessage}</span>
        <CustomButton
          title={'На страницу Авторизации'}
          color={'primary'}
          textColor={'white'}
          type={'button'}
          callback={toLoginPage}
        />
      </div>
    </div>
  );
};
