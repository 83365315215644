import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import styles from '../../styles/AdminMailingForm.module.scss';
import { Selection } from '../../../../customer/components/createDraw/components/Selection';
import { AddAvatar } from '../../../../customer/components/createDraw/components/AddAvatar';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import {
  selectionAges,
  selectionButtonUsage,
  selectionCountries,
  selectionGenders,
} from '../../../../../common/shemas';
import { CustomButton } from '../../../../../common/components/customButton/CustomButton';
import { AdminMailingTextArea, ButtonSettings } from '../../common';
import { AdminMailingFormFields, ButtonUsage } from '../../types';
import { adminMailingFormValuesSelector, setFormFields } from '../../bll';
import { adminMailingFormValidationSchema } from '../../lib';
import { mailingRaffleTestAsyncAction } from '../../bll/adminMailingAsyncActions';

export const AdminMailingForm = () => {
  const formFields = useAppSelector(adminMailingFormValuesSelector);
  const dispatch = useAppDispatch();

  const formik = useFormik<AdminMailingFormFields>({
    enableReinitialize: false,
    isInitialValid: false,
    initialValues: formFields,
    validationSchema: adminMailingFormValidationSchema,
    onSubmit: async (values, formikHelpers) => {
      dispatch(setFormFields(values));
      const response = await dispatch(mailingRaffleTestAsyncAction(values));
      if (response.meta.requestStatus === 'rejected') {
        await formikHelpers.validateForm(values);
      }
    },
  });
  const savePhoto = useCallback((value: string | ArrayBuffer) => {
    formik.setFieldValue('photo', value);
  }, []);

  const isSubmitBtnDisabled = !formik.isValid || formik.isSubmitting;

  return (
    <form onSubmit={formik.handleSubmit} className={styles.mailingFormContainer}>
      <AddAvatar addPhoto={savePhoto} avatar={formFields.photo} formik={formik} />
      <Selection
        fieldName={'countries'}
        title={'Страна'}
        allSelectionText={'Все страны'}
        setFieldValue={formik.setFieldValue}
        selectionArr={selectionCountries}
        selectionValue={formik.values.countries}
      />
      <Selection
        fieldName={'ages'}
        title={'Возраст'}
        allSelectionText={'Все'}
        setFieldValue={formik.setFieldValue}
        selectionArr={selectionAges}
        selectionValue={formik.values.ages}
      />
      <Selection
        fieldName={'gender'}
        title={'Пол'}
        allSelectionText={'Все'}
        setFieldValue={formik.setFieldValue}
        selectionArr={selectionGenders}
        selectionValue={formik.values.gender}
      />
      <AdminMailingTextArea formik={formik} fieldName={'description'} />
      <Selection
        fieldName={'buttonUsage'}
        title={'Кнопка'}
        setFieldValue={formik.setFieldValue}
        selectionArr={selectionButtonUsage}
        selectionValue={formik.values.buttonUsage}
      />
      {formik.values.buttonUsage === ButtonUsage.YES && <ButtonSettings formik={formik} />}
      <CustomButton disable={isSubmitBtnDisabled} title={'Отправить тестовое сообщение'} textColor={'white'} />
    </form>
  );
};
