import React from 'react';
import { StatsWrapper } from '../../../sales/common/components/managerStats/StatsWrapper';
import { StatsItem } from '../../../sales/common/components/managerStats/StatsItem';
import { useAppSelector } from '../../../../common/hooks/storeHooks';
import { managerTeamStatsSelector } from '../../adminManagerSelectors';

export const AdminManagerTeamCount = () => {
  const managerTeamStats = useAppSelector(managerTeamStatsSelector);

  return (
    <StatsWrapper>
      <StatsItem title={'Участников в команде'} value={managerTeamStats.managers} headingTitle />
      <StatsItem title={'Клиентов у команды'} value={managerTeamStats.teamClients} />
    </StatsWrapper>
  );
};
