import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import { isAuthorizedSelector } from '../salesAuthSelectors';
import { PATH } from '../../../common/path/puth';
import { Container } from '../common/components/container/Container';
import { SalesHeader } from '../common/components/header/SalesHeader';

export const SalesRequireAuth = () => {
  const isAuthorized = useAppSelector(isAuthorizedSelector);
  return (
    <>
      <SalesHeader />
      {isAuthorized ? (
        <Container>
          <Outlet />
        </Container>
      ) : (
        <Navigate to={PATH.salesLogin} replace={true} />
      )}
    </>
  );
};
