import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SalesAuth } from './SalesAuth';
import { PATH } from '../../../common/path/puth';
import { SalesLogin } from '../components/salesAuth/login/SalesLogin';
import { ThemeProvider } from '@mui/material';
import { muiTheme } from '../constants/customTheme';
import { SalesRegistration } from '../components/salesAuth/registration/SalesRegistration';
import { SalesRecoverPass } from '../components/salesAuth/recoverPassword/SalesRecoverPass';
import { SetNewPassword } from '../components/salesAuth/recoverPassword/SetNewPassword';
import { SalesRequireAuth } from './SalesRequireAuth';
import { SalesHome } from '../components/salesHome/SalesHome';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { salesAuthThunks } from '../salesAuthSlice';
import { isInitializedSelector } from '../salesAuthSelectors';
import { PreLoader } from '../common/components/preLoader/PreLoader';
import { SalesBalance } from '../components/salesBalance/SalesBalance';
import { AllClients } from '../components/salesHome/clients/AllClients';
import { AllTeam } from '../components/salesHome/team/AllTeam';
import { KnowledgeBase } from '../components/salesKnowledgeBase/KnowledgeBase';
import { SalesSettings } from '../components/salesSettings/SalesSettings';
import { SalesWithdrawals } from '../components/salesBalance/withdrawals/SalesWithdrawals';
import { SalesLanding } from './SalesLanding';
import { CookiePage } from '../components/salesLanding/termsOfUsePages/cookiePage/CookiePage';
import { PrivacyPolicy } from '../components/salesLanding/termsOfUsePages/privacyPolicy/PrivacyPolicy';

export const SalesRoot = () => {
  const dispatch = useAppDispatch();
  const isInitialized = useAppSelector(isInitializedSelector);

  useEffect(() => {
    dispatch(salesAuthThunks.getMe());
  }, []);

  if (!isInitialized) return <PreLoader />;
  return (
    <ThemeProvider theme={muiTheme}>
      <Routes>
        <Route element={<SalesAuth />}>
          <Route path={PATH.salesLogin} element={<SalesLogin />} />
          <Route path={PATH.salesRegistration} element={<SalesRegistration />} />
          <Route path={PATH.salesRecoverPass} element={<SalesRecoverPass />} />
          <Route path={PATH.salesNewPassword} element={<SetNewPassword />} />
        </Route>
        <Route element={<SalesRequireAuth />}>
          <Route path={PATH.salesHome} element={<SalesHome />} />
          <Route path={PATH.salesAllClients} element={<AllClients />} />
          <Route path={PATH.salesAllTeam} element={<AllTeam />} />
          <Route path={PATH.salesBalance} element={<SalesBalance />} />
          <Route path={PATH.salesKnowledgeBase} element={<KnowledgeBase />} />
          <Route path={PATH.salesSettings} element={<SalesSettings />} />
          <Route path={PATH.salesWithdrawals} element={<SalesWithdrawals />} />
        </Route>
        <Route element={<SalesLanding />}>
          <Route path={PATH.salesCookie} element={<CookiePage />} />
          <Route path={PATH.salesPrivacyPolicy} element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};
