import React, { useState } from 'react';
import s from './WithdrawalsAction.module.scss';
import { useFormik } from 'formik';
import { FormikValuesType } from '../../../../common/types/commonTypes';
import * as Yup from 'yup';
import { usePasswordVisible } from '../../../../hooks/usePasswordVisible';
import { ActionBlock } from './actionBlock/ActionBlock';
import { PasswordBlock } from './passwordBlock/PasswordBlock';
import { SuccessBlock } from './successBlock/SuccessBlock';
import { AccordionBlock } from './accordionBlock/AccordionBlock';
import { useAppDispatch } from '../../../../../../common/hooks/storeHooks';
import { salesBalanceThunks } from '../../salesBalanceSlice';
import { handleServerNetworkError } from '../../../../../../common/utils/error-utils';

export type ActiveWindowType = 'withdrawals' | 'password' | 'success';

export const WithdrawalsAction = () => {
  const dispatch = useAppDispatch();
  const { visibility, toggleVisibility } = usePasswordVisible();
  const [activeWindow, setActiveWindow] = useState<ActiveWindowType>('withdrawals');

  const formik = useFormik<FormikValuesType>({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Пожалуйста, введите пароль').min(6, 'Минимум 6 символов!'),
    }),
    onSubmit: async values => {
      if (values.password) {
        await dispatch(salesBalanceThunks.checkPassword(values.password.toString()))
          .unwrap()
          .then(() => {
            setActiveWindow('success');
          })
          .catch(error => {
            if (error.response.data.statusCode === 403) {
              handleServerNetworkError('Запрос на вывод средств уже получен или баланс равен 0', dispatch);
            }
            if (error.response.data.statusCode === 400) {
              handleServerNetworkError('Неправильный пароль', dispatch);
            }
          });
      }
      formik.resetForm();
    },
  });

  return (
    <div className={s.withdrawalsWrapper}>
      {activeWindow === 'withdrawals' && <ActionBlock setActiveWindow={setActiveWindow} />}
      {activeWindow === 'password' && (
        <PasswordBlock
          formik={formik}
          visibility={visibility}
          toggleVisibility={toggleVisibility}
          setActiveWindow={setActiveWindow}
        />
      )}
      {activeWindow === 'success' && <SuccessBlock />}
      <div>
        <AccordionBlock />
      </div>
    </div>
  );
};
