import React from 'react';
import s from './AdminManagerInfo.module.scss';
import { AdminManagerContactCell, AdminManagerContactCellMode } from './AdminManagerContactCell';
import { useAppSelector } from '../../../../../common/hooks/storeHooks';
import { managerPhoneNumberSelector, managerTelegramSelector } from '../../../adminManagerSelectors';

export const AdminManagerContacts = () => {
  const managerPhoneNumber = useAppSelector(managerPhoneNumberSelector);
  const managerTelegram = useAppSelector(managerTelegramSelector);

  const phoneNumberFieldText = managerPhoneNumber ? managerPhoneNumber : 'Номер не указан';

  return (
    <div className={s.contactsWrapper}>
      <div className={s.contactsHeader}>Телефон / TG</div>
      <AdminManagerContactCell text={phoneNumberFieldText} allowClickForCopy={!!managerPhoneNumber} />
      <AdminManagerContactCell
        text={managerTelegram}
        mode={AdminManagerContactCellMode.NAVIGATE}
        allowClickForCopy={true}
      />
    </div>
  );
};
