import s from './TableHeadStat.module.scss';
import { Icon } from '../../../../ui-kit/components/icons/IconWrapper';
import { Data } from '../../types';
import { SORT_DIRECTION } from '../../../../common/constants/enumConstants';

type CurrIconProps = {
  color: string;
  order?: SORT_DIRECTION;
};

const CurrIcon = ({ color, order }: CurrIconProps) => {
  return (
    <Icon
      iconId='arrow-sort-stat'
      color={color}
      className={order === 'asc' ? s.arrowDown : ''}
      width='1.4rem'
      height='1.2rem'
    />
  );
};

type IconSortProps = {
  order: SORT_DIRECTION;
  orderBy: string;
  headCellId: keyof Data;
};

export const IconSort = ({ order, orderBy, headCellId }: IconSortProps) => {
  if (orderBy === headCellId) {
    return <CurrIcon color={'var(--color-violet-3-stat)'} order={order} />;
  } else {
    return <CurrIcon color={'var(--color-white-0)'} />;
  }
};
